/**
 *
 * Mobylogix
 * Author: Mobylogix
 * Email: info@mobylogix.com
 * Website: mobylogix.com
 * Version: 1.0
 * Date: Thu Nov 11 2021
 * Category: Pages
 * Title: ProjectForm
 *
 */

import React from 'react';
import { Container, Row, Col, Card, CardBody, CardTitle, Form, FormGroup, Input, Label, Button } from 'reactstrap';

// Api
import { submitContact } from 'api/contact';

// styles
import './project-form.css';

const ProjectForm = (props) => {

  const handleContactSubmit = (e) => {
    e.preventDefault();

    const data = {
      name: e.target.name.value,
      email: e.target.email.value,
      phone: e.target.phone.value,
      message: e.target.message.value,
      href: window.location.href
    };

    submitContact(data);
  }

  return (
    <div className="project-form">
      <Container>
        <Row className="row-justify-center">
          <Col xs="12" md="9" lg="7">
            <Card className="project-form-card">
              <CardBody>
                <CardTitle tag="h2">Tell us about your project</CardTitle>
                <Form inline onSubmit={handleContactSubmit}>
                  <FormGroup>
                    <Label for="name">Name</Label>
                    <Input id="name" name="name" type="text" />
                  </FormGroup>
                  <Row>
                    <Col xs="12" md="6">
                      <FormGroup>
                        <Label for="email">Email</Label>
                        <Input id="email" name="email" type="email" />
                      </FormGroup>
                    </Col>
                    <Col xs="12" md="6">
                      <FormGroup>
                        <Label for="phone">Phone</Label>
                        <Input id="phone" name="phone" type="number" />
                      </FormGroup>
                    </Col>
                  </Row>
                  <FormGroup>
                    <Label for="message">Message</Label>
                    <Input id="message" name="message" type="textarea" row="3" />
                  </FormGroup>
                  <Button color="primary" size="lg">Submit</Button>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
};

ProjectForm.propTypes = {};

export default ProjectForm;
