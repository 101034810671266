import React from "react";

const AwardsLogistics = ({ extraClass= '' }) => (
  <svg className={extraClass} xmlns="http://www.w3.org/2000/svg" fill={"#007eff"} width="20px" height="20px" version="1.1" style={{ shapeRendering: 'geometricPrecision', textRendering: 'geometricPrecision', imageRendering: 'optimizeQuality', fillRule: 'evenodd', clipRule: 'evenodd' }}
    viewBox="0 0 364.24 198.01">
    <g id="Layer_x0020_1">
      <metadata id="CorelCorpID_0Corel-Layer"/>
      <path d="M293.45 166.67c0,-17.29 20.91,-26.18 32.98,-14.45 13.59,13.2 2.68,33.69 -10.89,33.69l-3.56 0c-10.43,0 -18.52,-8.97 -18.52,-19.23zm-143.88 19.23c-6.67,0 -10.41,-0.99 -14.81,-5.14 -7.78,-7.34 -7.98,-20.15 -0.01,-28.19 12.2,-12.31 33.33,-3.41 33.33,14.1 0,10.26 -8.08,19.23 -18.52,19.23zm132.48 -24.93l-7.83 0 0 -98.29 42.02 0c2.11,0 2.5,1.53 3.37,3.04l21.14 37.97c15.04,25.21 11.81,23.16 11.81,57.28l-8.55 0c-1.51,-6.5 -4.35,-13.11 -9.55,-17.52 -16.06,-13.6 -38.35,-9.91 -48.74,7.66 -1.8,3.05 -2.78,6.03 -3.68,9.86zm-219.38 -151.71l0 19.94 -24.22 0c-8.42,0 -8.32,12.11 0,12.11l68.38 0c8.63,0 8.63,-12.11 0,-12.11l-32.76 0 0 -17.09 188.04 0 0 148.86 -82.62 0c-2.99,-12.84 -13.93,-25.64 -28.49,-25.64 -10.53,0 -15.52,1 -23.93,8.12 -5.2,4.4 -8.03,11.02 -9.55,17.52l-43.45 0 0 -32.05c8.07,0 22.79,1.99 22.79,-5.7 0,-3.52 -2.89,-6.41 -6.41,-6.41l-71.94 0c-7.42,0 -8.75,12.11 2.14,12.11l42.02 0 0 37.75c0,7.96 11.75,5.7 24.22,5.7 10.21,0 20.42,0 30.63,0 7.35,31.55 45.5,32.73 57.93,10.21 3.54,-6.41 2.75,-7.52 4.04,-10.21l102.57 0c7.93,34.05 53.97,34.32 61.97,0 24.06,0 19.94,0.19 19.94,-43.45 0,-12.22 -8.17,-21.49 -14.01,-33l-22.89 -40.5c-2.42,-2.94 -6.33,-4.85 -11.54,-4.85l-41.31 0 0 -40.6c0,-5.24 -5.32,-9.97 -12.82,-9.97l-187.33 0c-5.54,0 -11.4,3.84 -11.4,9.26z"/>
      <path d="M62.68 58.41l0 32.76c-10.02,0 -21.11,-2.18 -20.99,5.59 0.04,2.59 2.26,5.8 4.61,5.8l103.28 0c5.74,0 7.33,-11.4 -0.71,-11.4l-74.79 0 0 -32.76c0,-7.64 -11.4,-7.64 -11.4,0z"/>
      <path d="M-0 68.65c0.21,2.22 1.18,4 4.99,4l39.17 0c7.83,0 9.36,-12.11 -2.14,-12.11l-34.9 0c-3.21,0 -7.12,1.35 -7.12,4.27l0 3.83z"/>
      <circle cx="148.39" cy="167.01" r="6.09"/>
      <circle cx="312.97" cy="167.01" r="6.09"/>
    </g>
  </svg>
)


export default AwardsLogistics;
