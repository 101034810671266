/**
 *
 * Mobylogix
 * Author: Mobylogix
 * Email: info@mobylogix.com
 * Website: mobylogix.com
 * Version: 1.0
 * Date: Wed Nov 17 2021
 * Category: Pages
 * Title: AboutTestimonials
 *
 */

import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Masonry from 'react-masonry-css'

const AboutTestimonials = (props) => {
  const breakpointColumnsObj = {
    default: 3,
    700: 2,
    500: 1
  };

  return (
    <div className="inner-page about about-testimonials">
      {/* About Client Banner */}
      <div className="work-banner">
        <div className="main-heading">
          <h2 className="main-title wow fadeInDown">We settle for a 100% client satisfaction only!</h2>
          <p className="description wow fadeInDown">Our clients love working with us because we treat their projects like our own.</p>
        </div>
      </div>
      {/* About Client Banner End */}
      {/* Client Video Section */}
      <section className="client-testimonials-video">
        <Container>
          <Row>
            <Col xs="12" md="6">
              <iframe className="video-wrap wow fadeInUp" src="https://www.youtube.com/embed/K3WSiuX-gME" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </Col>
            <Col xs="12" md="6">
              <iframe className="video-wrap wow fadeInUp" src="https://www.youtube.com/embed/K3WSiuX-gME" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Client Video Section End */}
      {/* Client Comments Section */}
      <section className="client-comments-sec">
        <Container>
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          >
            <div className="client-comments-item wow fadeInUp">
              <p className="message">The team stood out because of their swift response time and patience. Cubix provided one contact person, which helped streamline project management and coordination. Not only did Cubix follow the budget and timeline, the team even offered additional services at no extra charge.</p>
              <div className="author-detail">
                <span className="avatar">
                  <img src="https://www.cubix.co/storage/app/media/about/karen-agresti-nomidate.jpg" alt="" />
                </span>
                <div className="author-info">
                  <strong className="title">Karen Agresti, Founder</strong>
                  <span className="location">Nomidate</span>
                </div>
              </div>
            </div>
            <div className="client-comments-item wow fadeInUp">
              <p className="message">The team stood out because of their swift response time and patience. Cubix provided one contact person, which helped streamline project management and coordination. Not only did Cubix follow the budget and timeline, the team even offered additional services at no extra charge.
              The team stood out because of their swift response time and patience. Cubix provided one contact person, which helped streamline project management and coordination. Not only did Cubix follow the budget and timeline, the team even offered additional services at no extra charge.</p>
              <div className="author-detail">
                <span className="avatar">
                  <img src="https://www.cubix.co/storage/app/media/about/karen-agresti-nomidate.jpg" alt="" />
                </span>
                <div className="author-info">
                  <strong className="title">Karen Agresti, Founder</strong>
                  <span className="location">Nomidate</span>
                </div>
              </div>
            </div>
            <div className="client-comments-item wow fadeInUp">
              <p className="message">The team stood out because of their swift response time and patience. Cubix provided one contact person, which helped streamline project management and coordination. Not only did Cubix follow the budget and timeline, the team even offered additional services at no extra charge.
              The team stood out because of their swift response time and patience. Cubix provided one contact person, which helped streamline project management and coordination. Not only did Cubix follow the budget and timeline, the team even offered additional services at no extra charge.
              The team stood out because of their swift response time and patience. Cubix provided one contact person, which helped streamline project management and coordination. Not only did Cubix follow the budget and timeline, the team even offered additional services at no extra charge.</p>
              <div className="author-detail">
                <span className="avatar">
                  <img src="https://www.cubix.co/storage/app/media/about/karen-agresti-nomidate.jpg" alt="" />
                </span>
                <div className="author-info">
                  <strong className="title">Karen Agresti, Founder</strong>
                  <span className="location">Nomidate</span>
                </div>
              </div>
            </div>
            <div className="client-comments-item wow fadeInUp">
              <p className="message">The team stood out because of their swift response time and patience. Cubix provided one contact person, which helped streamline project management and coordination. Not only did Cubix follow the budget and timeline, the team even offered additional services at no extra charge.
              The team stood out because of their swift response time and patience. Cubix provided one contact person, which helped streamline project management and coordination. Not only did Cubix follow the budget and timeline, the team even offered additional services at no extra charge.</p>
              <div className="author-detail">
                <span className="avatar">
                  <img src="https://www.cubix.co/storage/app/media/about/karen-agresti-nomidate.jpg" alt="" />
                </span>
                <div className="author-info">
                  <strong className="title">Karen Agresti, Founder</strong>
                  <span className="location">Nomidate</span>
                </div>
              </div>
            </div>
            <div className="client-comments-item wow fadeInUp">
              <p className="message">The team stood out because of their swift response time and patience. Cubix provided one contact person, which helped streamline project management and coordination. Not only did Cubix follow the budget and timeline, the team even offered additional services at no extra charge.
              The team stood out because of their swift response time and patience. Cubix provided one contact person, which helped streamline project management and coordination. Not only did Cubix follow the budget and timeline, the team even offered additional services at no extra charge.
              The team stood out because of their swift response time and patience. Cubix provided one contact person, which helped streamline project management and coordination. Not only did Cubix follow the budget and timeline, the team even offered additional services at no extra charge.</p>
              <div className="author-detail">
                <span className="avatar">
                  <img src="https://www.cubix.co/storage/app/media/about/karen-agresti-nomidate.jpg" alt="" />
                </span>
                <div className="author-info">
                  <strong className="title">Karen Agresti, Founder</strong>
                  <span className="location">Nomidate</span>
                </div>
              </div>
            </div>
            <div className="client-comments-item wow fadeInUp">
              <p className="message">The team stood out because of their swift response time and patience. Cubix provided one contact person, which helped streamline project management and coordination. Not only did Cubix follow the budget and timeline, the team even offered additional services at no extra charge.</p>
              <div className="author-detail">
                <span className="avatar">
                  <img src="https://www.cubix.co/storage/app/media/about/karen-agresti-nomidate.jpg" alt="" />
                </span>
                <div className="author-info">
                  <strong className="title">Karen Agresti, Founder</strong>
                  <span className="location">Nomidate</span>
                </div>
              </div>
            </div>
            <div className="client-comments-item wow fadeInUp">
              <p className="message">The team stood out because of their swift response time and patience. Cubix provided one contact person, which helped streamline project management and coordination. Not only did Cubix follow the budget and timeline, the team even offered additional services at no extra charge.
              The team stood out because of their swift response time and patience. Cubix provided one contact person, which helped streamline project management and coordination. Not only did Cubix follow the budget and timeline, the team even offered additional services at no extra charge.</p>
              <div className="author-detail">
                <span className="avatar">
                  <img src="https://www.cubix.co/storage/app/media/about/karen-agresti-nomidate.jpg" alt="" />
                </span>
                <div className="author-info">
                  <strong className="title">Karen Agresti, Founder</strong>
                  <span className="location">Nomidate</span>
                </div>
              </div>
            </div>
            <div className="client-comments-item wow fadeInUp">
              <p className="message">The team stood out because of their swift response time and patience. Cubix provided one contact person, which helped streamline project management and coordination. Not only did Cubix follow the budget and timeline, the team even offered additional services at no extra charge.</p>
              <div className="author-detail">
                <span className="avatar">
                  <img src="https://www.cubix.co/storage/app/media/about/karen-agresti-nomidate.jpg" alt="" />
                </span>
                <div className="author-info">
                  <strong className="title">Karen Agresti, Founder</strong>
                  <span className="location">Nomidate</span>
                </div>
              </div>
            </div>
            <div className="client-comments-item wow fadeInUp">
              <p className="message">The team stood out because of their swift response time and patience. Cubix provided one contact person, which helped streamline project management and coordination. Not only did Cubix follow the budget and timeline, the team even offered additional services at no extra charge.
              The team stood out because of their swift response time and patience. Cubix provided one contact person, which helped streamline project management and coordination. Not only did Cubix follow the budget and timeline, the team even offered additional services at no extra charge.
              The team stood out because of their swift response time and patience. Cubix provided one contact person, which helped streamline project management and coordination. Not only did Cubix follow the budget and timeline, the team even offered additional services at no extra charge.</p>
              <div className="author-detail">
                <span className="avatar">
                  <img src="https://www.cubix.co/storage/app/media/about/karen-agresti-nomidate.jpg" alt="" />
                </span>
                <div className="author-info">
                  <strong className="title">Karen Agresti, Founder</strong>
                  <span className="location">Nomidate</span>
                </div>
              </div>
            </div>
          </Masonry>
        </Container>
      </section>
      {/* Client Comments Section End */}
    </div>
  )
};

AboutTestimonials.propTypes = {
};

export default AboutTestimonials;

