/**
 *
 * Mobylogix
 * Author: Mobylogix
 * Email: info@mobylogix.com
 * Website: mobylogix.com
 * Version: 1.0
 * Date: Mon Nov 15 2021
 * Category: Pages
 * Title: SolutionsInnerPage
 *
 */

import React, { useState } from 'react';
import { Container, TabContent, TabPane, Nav, NavItem, NavLink, Row, Col, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import classnames from 'classnames';

// Components
import SolutionsBanner from 'components/SolutionsBanner';

const SolutionsInnerPage = (props) => {
  const [activeTab, setActiveTab] = useState('1');

  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }

  const tabsContent = [
    {
      id: '1',
      imgPath: 'https://www.cubix.co/themes/responsiv-clean/assets/images/cubix-happyforce/Happyforce-Timelog.png',
      title: 'Time management',
      description: 'Cubix HappyForce provides prompt and up to date time logs and employee attendance. HR professionals can easily validate and track the check-ins and check-outs of employees. Short hours, overtime and all the necessary details about employee attendance can be managed on the go.'
    },
    {
      id: '2',
      imgPath: 'https://www.cubix.co/themes/responsiv-clean/assets/images/cubix-happyforce/Happyforce-Training.png',
      title: 'Employee development',
      description: 'Successful organizations focus on employee training, and it’s their constant part of the play. When it comes to conducting employee training sessions, Cubix Happyforce provides a seamless feature. It helps managing training sessions and also deals with the track record of employees who have received.'
    },
    {
      id: '3',
      imgPath: 'https://www.cubix.co/themes/responsiv-clean/assets/images/cubix-happyforce/Happyforce-Events.png',
      title: 'Event management',
      description: 'Cubix Happyforce lets you create events and all activities of it. Be it assigning different roles to various employees or carrying out all event activities swiftly; the solution provides all the necessary options for indoor and outdoor events of an organization.'
    },
    {
      id: '4',
      imgPath: 'https://www.cubix.co/themes/responsiv-clean/assets/images/cubix-happyforce/Happyforce-Timelog.png',
      title: 'Time management',
      description: 'Cubix HappyForce provides prompt and up to date time logs and employee attendance. HR professionals can easily validate and track the check-ins and check-outs of employees. Short hours, overtime and all the necessary details about employee attendance can be managed on the go.'
    },
    {
      id: '5',
      imgPath: 'https://www.cubix.co/themes/responsiv-clean/assets/images/cubix-happyforce/Happyforce-Training.png',
      title: 'Employee development',
      description: 'Successful organizations focus on employee training, and it’s their constant part of the play. When it comes to conducting employee training sessions, Cubix Happyforce provides a seamless feature. It helps managing training sessions and also deals with the track record of employees who have received.'
    },
    {
      id: '6',
      imgPath: 'https://www.cubix.co/themes/responsiv-clean/assets/images/cubix-happyforce/Happyforce-Events.png',
      title: 'Event management',
      description: 'Cubix Happyforce lets you create events and all activities of it. Be it assigning different roles to various employees or carrying out all event activities swiftly; the solution provides all the necessary options for indoor and outdoor events of an organization.'
    },
    {
      id: '7',
      imgPath: 'https://www.cubix.co/themes/responsiv-clean/assets/images/cubix-happyforce/Happyforce-Training.png',
      title: 'Employee development',
      description: 'Successful organizations focus on employee training, and it’s their constant part of the play. When it comes to conducting employee training sessions, Cubix Happyforce provides a seamless feature. It helps managing training sessions and also deals with the track record of employees who have received.'
    }
  ];

  const widgets = [
    {
      path: 'https://www.cubix.co/themes/responsiv-clean/assets/images/cubix-happyforce/cubix-happyforce-time%20off.svg',
      title: 'Time off',
      description: 'Make time-offs a thing of the past with Cubix Happyforce. The software tracks every employee in an organization individually.'
    },
    {
      path: 'https://www.cubix.co/themes/responsiv-clean/assets/images/cubix-happyforce/cubix-happyforce-time%20off.svg',
      title: 'Time off',
      description: 'Make time-offs a thing of the past with Cubix Happyforce. The software tracks every employee in an organization individually.'
    },
    {
      path: 'https://www.cubix.co/themes/responsiv-clean/assets/images/cubix-happyforce/cubix-happyforce-time%20off.svg',
      title: 'Time off',
      description: 'Make time-offs a thing of the past with Cubix Happyforce. The software tracks every employee in an organization individually.'
    },
    {
      path: 'https://www.cubix.co/themes/responsiv-clean/assets/images/cubix-happyforce/cubix-happyforce-time%20off.svg',
      title: 'Time off',
      description: 'Make time-offs a thing of the past with Cubix Happyforce. The software tracks every employee in an organization individually.'
    },
    {
      path: 'https://www.cubix.co/themes/responsiv-clean/assets/images/cubix-happyforce/cubix-happyforce-time%20off.svg',
      title: 'Time off',
      description: 'Make time-offs a thing of the past with Cubix Happyforce. The software tracks every employee in an organization individually.'
    },
    {
      path: 'https://www.cubix.co/themes/responsiv-clean/assets/images/cubix-happyforce/cubix-happyforce-time%20off.svg',
      title: 'Time off',
      description: 'Make time-offs a thing of the past with Cubix Happyforce. The software tracks every employee in an organization individually.'
    }
  ];

  return (
    <div className="inner-page solutions-inner-page">
      {/* Solutions Banner */}
      <SolutionsBanner />
      {/* Solutions Banner End */}
      {/* Solutions Tabs Section */}
      <section className="solutions-tabs-section">
        <Container>
          <div className="main-heading">
            <h2 className="main-title wow fadeInUp">Feature rich solution for your HR needs</h2>
            <p className="description wow fadeInUp">Optimize your hr process on the go with analytical features</p>
          </div>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '1' })}
                onClick={() => { toggle('1'); }}
              >
                Time Log
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '2' })}
                onClick={() => { toggle('2'); }}
              >
                Training
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '3' })}
                onClick={() => { toggle('3'); }}
              >
                Event
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '4' })}
                onClick={() => { toggle('4'); }}
              >
                Record
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '5' })}
                onClick={() => { toggle('5'); }}
              >
                Social media
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '6' })}
                onClick={() => { toggle('6'); }}
              >
                Dashboard
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '7' })}
                onClick={() => { toggle('7'); }}
              >
                Roster
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            {tabsContent.map((tab, i) => (
              <TabPane tabId={tab.id} key={i}>
                <Row className="row-holder">
                  <Col xs='12' md='6'>
                    <figure className="img-wrap">
                      <img src={tab.imgPath} alt="" />
                    </figure>
                  </Col>
                  <Col xs='12' md='6'>
                    <div className="main-heading main-heading-left">
                      <h3 className="main-title">{tab.title}</h3>
                      <p className="description">{tab.description}</p>
                    </div>
                  </Col>
                </Row>
              </TabPane>
            ))}
          </TabContent>
        </Container>
      </section>
      {/* Solutions Tabs Section End */}
      {/* Solutions Multi Widgets */}
      <section className="solutions-multi-widgets">
        <Container>
          <div className="main-heading">
            <h2 className="main-title">Feature rich solution for your HR needs</h2>
            <p className="description">Optimize your hr process on the go with analytical features</p>
          </div>
          <Row>
            {widgets.map((widget, i) => (
              <Col xs="12" md="6" lg="4" key={i}>
                <Card className="solutions-widget">
                  <CardBody>
                    <figure className="icon-wrap">
                      <img src={widget.path} alt="" />
                    </figure>
                    <CardTitle tag="h3">{widget.title}</CardTitle>
                    <CardText>{widget.description}</CardText>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
      {/* Solutions Multi Widgets End */}
      {/* Solutions Detail Widgets */}
      <section className="solutions-detail-widgets">
        <Container>
          <Row className="row-holder">
            <Col xs="12" md="6">
              <div className="detail-widget-caption">
                <figure className="icon-wrap">
                  <img src="https://www.cubix.co/themes/responsiv-clean/assets/images/cubix-happyforce/cubix-happyforce-job-portal.svg" alt="" />
                </figure>
                <h3 className="title">Job portal</h3>
                <p className="description">Cubix HappyForce includes a job portal where you can enlist jobs, filter candidates, send invitations to the potential candidates, screen-in the finalized candidates, etc. Moreover, the portal lets you take suggestions from the people within the organization whether the candidate should be hired or not.</p>
              </div>
            </Col>
            <Col xs="12" md="6">
              <figure className="img-wrap">
                <img src="https://www.cubix.co/themes/responsiv-clean/assets/images/cubix-happyforce/cubix-happyforce-job-portal.png" alt="" />
              </figure>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Solutions Detail Widgets End */}
      {/* Solutions Detail Widgets */}
      <section className="solutions-detail-widgets">
        <Container>
          <Row className="row-holder">
            <Col xs="12" md="6">
              <figure className="img-wrap">
                <img src="https://www.cubix.co/themes/responsiv-clean/assets/images/cubix-happyforce/cubix-happyforce-job-portal.png" alt="" />
              </figure>
            </Col>
            <Col xs="12" md="6">
              <div className="detail-widget-caption">
                <figure className="icon-wrap">
                  <img src="https://www.cubix.co/themes/responsiv-clean/assets/images/cubix-happyforce/cubix-happyforce-job-portal.svg" alt="" />
                </figure>
                <h3 className="title">Job portal</h3>
                <p className="description">Cubix HappyForce includes a job portal where you can enlist jobs, filter candidates, send invitations to the potential candidates, screen-in the finalized candidates, etc. Moreover, the portal lets you take suggestions from the people within the organization whether the candidate should be hired or not.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Solutions Detail Widgets End */}
      {/* Solution Video Section */}
      <section className="solutions-video-section">
        <Container>
          <div className="main-heading">
            <h2 className="main-title">Make your employees happy with happyforce</h2>
            <p className="description">Make your employees your biggest strength</p>
          </div>
          <iframe width="742px" height="417px" src="https://www.youtube.com/embed/K3WSiuX-gME" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </Container>
      </section>
      {/* Solution Video Section End */}
    </div>
  )
};

SolutionsInnerPage.propTypes = {
};

export default SolutionsInnerPage;

