/**
 *
 * Mobylogix
 * Author: Mobylogix
 * Email: info@mobylogix.com
 * Website: mobylogix.com
 * Version: 1.0
 * Date: Thu Nov 11 2021
 * Category: Pages
 * Title: ProductIntroduction
 *
 */

import React from 'react';
import { Container, Row, Col, Card, CardBody, CardTitle, Button } from 'reactstrap';

// styles
import './product-introduction.css';

const ProductIntroduction = (props) => (
  <div className="product-introduction">
    <Container>
      <Row className="row-justify-center">
        <Col xs="12" md="7" lg="7">
          <div className="main-heading main-heading-left">
            <h2 className="main-title wow fadeInDown">Introducing curious – an anonymous social platform to express your true self!</h2>
            <Row>
              <Col xs="12" md="6" lg="7">
                <div className="intro-caption-wrap">
                  <p className="title wow fadeInDown"><strong>Ever struggled with anxiety when posting on social platforms? Curious is a revolution in the world of digital socializing, where it offers users a platform to express themselves with complete anonymity.</strong></p>
                  <p className="wow fadeInDown">Explore places to visit, different events, and expand your social circle on the web with Curious. The app offers multiple exciting features allowing users to post videos, images, text, or audio content to share with the world.</p>
                </div>
              </Col>
              <Col xs="12" md="6" lg="5">
                <Card className="simple-card wow fadeInDown">
                  <CardBody>
                    <CardTitle tag="h3">Looking for something similar?</CardTitle>
                    <p>Our consultants are ready to hear your idea. Request a free consultation with our app experts and transform it into a digital reality.</p>
                    <Button color="primary">Get In Touch</Button>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
          <Row>
            <Col xs="12" md="6">
              <figure className="img-wrap wow fadeInLeft">
                <img src="https://www.cubix.co/themes/responsiv-clean/assets/images/curious-portfolio/Curious-section-1mobile.png" alt="" />
              </figure>
            </Col>
            <Col xs="12" md="6">
              <figure className="img-wrap wow fadeInRight">
                <img src="https://www.cubix.co/themes/responsiv-clean/assets/images/curious-portfolio/Curious-section-1mobile.png" alt="" />
              </figure>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  </div>
);

ProductIntroduction.propTypes = {};

export default ProductIntroduction;
