/**
 *
 * Mobylogix
 * Author: Mobylogix
 * Email: info@mobylogix.com
 * Website: mobylogix.com
 * Version: 1.0
 * Date: Tue Nov 09 2021
 * Category: Pages
 * Title: MobileAppsDevelopment
 *
 */

import React, { useState } from 'react';
import classnames from 'classnames';
import { Container, Row, Col, TabContent, TabPane, Nav, NavItem, NavLink,  } from 'reactstrap';

// Components
import InnerBanner from 'components/InnerBanner';
import DevelopmentServices from 'components/DevelopmentServices';
import CreativeSection from 'components/CreativeSection';
import ProcessSection from 'components/ProcessSection';
import TechnologyStackSection from 'components/TechnologyStackSection';
import InsightsSection from 'components/InsightsSection';
import HighlightsSection from 'components/HighlightsSection';
import TestimonialsSection from 'components/TestimonialsSection';
import AwardsSection from 'components/AwardsSection';
import ProjectForm from 'components/ProjectForm';
import FeaturedWork from 'components/FeaturedWork';

// util
import { amazon, angular, googleCloud, html, javaScript, microsoftAzure, nodejs, python, react } from "../../utils/technolgy";
import { WebAppsMeta } from 'utils/metas';

const SaasWebAppsDevelopment = (props) => {

  const [activeTab, setActiveTab] = useState('1');
  const toggle = tab => activeTab !== tab && setActiveTab(tab);

  const settings = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  return (
    <div className="inner-page mobile-app-development">
      {/* Meta */}
      <WebAppsMeta />
      {/* Meta End */}
      {/* Inner Banner */}
      <InnerBanner title={"SAAS WEB APPS DEVELOPMENT SERVICES"} description={"Convert your product vision into a plan on tech implementation"} />
      {/* Inner Banner End */}
      {/* Development Services */}
      <DevelopmentServices title={"SAAS WEB APPS DEVELOPMENT SERVICES"} description={"A SaaS web application plays a major role in developing business by enhancing boosting and visibility of brand awareness. It has become decisive in nearly every phase of online business because it serves as the digital face of the company."} />
      {/* Development Services End */}
      {/* Creative Section */}
      <CreativeSection
        title={"SaaS/Web Applications Development Assistance"}
        description={"Transform your vision of the product into a plan for tech implementation."}
      />
      {/* Creative Section End */}

      {/* Featured Work Slider */}
      <FeaturedWork />
      {/* Featured Work Slider End */}

      {/* Caption Section */}
      <section className="deve-caption-wrap deve-caption-primary">
        <Container>
          <Row className="row-justify-center">
            <Col xs="12" md="9" lg="7">
              <div className="main-heading">
                <h2 className="main-title">Work with a globally-recognized web app development company</h2>
                <p className="description">We are the leading web app development company in the USA providing web applications to power the visibility and mobility of your business. As an expert in web application development services in the USA, we have been creating feature-packed, high-performance wweb applications for all types of businesses and industries.</p>
                <p className="description">We are recognized as one of the world’s most desired Web app development companies. Our  application developers, strategists, and designers are fueled with creativity and passion for web app design. This is why our services are recognized among the best in the industry.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Caption Section End */}
      {/* Caption Section */}
      <section className="deve-caption-wrap">
        <Container>
          <Row className="row-justify-center">
            <Col xs="12" md="9" lg="7">
              <div className="main-heading">
                <h2 className="main-title">Web app services the Mobylogix way</h2>
                <p className="description">Whether you are a startup, a small-scale business or an enterprise looking to build a web apps, our development process remains the same. We convert your ideas into successful apps that become an integral part of everyone’s lives. With our fast and smooth app development process, the apps we build are user-friendly, robust, engaging, and well-crafted.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Caption Section End */}
      {/* Caption Section */}
      <section className="deve-caption-wrap deve-caption-primary">
        <Container>
          <Row className="row-justify-center">
            <Col xs="12" md="9" lg="7">
              <div className="main-heading">
                <h2 className="main-title">Highly Advance Web App Design & Development Services</h2>
                <p className="description">There is no doubt that we are living in a digital era where businesses are using technologies to reach out to their customers. Today, more than 60% of users research products and services online making it crucial for you to build a web application to meet the world-class standards. </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Caption Section End */}
      {/* Process Section */}
      <ProcessSection />
      {/* Process Section End */}
      {/* Caption Section */}
      <section className="deve-caption-wrap deve-caption-primary">
        <Container>
          <Row className="row-justify-center">
            <Col xs="12" md="9" lg="7">
              <div className="main-heading">
                <h2 className="main-title">Why Choose Our Web App Development Services?</h2>
                <p className="description">Mobylogix is the top web application development company in the USA. We create flawless web applications with advanced programming and technological tools.</p>
                <p className="description">Our team has profound knowledge of web app design & development and working on JavaScript, PHP, Drupal, CSS, Wordpress, etc. We implement all the latest technologies so you can better streamline your business and maximize your brand awareness. </p>
                <p className="description">We not only develop your website but also make sure that it gets high ranking through SEO (Search Engine Optimization). Our team works on every aspect of your website to generate the most impressive results. Using our best in-house technologies and expertise.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Caption Section End */}
      {/* Technology Section */}
      <div className="technology-stack-section">
        <Container>
          <div className="main-heading main-heading-left">
            <h2 className="main-title">Web apps development – technology stack</h2>
            <p className="description">We create Web apps with scalable and tested technology stack to deliver apps par excellence.</p>
          </div>
          <Row className="row-holder">
            <Col xs="12" lg="5">
              <h3 className="title">Language</h3>
              <Nav tabs>
              <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '9' })}
                    onClick={() => toggle('9')}
                  >
                    <img src="/images/Web Development/react.png" alt="" />
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '2' })}
                    onClick={() => toggle('2')}
                  >
                    <img src="/images/Web Development/angular.png" alt="" />
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '8' })}
                    onClick={() => toggle('8')}
                  >
                    <img src="/images/Web Development/python.png" alt="" />
                  </NavLink>
                </NavItem>
              </Nav>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '4' })}
                    onClick={() => toggle('4')}
                  >
                    <img src="/images/Web Development/html.png" alt="" />
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '5' })}
                    onClick={() => toggle('5')}
                  >
                    <img src="/images/Web Development/java-script.png" alt="" />
                  </NavLink>
                </NavItem>
              </Nav>
              <h3 className="title">Database</h3>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '6' })}
                    onClick={() => toggle('6')}
                  >
                    <img src="/images/Web Development/microsoft-azure.png" alt="" />
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '7' })}
                    onClick={() => toggle('7')}
                  >
                    <img src="/images/Web Development/node-js.png" alt="" />
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '3' })}
                    onClick={() => toggle('3')}
                  >
                    <img src="/images/Web Development/google-cloud.png" alt="" />
                  </NavLink>
                </NavItem>
              </Nav>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '1' })}
                    onClick={() => toggle('1')}
                  >
                    <img src="/images/Web Development/amazon-web-services.png" alt="" />
                  </NavLink>
                </NavItem>
  
              </Nav>
            </Col>
            <Col xs="12" lg="7">
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <div className="tabs-content-caption">
                    <div className="title-wrap">
                      <img src="/images/Web Development/amazon-web-services.png" alt="" className="icon" />
                      <h3 className="title">{amazon.title}</h3>
                    </div>
                    <p className="description">{amazon.description}</p>
                  </div>
                </TabPane>
                <TabPane tabId="2">
                  <div className="tabs-content-caption">
                    <div className="title-wrap">
                      <img src="/images/Web Development/angular.png" alt="" className="icon" />
                      <h3 className="title">{angular.title}</h3>
                    </div>
                    <p className="description">{angular.description}</p>
                  
                  </div>
                </TabPane>
                <TabPane tabId="3">
                  <div className="tabs-content-caption">
                    <div className="title-wrap">
                      <img src="/images/Web Development/google-cloud.png" alt="" className="icon" />
                      <h3 className="title">{googleCloud.title}</h3>
                    </div>
                    <p className="description">{googleCloud.description}</p>
                  </div>
                </TabPane>
                <TabPane tabId="4">
                  <div className="tabs-content-caption">
                    <div className="title-wrap">
                      <img src="/images/Web Development/html.png" alt="" className="icon" />
                      <h3 className="title">{html.title}</h3>
                    </div>
                    <p className="description">{html.description}</p>
                  </div>
                </TabPane>
                <TabPane tabId="5">
                  <div className="tabs-content-caption">
                    <div className="title-wrap">
                      <img src="/images/Web Development/java-script.png" alt="" className="icon" />
                      <h3 className="title">{javaScript.title}</h3>
                    </div>
                    <p className="description">{javaScript.description}</p>
                  </div>
                </TabPane>
                <TabPane tabId="6">
                  <div className="tabs-content-caption">
                    <div className="title-wrap">
                      <img src="/images/Web Development/microsoft-azure.png" alt="" className="icon" />
                      <h3 className="title">{microsoftAzure.title}</h3>
                    </div>
                    <p className="description">{microsoftAzure.description}</p>
                  </div>
                </TabPane>
                <TabPane tabId="7">
                  <div className="tabs-content-caption">
                    <div className="title-wrap">
                      <img src="images/Web Development/node-js.png" alt="" className="icon" />
                      <h3 className="title">{nodejs.title}</h3>
                    </div>
                    <p className="description">{nodejs.description}</p>
                  </div>
                </TabPane>
                <TabPane tabId="8">
                  <div className="tabs-content-caption">
                    <div className="title-wrap">
                      <img src="/images/Web Development/python.png" alt="" className="icon" />
                      <h3 className="title">{python.title}</h3>
                    </div>
                    <p className="description">{python.description}</p>
                  </div>
                </TabPane>
                <TabPane tabId="9">
                  <div className="tabs-content-caption">
                    <div className="title-wrap">
                      <img src="/images/Web Development/react.png" alt="" className="icon" />
                      <h3 className="title">{react.title}</h3>
                    </div>
                    <p className="description">{react.description}</p>
                  </div>
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </Container>
      </div>
      {/* Technology Section End */}

      {/* Insights Section */}
      {/* <InsightsSection title="Key Highlights our work in numbers."  description="Build a thriving web presence with programming tools like Python, Angular, React Native, and renowned cloud services with creative experts who formulate customized solutions." /> */}
      {/* Insights Section End */}
      
      {/* Highlight Section */}
      <HighlightsSection />
      {/* Highlight Section End */}
      {/* Testimonials Section */}
      <TestimonialsSection />
      {/* Testimonials Section End */}
      {/* Awards Section */}
      <AwardsSection />
      {/* Awards Section End */}
      {/* Project Form */}
      <ProjectForm />
      {/* Project Form End */}
    </div>
  )
};

export default SaasWebAppsDevelopment;

