/**
 *
 * Mobylogix
 * Author: Mobylogix
 * Email: info@mobylogix.com
 * Website: mobylogix.com
 * Version: 1.0
 * Date: Tue Nov 09 2021
 * Category: Pages
 * Title: MobileAppsDevelopment
 *
 */

import React, { useState, useEffect } from 'react';
import Slider from "react-slick";
import { useCountUp } from 'react-countup';
import { Container, Row, Col, TabContent, TabPane, Nav, NavItem, NavLink,  } from 'reactstrap';
import classnames from 'classnames';

// util
import { drupal, joomla, magento, shopify, wordpress } from "../../utils/technolgy";

// Components
import InnerBanner from 'components/InnerBanner';
import DevelopmentServices from 'components/DevelopmentServices';
import CreativeSection from 'components/CreativeSection';
import ProcessSection from 'components/ProcessSection';
import TechnologyStackSection from 'components/TechnologyStackSection';
import InsightsSection from 'components/InsightsSection';
import HighlightsSection from 'components/HighlightsSection';
import TestimonialsSection from 'components/TestimonialsSection';
import AwardsSection from 'components/AwardsSection';
import ProjectForm from 'components/ProjectForm';
import FeaturedWork from 'components/FeaturedWork';

// utli
import { EcommerceMeta } from 'utils/metas';

const EcommerceAppsDevelopment = (props) => {
  // const totalHeight = document.getElementsByClassName('mobile-app-development')[0].offsetHeight;

  // useEffect(() => {
  //   let height = document.getElementsByClassName('mobile-app-development')[0].offsetHeight;

  //   console.log('height', height);

  //   const calcHeight = (height / 100) * 75;

  //   console.log('height1', height);

  //   if (calcHeight > window.scrollY) {

  //   }
  // });

  const [activeTab, setActiveTab] = useState('1');

  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }

  const settings = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  return (
    <div className="inner-page mobile-app-development">
      {/* Meta */}
      <EcommerceMeta />
      {/* Meta End */}
      {/* Inner Banner */}
      <InnerBanner title={"Ecommerce Website Development Services"} description={"Get an extensive range of marketing opportunities for a huge targeted audience to get the better of your SALES round the clock with eCommerce Development!"} />
      {/* Inner Banner End */}
      {/* Development Services */}
      <DevelopmentServices title={"Supporting Well Defined Ecommerce Solutions"} description={"Along with our expert of e-commerce development and IT technologies expert, Mobylogix assists our clients in driving their customers' satisfaction and making their brand speak aloud in the whole e-commerce market."} />
      {/* Development Services End */}
      {/* Creative Section */}
      <CreativeSection 
        title={"Ecommerce Website Development Assistance"}
        description={"We provide with the most substantial range of marketing opportunities for a large number of target audience to better your sales with the ecommerce development platform."}
      />
      {/* Creative Section End */}
      {/* Featured Work Slider */}
      <FeaturedWork />
      {/* Featured Work Slider End */}
      {/* Product Slider */}
      {/* <section className="product-section">
        <Container>
          <div className="main-heading">
            <h2 className="main-title">Our featured works</h2>
          </div>
          <Slider {...settings} className="product-slider">
            <div className="product-slider-item">
              <Row className="row-holder">
                <Col xs="12" lg="6">
                  <div className="product-imgs">
                    <figure className="product-img">
                      <img src="/images/deribit.png" alt="" />
                    </figure>
                    <figure className="product-img-1">
                      <img src="https://www.Mobylogix.co/themes/responsiv-clean/assets/images/home/foodly-mobile-image-3.png" alt="" />
                    </figure>
                  </div>
                </Col>
                <Col xs="12" lg="6">
                  <div className="product-caption">
                    <figure className="product-icon">
                      <img src="/images/deribit.icon.png" alt="" />
                    </figure>
                    <strong className="sub-title">Mobile App</strong>
                    <h3 className="title">Foodly - the hub of delicious cuisines</h3>
                    <p className="description">Order breakfast, lunch, or dinner, rate your favorite restaurants and track your order - one platform to curb your hunger.</p>
                    <a href="#" rel="nooppener noreferrer" className="link-underline">View Case Study</a>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="product-slider-item">
              <Row className="row-holder">
                <Col xs="12" lg="6">
                  <div className="product-imgs">
                    <figure className="product-img">
                      <img src="/images/craftsman.png" alt="" />
                    </figure>
                    <figure className="product-img-1">
                      <img src="" alt="" />
                    </figure>
                  </div>
                </Col>
                <Col xs="12" lg="6">
                  <div className="product-caption">
                    <figure className="product-icon">
                      <img src="/images/craftsman-icon.png" alt="" />
                    </figure>
                    <strong className="sub-title">Mobile App</strong>
                    <h3 className="title">Foodly - the hub of delicious cuisines</h3>
                    <p className="description">Order breakfast, lunch, or dinner, rate your favorite restaurants and track your order - one platform to curb your hunger.</p>
                    <a href="#" rel="nooppener noreferrer" className="link-underline">View Case Study</a>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="product-slider-item">
              <Row className="row-holder">
                <Col xs="12" lg="6">
                  <div className="product-imgs">
                    <figure className="product-img">
                      <img src="/images/ahcip.chs.harvard.edu.png" alt="" />
                    </figure>
                    <figure className="product-img-1">
                      <img src="" alt="" />
                    </figure>
                  </div>
                </Col>
                <Col xs="12" lg="6">
                  <div className="product-caption">
                    <figure className="product-icon">
                      <img src="/images/ahcip.chs.harvard.edu.icon.png" alt="" />
                    </figure>
                    <strong className="sub-title">Mobile App</strong>
                    <h3 className="title">Foodly - the hub of delicious cuisines</h3>
                    <p className="description">Order breakfast, lunch, or dinner, rate your favorite restaurants and track your order - one platform to curb your hunger.</p>
                    <a href="#" rel="nooppener noreferrer" className="link-underline">View Case Study</a>
                  </div>
                </Col>
              </Row>
            </div>
          </Slider>
        </Container>
      </section> */}
      {/* Product Slider End */}
      {/* Caption Section */}
      {/* <section className="deve-caption-wrap deve-caption-primary">
        <Container>
          <Row className="row-justify-center">
            <Col xs="12" md="9" lg="7">
              <div className="main-heading">
                <h2 className="main-title">Work with a globally-recognized mobile app development company</h2>
                <p className="description">We are the leading mobile app development company in the USA providing apps to power the visibility and mobility of your business. As an expert in mobile application development services in the USA, we have been creating feature-packed, high-performance mobile applications for all types of businesses and industries.</p>
                <p className="description">We are recognized as one of the world’s most desired mobile app development companies. Our mobile app developers, strategists, and designers are fueled with creativity and passion for app design. This is why our mobile app development services are recognized among the best in the industry.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section> */}
      {/* Caption Section End */}
      {/* Caption Section */}
      <section className="deve-caption-wrap">
        <Container>
          <Row className="row-justify-center">
            <Col xs="12" md="9" lg="7">
              <div className="main-heading">
                <h2 className="main-title">Why choose us?</h2>
                <p className="description">Mobylogix’s  eCommerce website development team needs you to succeed online. A strong eCommerce website serves as the foundation of your online retail business, and Mobylogix understands the importance of utilizing the latest technology to maximize  beneficialness. Our eCommerce solution offers the best in standard features and functionality while remaining agile and completely customizable. Our team of experts can develop fully custom eCommerce  integration, functionality with ERP systems, pull data from different sources, and find a solution that matches your brand and eCommerce goals. The combination of our eCommerce website development services, along with years of  experience, makes us a good choice for your project.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Caption Section End */}
      {/* Caption Section */}
      {/* <section className="deve-caption-wrap deve-caption-primary">
        <Container>
          <Row className="row-justify-center">
            <Col xs="12" md="9" lg="7">
              <div className="main-heading">
                <h2 className="main-title">Apps developed by Mobylogix rake in success</h2>
                <p className="description">It doesn’t matter if you are an enterprise, a unicorn startup or a small-scale business, our development process stays the same, which has awesome written all over it. We begin by asking the number one question “How can I turn my idea into a successful app”. “An app that becomes an integral part of everyone’s life”. The answer: our app development process. We want our apps to be intuitive, appealing, robust and friendly.</p>
                <p className="description">We aren’t in a rush to escalate your project as we believe in detailed evaluation of a product, through various stages including Planning, sketching, wireframing, prototyping, developing, quality assurance, and launching an MVP. Our goal is to create mobile apps that are bound to rake in popularity, downloads and marvelous word of mouth.</p>
                <p className="description">You can find Mobylogix’s leading mobile app development team in USA, Lithuania, Lahore, and Islamabad.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section> */}
      {/* Caption Section End */}
      {/* Process Section */}
      {/* <ProcessSection /> */}
      {/* Process Section End */}
      {/* Caption Section */}
      {/* <section className="deve-caption-wrap deve-caption-primary">
        <Container>
          <Row className="row-justify-center">
            <Col xs="12" md="9" lg="7">
              <div className="main-heading">
                <h2 className="main-title">World-famous mobile application development company</h2>
                <p className="description">Mobile apps indeed hold a significant chunk of the market, currently (about 58%), making it important for your business to invest in one. If you have a wide customer base from your e-commerce website, imagine the power of pairing that with a mobile app!</p>
                <p className="description">Mobylogix is a popular mobile apps development company that helps you achieve your goals. We examine your particular need and work to develop your app solution to perfection! And it all starts with a rock-solid plan that not only sets the parameters of your project features, but also minutely defines and strategizes the length and breadth of programming required. This concrete plan is matched against a strict timeline to ensure delivery on time.</p>
                <p className="description">Every customer is important to us, as we believe in taking everyone who approaches us to the next level. So, no matter what kind of app you are desirous of building; we can build anything for you from scratch.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section> */}
      {/* Caption Section End */}
      {/* Technology Section */}
      <div className="technology-stack-section">
        <Container>
          <div className="main-heading main-heading-left">
            <h2 className="main-title">Ecommerce development – technology stack</h2>
            <p className="description">We create Ecommerce with scalable and tested technology stack to deliver apps par excellence.</p>
          </div>
          <Row className="row-holder">
            <Col xs="12" lg="5">
              <h3 className="title">CMS</h3>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '1' })}
                    onClick={() => { toggle('1'); }}
                  >
                    <img src="/images/ecommerce/drupal.png" alt="" />
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '2' })}
                    onClick={() => { toggle('2'); }}
                  >
                    <img src="/images/ecommerce/joomla.png" alt="" />
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '3' })}
                    onClick={() => { toggle('3'); }}
                  >
                    <img src="/images/ecommerce/magento.png" alt="" />
                  </NavLink>
                </NavItem>
              </Nav>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '4' })}
                    onClick={() => { toggle('4'); }}
                  >
                    <img src="/images/ecommerce/shopify.png" alt="" />
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '5' })}
                    onClick={() => { toggle('5'); }}
                  >
                    <img src="/images/ecommerce/wordpress.png" alt="" />
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
            <Col xs="12" lg="7">
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <div className="tabs-content-caption">
                    <div className="title-wrap">
                      <img src="/images/ecommerce/drupal.png" alt="" className="icon" />
                      <h3 className="title">{drupal.title}</h3>
                    </div>
                    <p className="description">{drupal.description}</p>
                  </div>
                </TabPane>
                <TabPane tabId="2">
                  <div className="tabs-content-caption">
                    <div className="title-wrap">
                      <img src="/images/ecommerce/joomla.png" alt="" className="icon" />
                      <h3 className="title">{joomla.title}</h3>
                    </div>
                    <p className="description">{joomla.description}</p>
                  
                  </div>
                </TabPane>
                <TabPane tabId="3">
                  <div className="tabs-content-caption">
                    <div className="title-wrap">
                      <img src="/images/ecommerce/magento.png" alt="" className="icon" />
                      <h3 className="title">{magento.title}</h3>
                    </div>
                    <p className="description">{magento.description}</p>
                  </div>
                </TabPane>
                <TabPane tabId="4">
                  <div className="tabs-content-caption">
                    <div className="title-wrap">
                      <img src="/images/ecommerce/shopify.png" alt="" className="icon" />
                      <h3 className="title">{shopify.title}</h3>
                    </div>
                    <p className="description">{shopify.description}</p>
                  </div>
                </TabPane>
                <TabPane tabId="5">
                  <div className="tabs-content-caption">
                    <div className="title-wrap">
                      <img src="/images/ecommerce/wordpress.png" alt="" className="icon" />
                      <h3 className="title">{wordpress.title}</h3>
                    </div>
                    <p className="description">{wordpress.description}</p>
                  </div>
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </Container>
      </div>
      {/* Technology Section End */}
      {/* Insights Section */}
      {/* <InsightsSection title="Technologies we work with for MVP" description="We build reliable eCommerce websites, which helps us overcome all the complex and challenging tasks.Build and launch your eCommerce store with our Development Process. We provide comprehensive ecommerce website development services for planning, building, and executing all sizes of online stores."/> */}
      {/* Insights Section End */}
      {/* Highlight Section */}
      <HighlightsSection />
      {/* Highlight Section End */}
      {/* Testimonials Section */}
      <TestimonialsSection />
      {/* Testimonials Section End */}
      {/* Awards Section */}
      <AwardsSection />
      {/* Awards Section End */}
      {/* Project Form */}
      <ProjectForm />
      {/* Project Form End */}
    </div>
  )
};

export default EcommerceAppsDevelopment;

