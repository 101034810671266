import { helmetJsonLdProp } from "react-schemaorg";
import { Helmet } from 'react-helmet';

export const HomeMeta = () => (
  <Helmet
  script={[
    helmetJsonLdProp({
        "@context": "https://schema.org/",
        "@type": "WebSite",
        "name": "Mobylogix",
        "url": "https://mobylogix.com/",
        "potentialAction": {
          "@type": "SearchAction",
          "target": "https://mobylogix.com//#features{search_term_string}",
          "query-input": "required name=search_term_string"
        }
      }),
    ]}
  >
    <title>Mobylogix - Software Service - Engineering & Marketing Company</title>
    <meta name="description" content="A trusted Engineering & Marketing Company. We have in-depth knowledge and experience to provide you creative designs and products." />
    <meta name="keywords" content="Engineering & Marketing Company" />
    <meta property="og:title" content="Mobylogix - Software Service - Engineering & Marketing Company" />
    <meta property="og:description" content="A trusted Engineering & Marketing Company. We have in-depth knowledge and experience to provide you creative designs and products." />
    <meta name="twitter:title" content="Mobylogix - Software Service - Engineering & Marketing Company" /> 
    <meta name="twitter:description" content="A trusted Engineering & Marketing Company. We have in-depth knowledge and experience to provide you creative designs and products." />
    <link rel="canonical" href="https://mobylogix.com/" />
  </Helmet>
);

export const MobileAppsMeta = () => (
  <Helmet
  script={[
    helmetJsonLdProp({
        "@context": "https://schema.org/",
        "@type": "WebSite",
        "name": "Mobylogix",
        "url": "https://mobylogix.com/mobile-app-development",
        "potentialAction": {
          "@type": "SearchAction",
          "target": "https://mobylogix.com//#features{search_term_string}",
          "query-input": "required name=search_term_string"
        }
      }),
    ]}
  >
    <title>Mobile Apps Development Services Provider in USA - Mobylogix</title>
    <meta name="description" content="Get affordable Mobile Apps development services by partnering with Mobylogix. We are the top Custom Mobile App Design & Development Services Company in USA." />
    <meta name="keywords" content="Mobile Apps Development Services" />
    <meta property="og:title" content="Mobile Apps Development Services Provider in USA - Mobylogix" />
    <meta property="og:description" content="Get affordable Mobile Apps development services by partnering with Mobylogix. We are the top Custom Mobile App Design & Development Services Company in USA." />
    <meta name="twitter:title" content="Mobile Apps Development Services Provider in USA - Mobylogix" /> 
    <meta name="twitter:description" content="Get affordable Mobile Apps development services by partnering with Mobylogix. We are the top Custom Mobile App Design & Development Services Company in USA." />
    <link rel="canonical" href="https://mobylogix.com/mobile-app-development" />
  </Helmet>
);

export const WebAppsMeta = () => (
  <Helmet
  script={[
    helmetJsonLdProp({
        "@context": "https://schema.org/",
        "@type": "WebSite",
        "name": "Mobylogix",
        "url": "https://mobylogix.com/saas-web-development",
        "potentialAction": {
          "@type": "SearchAction",
          "target": "https://mobylogix.com//#features{search_term_string}",
          "query-input": "required name=search_term_string"
        }
      }),
    ]}
  >
    <title>Top Custom Web App Design & Development services company in USA</title>
    <meta name="description" content="Mobylogix is a top best Custom Web App Design & Development services company in the USA. We provide Website Development Solutions to startups, enterprises." />
    <meta name="keywords" content="Web App Design & Development Services" />
    <meta property="og:title" content="Top Custom Web App Design & Development services company in USA" />
    <meta property="og:description" content="Mobylogix is a top best Custom Web App Design & Development services company in the USA. We provide Website Development Solutions to startups, enterprises." />
    <meta name="twitter:title" content="Top Custom Web App Design & Development services company in USA" /> 
    <meta name="twitter:description" content="Mobylogix is a top best Custom Web App Design & Development services company in the USA. We provide Website Development Solutions to startups, enterprises." />
    <link rel="canonical" href="https://mobylogix.com/saas-web-development" />
  </Helmet>
);

export const EcommerceMeta = () => (
  <Helmet
  script={[
    helmetJsonLdProp({
        "@context": "https://schema.org/",
        "@type": "WebSite",
        "name": "Mobylogix",
        "url": "https://mobylogix.com/ecommerce-development",
        "potentialAction": {
          "@type": "SearchAction",
          "target": "https://mobylogix.com//#features{search_term_string}",
          "query-input": "required name=search_term_string"
        }
      }),
    ]}
  >
    <title>eCommerce Website Development Company - Mobylogix</title>
    <meta name="description" content="Being the top service provider of eCommerce website development company, Mobylogix focuses on developing a unique webshop that saves you time and enhances your online business." />
    <meta name="keywords" content="eCommerce Website Development Company" />
    <meta property="og:title" content="eCommerce Website Development Company - Mobylogix" />
    <meta property="og:description" content="Being the top service provider of eCommerce website development company, Mobylogix focuses on developing a unique webshop that saves you time and enhances your online business." />
    <meta name="twitter:title" content="eCommerce Website Development Company - Mobylogix" /> 
    <meta name="twitter:description" content="Being the top service provider of eCommerce website development company, Mobylogix focuses on developing a unique webshop that saves you time and enhances your online business." />
    <link rel="canonical" href="https://mobylogix.com/ecommerce-development" />
  </Helmet>
);

export const MechineMeta = () => (
  <Helmet
  script={[
    helmetJsonLdProp({
        "@context": "https://schema.org/",
        "@type": "WebSite",
        "name": "Mobylogix",
        "url": "https://mobylogix.com/machine-learing",
        "potentialAction": {
          "@type": "SearchAction",
          "target": "https://mobylogix.com//#features{search_term_string}",
          "query-input": "required name=search_term_string"
        }
      }),
    ]}
  >
    <title>Artificial Intelligence Development Service Company - Mobylogix</title>
    <meta name="description" content="Artificial intelligence development service and solutions for enterprise customers. Enhance the way you do business and modernize your processes Mobylogix." />
    <meta name="keywords" content="Artificial Intelligence Development Service Company" />
    <meta property="og:title" content="Artificial Intelligence Development Service Company - Mobylogix" />
    <meta property="og:description" content="Artificial intelligence development service and solutions for enterprise customers. Enhance the way you do business and modernize your processes Mobylogix." />
    <meta name="twitter:title" content="Artificial Intelligence Development Service Company - Mobylogix" /> 
    <meta name="twitter:description" content="Artificial intelligence development service and solutions for enterprise customers. Enhance the way you do business and modernize your processes Mobylogix." />
    <link rel="canonical" href="https://mobylogix.com/machine-learing" />
  </Helmet>
);

export const BlockchainMeta = () => (
  <Helmet
  script={[
    helmetJsonLdProp({
        "@context": "https://schema.org/",
        "@type": "WebSite",
        "name": "Mobylogix",
        "url": "https://mobylogix.com/blockchain-development",
        "potentialAction": {
          "@type": "SearchAction",
          "target": "https://mobylogix.com//#features{search_term_string}",
          "query-input": "required name=search_term_string"
        }
      }),
    ]}
  >
    <title>Blockchain Technology Solutions - Blockchain Development Services</title>
    <meta name="description" content="Mobylogix is at the forefront of Blockchain Technology and its end-to-end solutions. We are the leading provider of Blockchain Development Services in USA." />
    <meta name="keywords" content="Blockchain Development Services" />
    <meta property="og:title" content="Blockchain Technology Solutions - Blockchain Development Services" />
    <meta property="og:description" content="Get affordable Mobile Apps development services by partnering with Mobylogix. We are the top Custom Mobile App Design & Development Services Company in USA." />
    <meta name="twitter:title" content="Blockchain Technology Solutions - Blockchain Development Services" /> 
    <meta name="twitter:description" content="Get affordable Mobile Apps development services by partnering with Mobylogix. We are the top Custom Mobile App Design & Development Services Company in USA." />
    <link rel="canonical" href="https://mobylogix.com/blockchain-development" />
  </Helmet>
);

export const CloudMeta = () => (
  <Helmet
  script={[
    helmetJsonLdProp({
        "@context": "https://schema.org/",
        "@type": "WebSite",
        "name": "Mobylogix",
        "url": "https://mobylogix.com/cloud-services",
        "potentialAction": {
          "@type": "SearchAction",
          "target": "https://mobylogix.com//#features{search_term_string}",
          "query-input": "required name=search_term_string"
        }
      }),
    ]}
  >
    <title>Cloud Computing Services Providers - Mobylogix</title>
    <meta name="description" content="Mobylogix is a top Cloud Computing Services Providers. We offer a wide range of flexible features and advantages for businesses as well as for end-users." />
    {/* <meta name="keywords" content="Blockchain Development Services" /> */}
    <meta property="og:title" content="Cloud Computing Services Providers - Mobylogix" />
    <meta property="og:description" content="Mobylogix is at the forefront of Blockchain Technology and its end-to-end solutions. We are the leading provider of Blockchain Development Services in USA." />
    <meta name="twitter:title" content="Cloud Computing Services Providers - Mobylogix" /> 
    <meta name="twitter:description" content="Mobylogix is at the forefront of Blockchain Technology and its end-to-end solutions. We are the leading provider of Blockchain Development Services in USA." />
    <link rel="canonical" href="https://mobylogix.com/cloud-services" />
  </Helmet>
);

export const VirtualRealityMeta = () => (
  <Helmet
  script={[
    helmetJsonLdProp({
        "@context": "https://schema.org/",
        "@type": "WebSite",
        "name": "Mobylogix",
        "url": "https://mobylogix.com/virtual-reality",
        "potentialAction": {
          "@type": "SearchAction",
          "target": "https://mobylogix.com//#features{search_term_string}",
          "query-input": "required name=search_term_string"
        }
      }),
    ]}
  >
    <title>Virtual Reality Development Company - Virtual Reality Services</title>
    <meta name="description" content="Mobylogix, a leading Virtual Reality Development Company, offers VR/AR solutions created by top developers on board. Get VR services today." />
    <meta name="keywords" content="Virtual Reality Development Company" />
    <meta property="og:title" content="irtual Reality Development Company - Virtual Reality Services" />
    <meta property="og:description" content="Mobylogix, a leading Virtual Reality Development Company, offers VR/AR solutions created by top developers on board. Get VR services today." />
    <meta name="twitter:title" content="irtual Reality Development Company - Virtual Reality Services" /> 
    <meta name="twitter:description" content="Mobylogix, a leading Virtual Reality Development Company, offers VR/AR solutions created by top developers on board. Get VR services today." />
    <link rel="canonical" href="https://mobylogix.com/virtual-reality" />
  </Helmet>
);

export const MVPDevelopmentMeta = () => (
  <Helmet
  script={[
    helmetJsonLdProp({
        "@context": "https://schema.org/",
        "@type": "WebSite",
        "name": "Mobylogix",
        "url": "https://mobylogix.com/mvp-development",
        "potentialAction": {
          "@type": "SearchAction",
          "target": "https://mobylogix.com//#features{search_term_string}",
          "query-input": "required name=search_term_string"
        }
      }),
    ]}
  >
    <title>Top MVP Development Service Company - Mobylogix</title>
    <meta name="description" content="Mobylogix offers MVP Development Services for startup companies to test their business ideas and start building great software." />
    <meta name="keywords" content="Top MVP Development Service Company" />
    <meta property="og:title" content="Top MVP Development Service Company - Mobylogix" />
    <meta property="og:description" content="Mobylogix offers MVP Development Services for startup companies to test their business ideas and start building great software." />
    <meta name="twitter:title" content="Top MVP Development Service Company - Mobylogix" /> 
    <meta name="twitter:description" content="Mobylogix offers MVP Development Services for startup companies to test their business ideas and start building great software." />
    <link rel="canonical" href="https://mobylogix.com/mvp-development" />
  </Helmet>
);

export const CMSDevelopmentMeta = () => (
  <Helmet
  script={[
    helmetJsonLdProp({
        "@context": "https://schema.org/",
        "@type": "WebSite",
        "name": "Mobylogix",
        "url": "https://mobylogix.com/custom-enterprise-software-development",
        "potentialAction": {
          "@type": "SearchAction",
          "target": "https://mobylogix.com//#features{search_term_string}",
          "query-input": "required name=search_term_string"
        }
      }),
    ]}
  >
    <title>Best Custom CMS Development Services Company in the USA</title>
    <meta name="description" content="Mobylogix is the best Custom CMS Development Services Company in the USA. We offer advance CMS services featuring Softwares, websites and CMS platforms." />
    <meta name="keywords" content="CMS Development Services" />
    <meta property="og:title" content="Best Custom CMS Development Services Company in the USA" />
    <meta property="og:description" content="Mobylogix is the best Custom CMS Development Services Company in the USA. We offer advance CMS services featuring Softwares, websites and CMS platforms." />
    <meta name="twitter:title" content="Best Custom CMS Development Services Company in the USA" /> 
    <meta name="twitter:description" content="Mobylogix is the best Custom CMS Development Services Company in the USA. We offer advance CMS services featuring Softwares, websites and CMS platforms." />
    <link rel="canonical" href="https://mobylogix.com/custom-enterprise-software-development" />
  </Helmet>
);

export const CareersMeta = () => (
  <Helmet
  script={[
    helmetJsonLdProp({
        "@context": "https://schema.org/",
        "@type": "WebSite",
        "name": "Mobylogix",
        "url": "https://mobylogix.com/careers",
        "potentialAction": {
          "@type": "SearchAction",
          "target": "https://mobylogix.com//#features{search_term_string}",
          "query-input": "required name=search_term_string"
        }
      }),
    ]}
  >
    <title>Careers - Mobylogix</title>
    <meta name="description" content="Mobylogix aims to build high-quality software while also fostering an enjoyable work environment that allows everyone the opportunity to reach their highest potential." />
    <meta name="keywords" content="Careers" />
    <meta property="og:title" content="Careers - Mobylogix" />
    <meta property="og:description" content="Mobylogix aims to build high-quality software while also fostering an enjoyable work environment that allows everyone the opportunity to reach their highest potential." />
    <meta name="twitter:title" content="Careers - Mobylogix" /> 
    <meta name="twitter:description" content="Mobylogix aims to build high-quality software while also fostering an enjoyable work environment that allows everyone the opportunity to reach their highest potential." />
    <link rel="canonical" href="https://mobylogix.com/careers" />
  </Helmet>
);

export const PrivacyPolicyMeta = () => (
  <Helmet
  script={[
    helmetJsonLdProp({
        "@context": "https://schema.org/",
        "@type": "WebSite",
        "name": "Mobylogix",
        "url": "https://mobylogix.com/privacy-policy",
        "potentialAction": {
          "@type": "SearchAction",
          "target": "https://mobylogix.com//#features{search_term_string}",
          "query-input": "required name=search_term_string"
        }
      }),
    ]}
  >
    <title>Privacy Policy - Engineering and Marketing Company</title>
    <meta name="description" content="This Privacy Policy has been prepared by Mobylogix ( Engineering and Marketing Company ) regarding Personal data collected on or through our website." />
    <meta name="keywords" content="Privacy Policy" />
    <meta property="og:title" content="Privacy Policy - Engineering and Marketing Company" />
    <meta property="og:description" content="This Privacy Policy has been prepared by Mobylogix ( Engineering and Marketing Company ) regarding Personal data collected on or through our website." />
    <meta name="twitter:title" content="Privacy Policy - Engineering and Marketing Company" /> 
    <meta name="twitter:description" content="This Privacy Policy has been prepared by Mobylogix ( Engineering and Marketing Company ) regarding Personal data collected on or through our website." />
    <link rel="canonical" href="https://mobylogix.com/privacy-policy" />
  </Helmet>
);

export const LinkedcampMeta = () => (
  <Helmet
  script={[
    helmetJsonLdProp({
        "@context": "https://schema.org/",
        "@type": "WebSite",
        "name": "Mobylogix",
        "url": "https://mobylogix.com/linkedcamp",
        "potentialAction": {
          "@type": "SearchAction",
          "target": "https://mobylogix.com//#features{search_term_string}",
          "query-input": "required name=search_term_string"
        }
      }),
    ]}
  >
    <title>Marketing Sales Automation Tool | LinkedCamp</title>
    <meta name="description" content="Linkedincamp happens to be a very famous and effective marketing sales automation tool that personalizes your outreach and results in three times better lead generation." />
    <meta name="author" content="mobylogix" />
    <meta name="keywords" content="Marketing Sales Automation Tool" />
    <meta property="og:title" content="Marketing Sales Automation Tool - LinkedCamp" />
    <meta property="og:description" content="Linkedincamp happens to be a very famous and effective marketing sales automation tool that personalizes your outreach and results in three times better lead generation." />
    <meta name="twitter:title" content="Marketing Sales Automation Tool - LinkedCamp" /> 
    <meta name="twitter:description" content="Linkedincamp happens to be a very famous and effective marketing sales automation tool that personalizes your outreach and results in three times better lead generation." />
    <link rel="canonical" href="https://linkedcamp.com" />
  </Helmet>
);
