export const objectivec= {
  title: 'objective-c',
  description:'To help enterprises and startups launch an app on the iOS platform, Mac OS, or iPad, Mobylogix provides a development team specializing in iOS app development.Objective C is a general-purpose, object-oriented language that offers convenience and flexibility in development and design.',
  src:"/images/objective-c.png"
};

export const redux= {
  title: 'Redux',
  description:'Mobylogix is home to a diverse team with varying skills and expertise, including the Redux library for managing application state. Redux is a state management tool that allows apps to run consistently in different environments.For startups, SMBs, and enterprises, we offer proficiency in Redux, helping you understand how the data flows in an application and more.',
  src:"/images/redux.png"
};

export const kotlin= {
  title: 'Kotlin',
  description:'With a diverse team of programmers, we offers exceptional skills in Kotlin and other programming languages to design innovative solutions for startups,  Fortune 500s, and enterprises.Kotlin works well for cross-platform applications, allowing businesses to target both Android and iOS simultaneously.',
  src:"/images/kotlin.png"
};

export const realm= {
  title: 'Realm',
  description:'The Realm is the open-source database management system, initially used for mobile operating systems. Mobylogix offers expertise in Realm Database to develop innovative solutions for your business.Enhance your performance and efficiently manage your operations with an object database management system that fits your needs.',
  src:"/images/realm.png"
};

export const swift= {
  title: 'Swift',
  description:'Are you looking to invest in an iOS application? Mobylogix offers exceptional iOS app development services, mobile games designing, finance apps, and more for startups, enterprises and SMBs.Swift is easy to use with a simple learning curve and allows agile development, convenient scalability, and flexibility in app design.',
  src:"/images/swift.png"
};

export const sqlite= {
  title: 'Sqlite',
  description:'SQLite is a relational database management system that is versatile and portable. Mobylogix provides its expertise to help startups and enterprises leverage their benefits.We help clients boost their business growth and take their operations online through the SQLite database.',
  src:"/images/sqlite.png"
};

export const firebase= {
  title: 'Firebase',
  description:'Diversity in skillset and years of experience empowers us to design and develop innovative mobile apps via Google’s Firebase platform for startups and enterprises.Whether it’s mobile apps or web apps, our team of developers can give life to your ideas and grow your business.',
  src:"/images/firebase.png"
};

export const javascript= {
  title: 'Javascript',
  description:'Whether it’s a website or a web applications, our designers and developers have experience in designing exceptional solutions using HTML5 and other web programming languages.With new elements and attributes, HTML5 offers more creative freedom for designers to craft your app as you imagined it.',
  src:"/images/javascript.png"
};

export const ffmpeg= {
  title: 'Ffmpeg',
  description:'Our creative experts offer exceptional application and web development services. Ffmpeg is one such cross-platform solution we excel at, helping entrepreneurs launch their businesses on the mobile platform.Ffmpeg is an excellent solution when you want to record, convert, and stream audios and videos.',
  src:"/images/ffmpeg.png"
};

export const roomDatabase= {
  title: 'Room-Database',
  description:'Mobylogix offers exceptional mobile and web development services, helping enterprises, startups and SMBs revolutionize their business through innovative digital solutions.For Android application development, we have developers specializing in Room Database, helping entrepreneurs boost their business.',
  src:"/images/room-database.png"
};

export const java= {
  title: 'Java',
  description:'Java helps you slash costs, fuel innovation, and boost app services for enterprise architecture, IoT, and cloud services for a fully satisfactory product.Full-stack Java development services with our knowledgeable, and skilled and experienced developers to take you forward',
  src:"/images/room-database.png"

};

export const angular ={
  title: "Angular",
  description:'From loading speed to a multitude of tooling options and much more, Angular delivers an engaging and purposeful user experience.'
};

export const googleCloud = {
  title: "Google Cloud",
  description:'With a thriving suite offering Gmail, file storage, Google Search, and YouTube you enjoy powerful web development services.'
};

export const html = {
  title: "Html",
  description:'Enjoy rapid loading speed along with flexibility and power for top-notch performance that pleases every one of your site visitors.'
};

export const javaScript = {
  title: "Java-script",
  description:'Bolster your web presence with back end technologies compatible with front end frameworks, lending you handy tools and libraries.'
};

export const microsoftAzure = {
  title: "Microsoft Azure",
  description:'Build programs, run tests, deploy assets, and manage apps and services via data centers that deliver powerful cloud computing.'
};

export const nodejs = {
  title: "Node-js",
  description:'Harness efficiency by creating robust server-side apps in a runtime environment and witness code execution outside a web browser.'
};

export const python= {
  title: "Python",
  description:'Leverage Python programming to convert your ideas into a feasible IT solution in the shape of a highly functional and well-suited mobile application.Build a powerful app with Python and open the doors to new possibilities and turnkey strategies that shape your business.'
};

export const react = {
  title: "React",
  description:"Leverage React's flexible and powerful framework to build purposeful front end aspects for your website solution that are engaging."
};

export const aion = {
  title: "Aion",
  description: "Solve niche issues with AION, a multi-tier blockchain system geared to power cutting-edge services, capable of building efficient and decentralized systems to be built.",
};

export const bitcoin = {
  title: "Bitcoin",
  description: "Embrace the world of the Bitcoin, a decentralized digital currency exchanged through robust and secure protocols based on Blockchain technology."
};

export const cardano = {
  title: "Cardano",
  description: "Participate with millions accessing Cardano, an open source cryptocurrency network and public blockchain platform accommodating smart contracts."
};

export const ethereum = {
  title: "Ethereum",
  description: "Build your application in a secure environment, and write code on a vibrant platform globally renowned for finance-related operations"
};

export const neblio = {
  title: "Neblio",
  description: "Simplify your blockchain ambitions with Token Protocol-1 coupled with unique metadata properties that tokenize tangible and digital assets."
};

export const quorum = {
  title :"Quorum",
  description: "Harness is an evidence-oriented programming language that is inclusive with screen reader technology, and aids you with building digital solutions."
};

export const ripple = {
  title: "Ripple",
  description: "Leverage a powerful and convenient currency exchange system with Ripple that offers real-time gross settlement system remittance network operations."
}

export const php = {
  title: "PHP",
  description:'Build your presence with PHP, a powerful and versatile technology for enterprise web portal development, e-business solutions, and complex e-commerce.Performance-oriented and highly scalable PHP development services deliver dynamic, vibrant, and complex systems and apps.'
}

export const Swift = {
  title: "Swift",
  description:'We build innovative on-demand food apps with intuitive features, including order tracking, multiple payment options, vendor management, and delivery tracking.'
}

export const Go = {
  title: "Go",
  description:'We build innovative on-demand food apps with intuitive features, including order tracking, multiple payment options, vendor management, and delivery tracking.'
}

export const ROR = {
  title : "ROR",
  description:'Gain from the amazing power of ROR, allowing talented developers to build outstanding and well-matched apps that meet your business requirements.Empower your project with the best ROR framework developers who build robust back-end user interfaces for your application.'
}

export const ui = {
  title : "UI & UX",
  description:'Build an innovative and user-friendly interface that allows our clients to accomplish excellent and fully-functional results within the given timeframe.Plan and craft an artistic face that engages your audience with your core features, functionalities and visuals in mind.'
}

export const andriod = {
  title : "Android",
  description:'More than 24,000 smart devices run on Android. No matter what you’re looking for, there’s always something you need from Android technology.Harness the power and beauty of the Android platform for your everyday routine, and gain from robust and reliable products.'
}

export const drupal = {
  title : "Drupal",
  description:'We build innovative on-demand food apps with intuitive features, including order tracking, multiple payment options, vendor management, and delivery tracking.'
}

export const joomla = {
  title : "Joomla",
  description:'We build innovative on-demand food apps with intuitive features, including order tracking, multiple payment options, vendor management, and delivery tracking.'
}

export const magento = {
  title : "Magento",
  description:'We build innovative on-demand food apps with intuitive features, including order tracking, multiple payment options, vendor management, and delivery tracking.'
}

export const shopify = {
  title : "Shopify",
  description:'We build innovative on-demand food apps with intuitive features, including order tracking, multiple payment options, vendor management, and delivery tracking.'
}

export const wordpress = {
  title : "Wordpress",
  description:'We build innovative on-demand food apps with intuitive features, including order tracking, multiple payment options, vendor management, and delivery tracking.'
}

export const androidstudio = {
  title : "Android Studio",
  description:'More than 24,000 smart devices run on Android. No matter what you’re looking for, there’s always something you need from Android technology.Harness the power and beauty of the Android platform for your everyday routine, and gain from robust and reliable products.'
}

export const unity = {
  title : "Unity 3d",
  description:'Grip users with powerful technologies by building real-time 3D assets to meet your game needs with animation for Films, Automotive, Architecture, etc.  Leverage a leading platform for real-time development with a vibrant ecosystem perfectly designed to meet your needs'
}

export const arkit = {
  title : "Arkit",
  description:'ARKit brings your applications to life with amazing options, allowing you to develop immersive and featured-rich gaming applications that hook users.With ARKit solutions, you possess the power to deliver experiences with the potential to generate significant revenue.'
}

export const arcore = {
  title : "Arcore",
  description:'Detect surfaces and understand the reality are the major features of ARCore. With ARCore technology, we can develop a unique and outstanding gaming app.Our technical expertise in ARCore allows us to develop games that let you interact with your target audience efficiently.'
}

export const mysql = {
  title : "Mysql",
  description:'Manage your data through a relational database management system based on SQL to ensure stable e-commerce, data warehousing, and logging applications.Ensure stability and robustness for all your projects that access vast amounts of data through PHP and java-based apps.'
}

export const sqlitevr = {
  title : "Sqlite",
  description:'SQLite is a relational database management system that is versatile and portable. Mobylogix provides its expertise to help startups and enterprises leverage their benefits.We help clients boost their business growth and take their operations online through the SQLite database.'
}

export const javavr = {
  title : "Java",
  description:'Java helps you slash costs, fuel innovation, and boost app services for enterprise architecture, IoT, and cloud services for a fully satisfactory product.Full-stack Java development services with our knowledgeable, and skilled and experienced developers to take you forward'
}

export const javascriptvr = {
  title : "javascript",
  description:'Whether it’s a website or a web applications, our designers and developers have experience in designing exceptional solutions using HTML5 and other web programming languages.With new elements and attributes, HTML5 offers more creative freedom for designers to craft your app as you imagined it.'
}

export const csharp = {
  title : "C#",
  description:'Interact with users by harnessing C#, a popular programming language for game development that delivers cross-platform compatibility for maximum outreach.Leverage C# with advanced back-end servers to unleash the power and versatility of object-oriented language.'
}

export const arkitandarcore = {
  title : "Arkit And Arcore",
  description:'With ArKit and Arcore, We build crucial augmented elements that bolster solutions with exciting and groundbreaking artificial intelligence results.Leverage 2D or 3D elements for a virtual experience that appears almost real in front of you no matter which platform your users are using'
}

export const corlocationforios = {
  title : "Cor Location For Ios",
  description:'iOS apps offer you compelling, advanced capabilities like API integration, mobility, and cloud ops that take your business forward at a rapid pace.Explore our enhanced approaches with iOS mobile apps that are scalable, reliable, secure, user-friendly, and affordable.'
}

export const gesturerecognitionkit = {
  title : "Gesture Recognition Kit",
  description:'Detect and Read gestures with 3D gesture and motion tracking controllers that learn motions and detect them with efficiency, enabling suitable responses. Touch-free solutions that give you a full control while identifying your presence with substantial security.'
}

export const iosmotioncapture = {
  title : "IOS Motion Capture",
  description:'Build products for biomechanic detection with mocap SDK for Android and iOS, and Integrate your device camera to generate augmented reality experiences.Build solutions with motion capture operable through smartphones that can capture 3d movements for various applications.'
}

export const mlkitsdk = {
  title : "Ml Kit Sdk",
  description:'Increase safety and security in terms of health with systems that are intelligent and touch-free enough to detect your unique features.Machine learning expertise to build facial recognition features for iOS and  Android that are powerful and suitable to use.'
}

export const webconferencing = {
  title : "Web Conferencing",
  description:'Unlock the power of the ARKit to explore and create breathtaking and powerful virtual environments that support your web conferencing sessions.Build environments that match your needs for virtual conferencing, whereby you set a theme that suits your objectives.'
}

export const csharpCustome = {
  title: "C Sharp",
  description: 'We build innovative on-demand food apps with intuitive features, including order tracking, multiple payment options, vendor management, and delivery tracking.'
}

export const flutterCustome = {
  title: "Flutter",
  description: 'We build innovative on-demand food apps with intuitive features, including order tracking, multiple payment options, vendor management, and delivery tracking.'
}

export const golangCustome = {
  title: "Golang",
  description: 'We build innovative on-demand food apps with intuitive features, including order tracking, multiple payment options, vendor management, and delivery tracking.'
}

export const javaCustome = {
  title: "Java",
  description: 'Java helps you slash costs, fuel innovation, and boost app services for enterprise architecture, IoT, and cloud services for a fully satisfactory product.Full-stack Java development services with our knowledgeable, and skilled and experienced developers to take you forward'
}

export const javascriptCustome = {
  title: "javascript",
  description: 'Whether it’s a website or a web applications, our designers and developers have experience in designing exceptional solutions using HTML5 and other web programming languages.With new elements and attributes, HTML5 offers more creative freedom for designers to craft your app as you imagined it.'
}

export const nodeCustome = {
  title: "NodeJS",
  description: 'Harness efficiency by creating robust server-side apps in a runtime environment and witness code execution outside a web browser.'
}

export const swiftCustome = {
  title: "Swift",
  description: 'Are you looking to invest in an iOS application? Mobylogix offers exceptional iOS app development services, mobile games designing, finance apps, and more for startups, enterprises and SMBs. Swift is easy to use with a simple learning curve and allows agile development, convenient scalability, and flexibility in app design.'
}

export const coreData = {
  title: "CoreDate",
  description:"Developed by Apple, CoreData allows for the management of model layer objects in an application. It is a popular choice of database framework that provides generalized and automated solutions to common tasks.Mobylogix has its development team at the ready to help startups and enterprises design or develop exceptional, innovative solutions for iOS"
}

export const room = {
  title: "Room",
  description:"Mobylogix offers exceptional mobile and web development services, helping enterprises, startups and SMBs revolutionize their business through innovative digital solutions.For Android application development, we have developers specializing in Room Database, helping entrepreneurs boost their business."
}

export const Alamofire = {
  title: "Alamofire",
  description:"If you’re looking to launch your own brand on the iOS platform, Mobylogix has the skills you need. We have expertise in a range of appliction development frameworks, including Alamofire.Alamofire allows easy integration of APIs into applications, making the process incredibly convenient and faster for developers."
}

export const azure = {
  title: "Azure",
  description:"Build programs, run tests, deploy assets, and manage apps and services via data centers that deliver powerful cloud computing."
}

export const amazon = {
  title: "Amazon",
  description:"On-demand cloud computing with APIs deliver complete control and power to host your web assets with robust long-term support."
}

