/**
 *
 * Mobylogix
 * Author: Mobylogix
 * Email: info@mobylogix.com
 * Website: mobylogix.com
 * Version: 1.0
 * Date: Wed Nov 10 2021
 * Category: Pages
 * Title: ProcessSection
 *
 */

import React from 'react';
import { Container, Row, Col } from 'reactstrap';

// styles
import './process-section.css';

const ProcessSection = (props) => (
  <div className="process-section">
    <Container>
      <div className="main-heading alter-main-heading">
        <Row>
          <Col xs='12' lg='5'>
            <h2 className="main-title">Why choose us?</h2>
          </Col>
          <Col xs='12' lg='7'>
            <p className="description">Apart from designing and developing your web presence, we suggest business-oriented solutions that fit your business needs and speak volumes of your values as a brand. With highly experienced developers and digital marketers, you get a better solution with cutting-edge appeal that engages more people online. Powerful tools with the latest technologies and strong architecture to build a web solution that cover all your needs.</p>
          </Col>
        </Row>
      </div>
      <Row className="process-box-wrap">
        <Col xs="12" md="6" lg="4">
          <div className="process-box">
            <h3 className="title">01</h3>
            <h4 className="sub-title">Plan</h4>
            <hr className="hr-line" />
            <ul className="process-list">
              <li>Business analysis</li>
              <li>Documenting specifications</li>
              <li>Preparing wireframess</li>
              <li>Getting client approval</li>
            </ul>
          </div>
        </Col>
        <Col xs="12" md="6" lg="4">
          <div className="process-box">
            <h3 className="title">02</h3>
            <h4 className="sub-title">Design</h4>
            <hr className="hr-line" />
            <ul className="process-list">
              <li>Crafting app prototype</li>
              <li>Making changes</li>
              <li>Getting client approval</li>
              <li>Implementing feedback</li>
            </ul>
          </div>
        </Col>
        <Col xs="12" md="6" lg="4">
          <div className="process-box process-box-after-none">
            <h3 className="title">03</h3>
            <h4 className="sub-title">Development</h4>
            <hr className="hr-line" />
            <ul className="process-list">
              <li>Development strategy</li>
              <li>Testing strategy</li>
              <li>Product development</li>
              <li>Product release</li>
            </ul>
          </div>
        </Col>
      </Row>
    </Container>
  </div>
);

ProcessSection.propTypes = {};

export default ProcessSection;
