/**
 *
 * Mobylogix
 * Author: Mobylogix
 * Email: info@mobylogix.com
 * Website: mobylogix.com
 * Version: 1.0
 * Date: Tue Nov 09 2021
 * Category: Pages
 * Title: InnerBanerForm
 *
 */

import React from 'react';
import { Card, CardBody, CardTitle, Form, FormGroup, Input, Label, Button } from 'reactstrap';

// Api
import { submitContact } from 'api/contact';

// styles
import './inner-baner-form.css';

const InnerBanerForm = (props) => {

  const handleContactSubmit = (e) => {
    e.preventDefault();

    const data = {
      name: e.target.name.value,
      email: e.target.email.value,
      phone: e.target.phone.value,
      message: e.target.message.value,
      href: window.location.href
    };

    submitContact(data);
  }
  
  return (
    <Card className="inner-baner-form wow fadeInUp">
      <CardBody>
        <CardTitle tag="h2">Request for proposal</CardTitle>
        <Form inline onSubmit={handleContactSubmit}>
          <FormGroup>
            <Label for="name">
              Name
            </Label>
            <Input
              id="name"
              name="name"
              type="text"
            />
          </FormGroup>
          <FormGroup>
            <Label for="email">
              Email
            </Label>
            <Input
              id="email"
              name="email"
              type="email"
            />
          </FormGroup>
          <FormGroup>
            <Label for="phone">
              Phone
            </Label>
            <Input
              id="phone"
              name="phone"
              type="number"
            />
          </FormGroup>
          <FormGroup>
            <Label for="message">
              Message
            </Label>
            <Input
              id="message"
              name="message"
              type="textarea"
              row="3"
            />
          </FormGroup>
          <Button color="primary" size="lg">Submit</Button>
        </Form>
      </CardBody>
    </Card>
  )
};

InnerBanerForm.propTypes = {};

export default InnerBanerForm;
