/**
 *
 * Mobylogix
 * Author: Mobylogix
 * Email: info@mobylogix.com
 * Website: mobylogix.com
 * Version: 1.0
 * Date: Tue Nov 09 2021
 * Category: Pages
 * Title: MobileAppsDevelopment
 *
 */

import React, { useState, useEffect } from 'react';
import Slider from "react-slick";
import { useCountUp } from 'react-countup';
import { Container, Row, Col, TabContent, TabPane, Nav, NavItem, NavLink,  } from 'reactstrap';
import classnames from 'classnames';

// util
import { swift, roomDatabase, sqlite, javascript, firebase, ffmpeg, objectivec, realm, redux, kotlin, csharp, flutterCustome, golangCustome, javaCustome, nodeCustome, swiftCustome } from "../../utils/technolgy";
import { CMSDevelopmentMeta } from 'utils/metas';

// Components
import InnerBanner from 'components/InnerBanner';
import DevelopmentServices from 'components/DevelopmentServices';
import CreativeSection from 'components/CreativeSection';
import ProcessSection from 'components/ProcessSection';
import TechnologyStackSection from 'components/TechnologyStackSection';
import InsightsSection from 'components/InsightsSection';
import HighlightsSection from 'components/HighlightsSection';
import TestimonialsSection from 'components/TestimonialsSection';
import AwardsSection from 'components/AwardsSection';
import ProjectForm from 'components/ProjectForm';
import FeaturedWork from 'components/FeaturedWork';

const MobileAppsDevelopment = (props) => {

  const [activeTab, setActiveTab] = useState('1');

  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }
  // const totalHeight = document.getElementsByClassName('mobile-app-development')[0].offsetHeight;

  // useEffect(() => {
  //   let height = document.getElementsByClassName('mobile-app-development')[0].offsetHeight;

  //   console.log('height', height);

  //   const calcHeight = (height / 100) * 75;

  //   console.log('height1', height);

  //   if (calcHeight > window.scrollY) {

  //   }
  // });

  const settings = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  return (
    <div className="inner-page mobile-app-development">
      {/* Meta */}
      <CMSDevelopmentMeta />
      {/* Meta End */}
      {/* Inner Banner */}
      <InnerBanner title={"CUSTOM SOFTWARE DEVELOPMENT"} description={"Aa a leading Software Company, We Intend To Provide You End-to-End Custom Software Solutions That increase Business Efficiency."} />
      {/* Inner Banner End */}
      {/* Development Services */}
      <DevelopmentServices title={"Business Growth With Custom Software Development Services"} description={"Are you looking for fresh, technical and innovative custom software solutions with rapid execution? Mobylogix is the leading custom software development company that provides high-tech and robust software services to businesses. Our company provides exceptional Software Design & Development Services that help you to improve performance, drive revenue and transform your business."} />
      {/* Development Services End */}
      {/* Creative Section */}
      <CreativeSection 
        title={"Customer software development"}
        description={"We provide you with end to end custom software solutions that enhances business efficiency."}
      />
      {/* Creative Section End */}
      {/* Featured Work Slider */}
      <FeaturedWork />
      {/* Featured Work Slider End */}
      {/* Caption Section */}
      {/* <section className="deve-caption-wrap deve-caption-primary">
        <Container>
          <Row className="row-justify-center">
            <Col xs="12" md="9" lg="7">
              <div className="main-heading">
                <h2 className="main-title">Work with a globally-recognized mobile app development company</h2>
                <p className="description">We are the leading mobile app development company in the USA providing apps to power the visibility and mobility of your business. As an expert in mobile application development services in the USA, we have been creating feature-packed, high-performance mobile applications for all types of businesses and industries.</p>
                <p className="description">We are recognized as one of the world’s most desired mobile app development companies. Our mobile app developers, strategists, and designers are fueled with creativity and passion for app design. This is why our mobile app development services are recognized among the best in the industry.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section> */}
      {/* Caption Section End */}
      {/* Caption Section */}
      <section className="deve-caption-wrap">
        <Container>
          <Row className="row-justify-center">
            <Col xs="12" md="9" lg="7">
              <div className="main-heading">
                <h2 className="main-title">Work with a globally-recognized mobile app development company</h2>
                <p className="description">Backed by an experienced and highly skilled team, we are committed to bringing a shift in the digital world by providing software that will transform the way you do business. We build, design and maintain software that perfectly meets our clients’ requirements. We offer custom software development services in a variety of tech stacks for all the business verticals. We are a renowned software design & development services company that has successfully completed many challenging projects in the past. Our team has the capability to untangle complex issues and deliver cutting edge engineering solutions to startups, small-medium businesses, and enterprises. Our company has been a visionary and reliable software engineering partner for the top brands. With well defined and accurate ROI-driven strategy, clear project deliverables, and transparent timelines, we bring business winning results for your business.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Caption Section End */}
      {/* Caption Section */}
      <section className="deve-caption-wrap deve-caption-primary">
        <Container>
          <Row className="row-justify-center">
            <Col xs="12" md="9" lg="7">
              <div className="main-heading">
                <h2 className="main-title">Why Choose Our Custom Software Development Services?</h2>
                <p className="description">Mobylogix exhibits expertise in deploying software solutions to minimize business interruptions and maximize revenue generation. We provide quick and cost-effective custom software solutions that will help you to exert better control over your system.</p>
                <p className="description">We are reliable, responsive, and always ready. For accelerated growth, and rapid results, Mobylogixis the company that you should consider to get the best Software development services.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Caption Section End */}
      {/* Process Section */}
      <ProcessSection />
      {/* Process Section End */}
      {/* Caption Section */}
      <section className="deve-caption-wrap deve-caption-primary">
        <Container>
          <Row className="row-justify-center">
            <Col xs="12" md="9" lg="7">
              <div className="main-heading">
                <h2 className="main-title">Our Custom Software development Process</h2>
                <p className="description">At Mobylogix, we help you to achieve a strategic advantage in the market by providing ROI-driven Software Design & Development Services. Not only we develop software but we also help you attract the maximum users through our digital marketing services</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Caption Section End */}
      {/* Technology Section */}
      <div className="technology-stack-section">
        <Container>
          <div className="main-heading main-heading-left">
            <h2 className="main-title">Custom  software development – technology stack</h2>
            <p className="description">We create custom software with scalable and tested technology stack to deliver software par excellence.</p>
          </div>
          <Row className="row-holder">
            <Col xs="12" lg="5">
              <h3 className="title">Language</h3>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '1' })}
                    onClick={() => { toggle('1'); }}
                  >
                    <img src="/images/Custom/csharp.png" alt="" />
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '2' })}
                    onClick={() => { toggle('2'); }}
                  >
                    <img src="/images/Custom/Flutter.png" alt="" />
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '3' })}
                    onClick={() => { toggle('3'); }}
                  >
                    <img src="/images/Custom/golang.png" alt="" />
                  </NavLink>
                </NavItem>
              </Nav>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '4' })}
                    onClick={() => { toggle('4'); }}
                  >
                    <img src="/images/Custom/java.png" alt="" />
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '5' })}
                    onClick={() => { toggle('5'); }}
                  >
                    <img src="/images/Custom/javascript.png" alt="" />
                  </NavLink>
                </NavItem>
              </Nav>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '6' })}
                    onClick={() => { toggle('6'); }}
                  >
                    <img src="/images/Custom/node-js.png" alt="" />
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '7' })}
                    onClick={() => { toggle('7'); }}
                  >
                    <img src="/images/Custom/swift.png" alt="" />
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
            <Col xs="12" lg="7">
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <div className="tabs-content-caption">
                    <div className="title-wrap">
                      <img src="/images/Custom/csharp.png" alt="" className="icon" />
                      <h3 className="title">{csharp.title}</h3>
                    </div>
                    <p className="description">{csharp.description}</p>
                  </div>
                </TabPane>
                <TabPane tabId="2">
                  <div className="tabs-content-caption">
                    <div className="title-wrap">
                      <img src="/images/Custom/Flutter.png" alt="" className="icon" />
                      <h3 className="title">{flutterCustome.title}</h3>
                    </div>
                    <p className="description">{flutterCustome.description}</p>
                  
                  </div>
                </TabPane>
                <TabPane tabId="3">
                  <div className="tabs-content-caption">
                    <div className="title-wrap">
                      <img src="/images/Custom/golang.png" alt="" className="icon" />
                      <h3 className="title">{golangCustome.title}</h3>
                    </div>
                    <p className="description">{golangCustome.description}</p>
                  </div>
                </TabPane>
                <TabPane tabId="4">
                  <div className="tabs-content-caption">
                    <div className="title-wrap">
                      <img src="/images/Custom/java.png" alt="" className="icon" />
                      <h3 className="title">{javaCustome.title}</h3>
                    </div>
                    <p className="description">{javaCustome.description}</p>
                  </div>
                </TabPane>
                <TabPane tabId="5">
                  <div className="tabs-content-caption">
                    <div className="title-wrap">
                      <img src="/images/Custom/javascript.png" alt="" className="icon" />
                      <h3 className="title">{javaCustome.title}</h3>
                    </div>
                    <p className="description">{javascript.description}</p>
                  </div>
                </TabPane>
                <TabPane tabId="6">
                  <div className="tabs-content-caption">
                    <div className="title-wrap">
                      <img src="/images/Custom/node-js.png" alt="" className="icon" />
                      <h3 className="title">{nodeCustome.title}</h3>
                    </div>
                    <p className="description">{nodeCustome.description}</p>
                  </div>
                </TabPane>
                <TabPane tabId="7">
                  <div className="tabs-content-caption">
                    <div className="title-wrap">
                      <img src="/images/Custom/swift.png" alt="" className="icon" />
                      <h3 className="title">{swiftCustome.title}</h3>
                    </div>
                    <p className="description">{swiftCustome.description}</p>
                  </div>
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </Container>
    </div>
      {/* Technology Section End */}
      {/* Insights Section */}
      {/* <InsightsSection title="Custom Software development – technology stack" description="We create custom software development with scalable and tested technology stack to deliver apps par excellence." /> */}
      {/* Insights Section End */}
      {/* Highlight Section */}
      <HighlightsSection />
      {/* Highlight Section End */}
      {/* Testimonials Section */}
      <TestimonialsSection />
      {/* Testimonials Section End */}
      {/* Awards Section */}
      <AwardsSection />
      {/* Awards Section End */}
      {/* Project Form */}
      <ProjectForm />
      {/* Project Form End */}
    </div>
  )
};

MobileAppsDevelopment.propTypes = {
};

export default MobileAppsDevelopment;

