/**
 *
 * Mobylogix
 * Author: Mobylogix
 * Email: info@mobylogix.com
 * Website: mobylogix.com
 * Version: 1.0
 * Date: Mon Sep 27 2021
 * Category: Pages
 * Title: Home
 *
 */

import React from 'react';

// Components
import MainBanner from 'components/MainBanner';
import WhyUsSection from 'components/WhyUsSection';
import ProductSection from 'components/ProductSection';
import TestimonialsSection from 'components/TestimonialsSection';
import TechnologiesSection from 'components/TechnologiesSection';
import AwardsSection from 'components/AwardsSection';
import PartnerSection from 'components/PartnerSection';
// import LinkedcampSection from 'components/LinkedcampSection';

// utils
import { HomeMeta } from 'utils/metas';

const Home = (props) => (
  <div className="home-page">
    {/* Meta */}
    <HomeMeta />
    {/* Meta End */}
    {/* Main Banner */}
    <MainBanner />
    {/* Main Banner End */}
    {/* Why Us */}
    <WhyUsSection />
    {/* Why Us End */}
    {/* Technologies Section */}
    <TechnologiesSection />
    {/* Technologies Section End */}
    {/* Linkedcamp Section */}
    {/* <LinkedcampSection /> */}
    {/* Linkedcamp Section End */}
    {/* Product Section */}
    <ProductSection />
    {/* Product Section End */}
    {/* Testimonials Section */}
    <TestimonialsSection />
    {/* Testimonials Section End */}
    {/* Awards Section */}
    <AwardsSection />
    {/* Awards Section End */}
    {/* Partner Section */}
    <PartnerSection />
    {/* Partner Section End */}
  </div>
);

export default Home;
