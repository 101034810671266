/**
 *
 * Mobylogix
 * Author: Mobylogix
 * Email: info@mobylogix.com
 * Website: mobylogix.com
 * Version: 1.0
 * Date: Mon Nov 08 2021
 * Category: Pages
 * Title: PartnerSection
 *
 */

import React from 'react';
import { Container, Row, Col } from 'reactstrap';

// styles
import './partner-section.css';

const PartnerSection = (props) => (
  <div className="partner-section">
    <Container>
      <Row className="row-justify-center">
        <Col lg="7">
          <div className="main-heading">
            <h2 className="main-title">Decade of building digital products and we are just getting started!</h2>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs="12" md="6" lg="3">
          <div className="partners-box">
            <figure className="img-wrap">
              <img src="https://www.cubix.co/storage/app/media/home/Google-Developer-Certified-Agency.png" alt="" />
            </figure>
            <h3 className="title">Google developer certified agency</h3>
          </div>
        </Col>
        <Col xs="12" md="6" lg="3">
          <div className="partners-box">
            <figure className="img-wrap">
              <img src="images/Amazon-Web-Services-Partner.png" alt="" />
            </figure>
            <h3 className="title">Amazon Web Services Partner </h3>
          </div>
        </Col>
        <Col xs="12" md="6" lg="3">
          <div className="partners-box">
            <figure className="img-wrap">
              <img src="images/Microsoft-Certified-Consulting-Partner.png" alt="" />
            </figure>
            <h3 className="title">Microsoft Certified Consulting Partner</h3>
          </div>
        </Col>
        <Col xs="12" md="6" lg="3">
          <div className="partners-box">
            <figure className="img-wrap">
              <img src="images/metero.png" alt="" />
            </figure>
            <h3 className="title">Meteor Official Partner</h3>
          </div>
        </Col>
      </Row>
    </Container>
  </div>
);

PartnerSection.propTypes = {};

export default PartnerSection;
