/**
 *
 * Mobylogix
 * Author: Mobylogix
 * Email: info@mobylogix.com
 * Website: mobylogix.com
 * Version: 1.0
 * Date: Wed Nov 17 2021
 * Category: Pages
 * Title: Contact
 *
 */

import React, { useEffect } from 'react';
import { Container, Row, Col, Form, FormGroup, Input, Label, Button } from 'reactstrap';
import WOW from 'wowjs';
import Slider from "react-slick";

// Api
import { submitContact } from 'api/contact';

const Contact = (props) => {

  useEffect(() => {
    new WOW.WOW({
      live: false
    }).init();
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  const handleContactSubmit = (e) => {
    e.preventDefault();

    const data = {
      name: e.target.name.value,
      email: e.target.email.value,
      phone: e.target.phone.value,
      message: e.target.message.value,
      href: window.location.href
    };

    submitContact(data);
  }

  return (
    <div className="contact">
      <Container fluid>
        <Row>
          <Col xs="12" lg="6">
            <div className="contact-left">
              <a href="/" rel="nooppener noreferrer" className="logo">
                <img src="images/mobylogix-white.png" alt="Mobylogix" width="180px" height="70px" />
              </a>
              <Slider {...settings} className="product-slider">
                  <div className="contact-left-content">
                    <h3 className="title wow fadeInDown">United States</h3>
                    <p className="wow fadeInDown">13816 Bora Bora Way Marina Del Rey <span className="d-block">Los Angeles, CA</span></p>
                    <a href="https://www.google.com/maps/place/Mobylogix,+Inc./@33.9709997,-118.4527544,17z/data=!3m1!4b1!4m5!3m4!1s0x80c2b16de30d4acb:0xd05cdd16ca45e065!8m2!3d33.9709997!4d-118.4505604" target={'_blank'} rel="nooppener noreferrer" className="link-underline wow fadeInDown">View Location</a>
                    <figure className="img-wrap wow fadeInRight">
                      <img src="images/usa.png" alt="" />
                    </figure>
                  </div>
                  {/* <div className="contact-left-content">
                    <h3 className="title wow fadeInDown">Pakistan</h3>
                    <p className="wow fadeInDown">251, Block G1 Block G 1 Phase 1 Johar Town <span className="d-block">Lahore</span></p>
                    <a href="https://www.google.com/maps/place/Mobylogix/@31.4772214,74.2784302,17z/data=!3m1!4b1!4m5!3m4!1s0x391903c4b8091bd7:0xadfacb97cca2d128!8m2!3d31.4772214!4d74.2806242" target={'_blank'} rel="nooppener noreferrer" className="link-underline wow fadeInDown">View Location</a>
                    <figure className="img-wrap wow fadeInRight">
                      <img src="images/pakistan.png" alt="" />
                    </figure>
                  </div> */}
                </Slider>
            </div>
          </Col>
          <Col xs="12" lg="6">
            <div className="contact-right">
              <ul className="contact-list wow fadeInUp">
                <li>
                  <a href="mailto:sales@mobylogix.com" rel="nooppener noreferrer">
                    <svg viewBox="64 64 896 896" focusable="false" data-icon="mail" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M928 160H96c-17.7 0-32 14.3-32 32v640c0 17.7 14.3 32 32 32h832c17.7 0 32-14.3 32-32V192c0-17.7-14.3-32-32-32zm-80.8 108.9L531.7 514.4c-7.8 6.1-18.7 6.1-26.5 0L189.6 268.9A7.2 7.2 0 01194 256h648.8a7.2 7.2 0 014.4 12.9z"></path></svg>
                    <span className="link-text">sales@mobylogix.com</span>
                  </a>
                </li>
                <li>
                  <a href="tel:+13235225593" rel="nooppener noreferrer">
                    <svg version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 53.942 53.942" className="link-icon">
                      <path d="M53.364,40.908c-2.008-3.796-8.981-7.912-9.288-8.092c-0.896-0.51-1.831-0.78-2.706-0.78c-1.301,0-2.366,0.596-3.011,1.68  c-1.02,1.22-2.285,2.646-2.592,2.867c-2.376,1.612-4.236,1.429-6.294-0.629L17.987,24.467c-2.045-2.045-2.233-3.928-0.632-6.291  c0.224-0.309,1.65-1.575,2.87-2.596c0.778-0.463,1.312-1.151,1.546-1.995c0.311-1.123,0.082-2.444-0.652-3.731  c-0.173-0.296-4.291-7.27-8.085-9.277c-0.708-0.375-1.506-0.573-2.306-0.573c-1.318,0-2.558,0.514-3.49,1.445L4.7,3.986  c-4.014,4.013-5.467,8.562-4.321,13.52c0.956,4.132,3.742,8.529,8.282,13.068l14.705,14.705c5.746,5.746,11.224,8.66,16.282,8.66  c0,0,0,0,0.001,0c3.72,0,7.188-1.581,10.305-4.698l2.537-2.537C54.033,45.163,54.383,42.833,53.364,40.908z"/>
                      <g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g>
                    </svg>
                    <span className="link-text">323 (522) 5593</span>
                  </a>
                </li>
              </ul>
              <div className="main-heading">
                <h2 className="main-title wow fadeInLeft">Let's start a project together</h2>
                <p className="description wow fadeInRight">We make all your dreams come true in a successful project.</p>
              </div>
              <Form inline onSubmit={handleContactSubmit}>
                <FormGroup>
                  <Label for="name">
                    Name
                  </Label>
                  <Input
                    id="name"
                    name="name"
                    type="text"
                    bsSize="lg"
                  />
                </FormGroup>
                <Row>
                  <Col xs="12" md="6">
                    <FormGroup>
                      <Label for="email">
                        Email
                      </Label>
                      <Input
                        id="email"
                        name="email"
                        type="email"
                        bsSize="lg"
                      />
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="6">
                    <FormGroup>
                      <Label for="phone">
                        Phone
                      </Label>
                      <Input
                        id="phone"
                        name="phone"
                        type="number"
                        bsSize="lg"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup>
                  <Label for="message">
                    Message
                  </Label>
                  <Input
                    id="message"
                    name="message"
                    type="textarea"
                    row="3"
                  />
                </FormGroup>
                <Button color="primary" size="lg">Submit</Button>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
};

export default Contact;

