export const submitContact = async (data) => {

  let results = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/contact`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  });

  results = await results.json();
};
