import React from "react";

const WebDevelopmentIcon = ({ extraClass= '' }) => (
  <svg className={extraClass} xmlns="http://www.w3.org/2000/svg" width="120px" height="120px" version="1.1" style={{ shapeRendering: 'geometricPrecision', textRendering: 'geometricPrecision', imageRendering: 'optimizeQuality', fillRule: 'evenodd', clipRule: 'evenodd' }}
    viewBox="0 0 22.66 22.41">
      <g id="Layer_x0020_1">
      <metadata id="CorelCorpID_0Corel-Layer"/>
      <path className="fil0" d="M-0 17.72c0,0.54 0.34,0.94 0.9,0.94l11.24 0 0 -0.9 -7.47 0 0 -14.03 16.87 0 0 9.31 0.94 0 0 -7.96c-0,-0.42 -0.05,-0.69 -0.05,-1.13l0 -2.92c0,-0.26 0.03,-0.33 -0.07,-0.52 -0.11,-0.21 -0.42,-0.52 -0.7,-0.52l-20.73 0c-0.31,0 -0.48,0.09 -0.65,0.24 -0.15,0.14 -0.29,0.34 -0.29,0.61l0 16.87zm14.12 -16.62c0.49,0 0.89,0.4 0.89,0.89 0,0.49 -0.4,0.89 -0.89,0.89 -0.49,0 -0.89,-0.4 -0.89,-0.89 0,-0.49 0.4,-0.89 0.89,-0.89zm2.8 0c0.49,0 0.89,0.4 0.89,0.89 0,0.49 -0.4,0.89 -0.89,0.89 -0.49,0 -0.89,-0.4 -0.89,-0.89 0,-0.49 0.4,-0.89 0.89,-0.89zm2.8 0c0.49,0 0.89,0.4 0.89,0.89 0,0.49 -0.4,0.89 -0.89,0.89 -0.49,0 -0.89,-0.4 -0.89,-0.89 0,-0.49 0.4,-0.89 0.89,-0.89zm-17.83 14.78l0.9 0 0 -0.9 -0.9 0 0 0.9zm0 -5.62l0.9 0 0 -0.9 -0.9 0 0 0.9zm0 3.78l0.9 0 0 -0.94 -0.9 0 0 0.94zm0 -1.89l0.9 0 0 -0.94 -0.9 0 0 0.94zm0 -3.73l0.9 0 0 -0.94 -0.9 0 0 0.94zm0 -1.89l0.9 0 0 -0.94 -0.9 0 0 0.94z"/>
      <g id="_2070505908224">
        <path d="M15.65 17.96c0,-0.48 -0,-0.87 0.23,-1.3 0.66,-1.27 2.53,-1.76 3.68,-0.62 0.19,0.19 0.37,0.37 0.49,0.63 1.02,2.21 -1.25,3.99 -2.95,3.22 -0.48,-0.22 -0.94,-0.59 -1.19,-1.06 -0.11,-0.22 -0.25,-0.56 -0.25,-0.87zm3.24 4.45l-1.89 0c0,-1.15 0.09,-1.05 -0.2,-1.15 -0.25,-0.09 -0.57,-0.24 -0.78,-0.28l-0.72 0.7 -1.3 -1.33 0.71 -0.73c-0.03,-0.15 -0.13,-0.32 -0.19,-0.48 -0.06,-0.16 -0.1,-0.32 -0.17,-0.46 -0.42,0 -0.7,-0.04 -1.08,-0.04l0 -1.84c0.36,0 0.66,-0.04 1.08,-0.04l0.36 -0.85c0,-0.19 -0.57,-0.65 -0.69,-0.82l1.27 -1.29c0.17,0.11 0.66,0.72 0.81,0.72 0.17,0 0.57,-0.33 0.9,-0.36l0 -1.08 1.89 0 0 0.94c0,0.15 0.89,0.47 0.98,0.48l0.73 -0.71c0.23,0.16 1.22,1.18 1.3,1.3 -0.18,0.27 -0.49,0.48 -0.67,0.76 0.04,0.16 0.13,0.35 0.2,0.52 0.21,0.52 -0.07,0.42 1.24,0.42l0 1.84c-0.36,0 -0.66,0.04 -1.08,0.04 -0.14,0.29 -0.2,0.57 -0.42,0.95l0.74 0.77 -1.31 1.28 -0.73 -0.75c-1.19,0.63 -0.99,0.05 -0.99,1.48z"/>
        <path d="M9.53 9.46l0 0.76 7.51 0c0,-0.57 0.08,-0.9 -0.13,-0.9l-7.24 0c-0.1,0 -0.13,0.03 -0.13,0.13z"/>
        <polygon points="5.8,12.11 11.42,12.11 11.42,11.17 5.8,11.17 "/>
        <polygon points="12.36,12.11 17.04,12.11 17.04,11.17 12.36,11.17 "/>
        <path d="M12.5 6.4c0.18,0.01 0.23,0.06 0.38,0.12l1.2 0.51c-0.16,0.01 -0.59,0.19 -0.78,0.25l-0.79 0.29 0 0.76c0.31,-0.03 2.22,-0.96 2.52,-0.99l0 -0.72 -1.24 -0.51c-0.29,-0.1 -1.05,-0.47 -1.28,-0.48l0 0.76z"/>
        <path d="M5.98 6.72c0,0.84 -0.17,0.48 1.2,1.09 0.22,0.1 0.42,0.16 0.63,0.27 0.19,0.1 0.43,0.2 0.64,0.26 0.01,-0.62 0.23,-0.74 -0.27,-0.89 -0.29,-0.09 -1.13,-0.33 -1.25,-0.5l1.23 -0.43c0.53,-0.17 0.31,-0.27 0.3,-0.87 -0.21,0.02 -1.04,0.4 -1.27,0.49l-0.95 0.39c-0.14,0.06 -0.25,0.05 -0.25,0.2z"/>
        <polygon points="5.8,10.23 8.59,10.23 8.59,9.33 5.8,9.33 "/>
        <path d="M9.62 7.3c0,0.1 0.03,0.13 0.13,0.13l1.66 0 0 -0.9 -1.8 0 0 0.76z"/>
      </g>
      </g>
  </svg>
)

export default WebDevelopmentIcon;
