import React from 'react';
import { Switch, Route } from 'react-router-dom';

const LayoutRoutes = ({ routes, getCurrentUser, ...layoutProps }) => (
  <Switch>
    {
      routes.map((route, i) => (
        <Route
          key={i}
          path={route.path}
          render={props => <route.component getCurrentUser={getCurrentUser} {...layoutProps} {...props} />}
          exact={route.exact}
        />
      ))
    }
  </Switch>
);

export default LayoutRoutes;
