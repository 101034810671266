/**
 *
 * Mobylogix
 * Author: Mobylogix
 * Email: info@mobylogix.com
 * Website: mobylogix.com
 * Version: 1.0
 * Date: Wed Nov 10 2021
 * Category: Pages
 * Title: InsightsSection
 *
 */

import React from 'react';
import { Container, Row, Col, Card, CardBody, Button } from 'reactstrap';

// styles
import './insights-section.css';

const InsightsSection = (props) => (
  <div className="insights-section">
    <Container>
      <div className="main-heading main-heading-left">
        <h2 className="main-title">{props.title}</h2>
        <p className="description">{props.description}</p>
      </div>
      <Row>
        <Col xs="12" md="4">
          <Card className="insights-card">
            <CardBody>
              <figure>
                <img src="https://www.cubix.co/themes/responsiv-clean/assets/images/seo-pages/mobile-seo/seo-blog-22.jpg" alt="" />
              </figure>
              <div className="insights-card-caption">
                <h3 className="title">Mobile game development</h3>
                <div className="action-wrap">
                  <a href="#" rel="nooppener noreferrer" className="link-underline">View Case Study</a>
                  <span className="text">5 Min Read</span>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col xs="12" md="4">
          <Card className="insights-card">
            <CardBody>
              <figure>
                <img src="https://www.cubix.co/themes/responsiv-clean/assets/images/seo-pages/mobile-seo/seo-blog-11.jpg" alt="" />
              </figure>
              <div className="insights-card-caption">
                <h3 className="title">Mobile game development</h3>
                <div className="action-wrap">
                  <a href="#" rel="nooppener noreferrer" className="link-underline">View Case Study</a>
                  <span className="text">5 Min Read</span>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col xs="12" md="4">
          <Card className="insights-card-primary">
            <CardBody>
              <figure>
                <img src="/images/content-strategy .png" alt="" />
              </figure>
              <h3 className="title">Get our best content straight to your inbox.</h3>
              <Button onClick={() => window.location.href = "/contact"} size="lg">Contact Us</Button>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  </div>
);

InsightsSection.propTypes = {};

export default InsightsSection;
