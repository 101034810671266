/**
 *
 * Mobylogix
 * Author: Mobylogix
 * Email: info@mobylogix.com
 * Website: mobylogix.com
 * Version: 1.0
 * Date: Thu Oct 07 2021
 * Category: Pages
 * Title: NotFound
 *
 */

import React from 'react';

const NotFound = (props) => (
  <div className="not-found">Not Found</div>
);

export default NotFound;
