/**
 *
 * Mobylogix
 * Author: Mobylogix
 * Email: info@mobylogix.com
 * Website: mobylogix.com
 * Version: 1.0
 * Date: Fri Nov 05 2021
 * Category: Pages
 * Title: AwardsSection
 *
 */

import React from 'react';
import { Container, Row, Col } from 'reactstrap';

// Icons
import AwardsAnalyticsIcon from 'icons/AwardsAnalyticsIcon';
import AwardsBlockchainIcon from 'icons/AwardsBlockchainIcon';
import AwardsEdtech from 'icons/AwardsEdtech';
import AwardsFintech from 'icons/AwardsFintech';
import AwardsHealthcare from 'icons/AwardsHealthcare';
import AwardsLogistics from 'icons/AwardsLogistics';

// styles
import './awards-section.css';
import AwardsRealEstate from 'icons/AwardsRealEstate';
import AwardsSupplyChain from 'icons/AwardsSupplyChain';

const AwardsSection = (props) => {

  return (
    <div className='awards-section'>
      <Container>
        {/* Main Heading */}
        <Row className="row-justify-center">
          <Col xs='12' sm='10' md='9' lg='9'>
          <div className="main-heading">
              <h3 className="main-title wow fadeInUp">Industries we have worked with</h3>
            </div>
          </Col>
        </Row>
        {/* Main Heading */}
        <Row>
            <Col xs='12' sm='6' md='6' lg='3'>
              <div className="why-us-card wow fadeInUp">
                <AwardsFintech extraClass='icon' />
                <h4 className="title">Fintech</h4>
              </div>
            </Col>
            <Col xs='12' sm='6' md='6' lg='3'>
              <div className="why-us-card wow fadeInUp">
                <AwardsHealthcare extraClass='icon' />
                <h4 className="title">Healthcare</h4>
              </div>
            </Col>
          <Col xs='12' sm='6' md='6' lg='3'>
            <div className="why-us-card wow fadeInUp">
              <AwardsEdtech extraClass='icon' />
              <h4 className="title">Edtech</h4>
            </div>
          </Col>
          <Col xs='12' sm='6' md='6' lg='3'>
            <div className="why-us-card wow fadeInUp">
              <AwardsBlockchainIcon extraClass='icon' />
              <h4 className="title">Blockchain</h4>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs='12' sm='6' md='6' lg='3'>
            <div className="why-us-card wow fadeInUp">
              <AwardsAnalyticsIcon extraClass='icon' />
              <h4 className="title">Analytics</h4>
            </div>
          </Col>
          <Col xs='12' sm='6' md='6' lg='3'>
            <div className="why-us-card wow fadeInUp">
              <AwardsLogistics extraClass='icon' />
              <h4 className="title">Logistics</h4>
            </div>
          </Col>
          <Col xs='12' sm='6' md='6' lg='3'>
            <div className="why-us-card wow fadeInUp">
              <AwardsRealEstate extraClass='icon' />
              <h4 className="title">Real-Estate</h4>
            </div>
          </Col>
          <Col xs='12' sm='6' md='6' lg='3'>
            <div className="why-us-card wow fadeInUp">
              <AwardsSupplyChain extraClass='icon' />
              <h4 className="title">Supply Chain</h4>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
};

AwardsSection.propTypes = {};

export default AwardsSection;
