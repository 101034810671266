/**
 *
 * Mobylogix
 * Author: Mobylogix
 * Email: info@mobylogix.com
 * Website: mobylogix.com
 * Version: 1.0
 * Date: Tue Nov 09 2021
 * Category: Pages
 * Title: MobileAppsDevelopment
 *
 */

import React from 'react';
import { Container, Row, Col } from 'reactstrap';

// Components
import InnerBanner from 'components/InnerBanner';
import DevelopmentServices from 'components/DevelopmentServices';
import CreativeSection from 'components/CreativeSection';
import ProcessSection from 'components/ProcessSection';
import TechnologyStackSection from 'components/TechnologyStackSection';
import InsightsSection from 'components/InsightsSection';
import HighlightsSection from 'components/HighlightsSection';
import TestimonialsSection from 'components/TestimonialsSection';
import AwardsSection from 'components/AwardsSection';
import ProjectForm from 'components/ProjectForm';
import FeaturedWork from 'components/FeaturedWork';

// utils
import { MobileAppsMeta } from 'utils/metas';

const MobileAppsDevelopment = (props) => (
  <div className="inner-page mobile-app-development">
    {/* Meta */}
    <MobileAppsMeta />
    {/* Meta End */}
    {/* Inner Banner */}
    <InnerBanner title={"MOBILE APPS DEVELOPMENT SERVICES"} description={"We Craft User-Friendly & Client-Centric Mobile Applications For You"} />
    {/* Inner Banner End */}
    {/* Development Services */}
    <DevelopmentServices title={"MOBILE APPS DEVELOPMENT SERVICES"} description={"Mobile app revenue statistics are projecting huge revenue of $581.9 billion in the year 2020 and it is expected to reach $935 billion by the year 2023. Almost every industry is seeking mobile apps development services and why not? They have changed the way we do business."} />
    {/* Development Services End */}
    {/* Creative Section */}
    <CreativeSection
      title={'Mobile Apps Development Services'}
      description={'We build and deliver customer-oriented and user friendly mobile applications'}
    />
    {/* Creative Section End */}
    {/* Featured Work Slider */}
    <FeaturedWork />
    {/* Featured Work Slider End */}
    {/* Caption Section */}
    <section className="deve-caption-wrap deve-caption-primary">
      <Container>
        <Row className="row-justify-center">
          <Col xs="12" md="9" lg="7">
            <div className="main-heading">
              <h2 className="main-title">Work with a globally-recognized mobile app development company</h2>
              <p className="description">We are the leading mobile app development company in the USA providing apps to power the visibility and mobility of your business. As an expert in mobile application development services in the USA, we have been creating feature-packed, high-performance mobile applications for all types of businesses and industries.</p>
              <p className="description">We are recognized as one of the world’s most desired mobile app development companies. Our mobile app developers, strategists, and designers are fueled with creativity and passion for app design. This is why our mobile app development services are recognized among the best in the industry.</p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
    {/* Caption Section End */}
    {/* Caption Section */}
    <section className="deve-caption-wrap">
      <Container>
        <Row className="row-justify-center">
          <Col xs="12" md="9" lg="7">
            <div className="main-heading">
              <h2 className="main-title">Mobile app services the Mobylogix way</h2>
              <p className="description">Whether you are a startup, a small-scale business or an enterprise looking to build a mobile app, our development process remains the same. We convert your ideas into successful mobile apps that become an integral part of everyone’s lives. With our fast and smooth app development process, the apps we build are user-friendly, robust, engaging, and well-crafted.</p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
    {/* Caption Section End */}
    {/* Caption Section */}
    <section className="deve-caption-wrap deve-caption-primary">
      <Container>
        <Row className="row-justify-center">
          <Col xs="12" md="9" lg="7">
            <div className="main-heading">
              <h2 className="main-title">Apps developed by Mobylogix rake in success</h2>
              <p className="description">It doesn’t matter if you are an enterprise, a unicorn startup or a small-scale business, our development process stays the same, which has awesome written all over it. We begin by asking the number one question “How can I turn my idea into a successful app”. “An app that becomes an integral part of everyone’s life”. The answer: our app development process. We want our apps to be intuitive, appealing, robust and friendly.</p>
              <p className="description">We aren’t in a rush to escalate your project as we believe in detailed evaluation of a product, through various stages including Planning, sketching, wireframing, prototyping, developing, quality assurance, and launching an MVP. Our goal is to create mobile apps that are bound to rake in popularity, downloads and marvelous word of mouth.</p>
              <p className="description">You can find Mobylogix’s leading mobile app development team in USA, Lithuania.</p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
    {/* Caption Section End */}
    {/* Process Section */}
    <ProcessSection />
    {/* Process Section End */}
    {/* Caption Section */}
    <section className="deve-caption-wrap deve-caption-primary">
      <Container>
        <Row className="row-justify-center">
          <Col xs="12" md="9" lg="7">
            <div className="main-heading">
              <h2 className="main-title">World-famous mobile application development company</h2>
              <p className="description">Mobile apps indeed hold a significant chunk of the market, currently (about 58%), making it important for your business to invest in one. If you have a wide customer base from your e-commerce website, imagine the power of pairing that with a mobile app!</p>
              <p className="description">Mobylogix is a popular mobile apps development company that helps you achieve your goals. We examine your particular need and work to develop your app solution to perfection! And it all starts with a rock-solid plan that not only sets the parameters of your project features, but also minutely defines and strategizes the length and breadth of programming required. This concrete plan is matched against a strict timeline to ensure delivery on time.</p>
              <p className="description">Every customer is important to us, as we believe in taking everyone who approaches us to the next level. So, no matter what kind of app you are desirous of building; we can build anything for you from scratch.</p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
    {/* Caption Section End */}
    {/* Technology Section */}
    <TechnologyStackSection />
    {/* Technology Section End */}

    {/* Insights Section */}
    {/* <InsightsSection /> */}
    {/* Insights Section End */}
    
    {/* Highlight Section */}
    <HighlightsSection />
    {/* Highlight Section End */}
    {/* Testimonials Section */}
    <TestimonialsSection />
    {/* Testimonials Section End */}
    {/* Awards Section */}
    <AwardsSection />
    {/* Awards Section End */}
    {/* Project Form */}
    <ProjectForm />
    {/* Project Form End */}
  </div>
);

export default MobileAppsDevelopment;
