/**
 *
 * Mobylogix
 * Author: Mobylogix
 * Email: info@mobylogix.com
 * Website: mobylogix.com
 * Version: 1.0
 * Date: Tue Nov 09 2021
 * Category: Pages
 * Title: DevelopmentServices
 *
 */

import React from 'react';
import { Container, Row, Col, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { useLocation, useHistory } from 'react-router-dom';
import Slider from "react-slick";
import _ from "lodash";

// styles
import './development-services.css';

const DevelopmentServices = ({ title, description }) => {

  const location = useLocation();
  const history = useHistory();
  let widgets = [
    {
      path: '/mobile-app-development',
      title: 'Mobile App Development',
      description: 'Build visually appealing apps optimized to quickly increase your business revenue and growth.'
    },
    {
      path: '/cloud-services',
      title: 'Cloud Services',
      description: 'The best clouding services in town that include databases, networking, software, analytics, intelligence and so much more.'
    },
    {
      path: '/blockchain-development',
      title: 'Blockchain Development',
      description: 'Our blockchain masters create secure and decentralized apps no mater which cryptocurrency you use for transactions.'
    },
    {
      path: '/webapps-development',
      title: 'Web Apps Development',
      description: 'Leverage powerful tools and platforms to build a vibrant and robust web presence.'
    },
    {
      path: '/virtual-reality',
      title: 'Virtual Reality',
      description: 'Virtual reality development offers image-centric scenarios within real settings  that help you in overcoming the challenges of the industry.'
    },
    {
      path: '/ecommerce-development',
      title: 'Ecommerce',
      description: 'We provide with the most substantial range of marketing opportunities for a large number of target audience to better your sales with the ecommerce development platform.'
    },
    {
      path: '/mvp-development',
      title: 'MVP Development',
      description: 'We help your business grow successfully. Construct and launch solutions quickly with our operational methods.'
    },
    {
      path: '/saas-web-development',
      title: 'SaaS Development',
      description: 'Transform your vision of the product into a plan on tech implementation. OR Develop and maintain your products without building a custom tech from scratch.'
    },
    {
      path: '/custom-enterprise-software-development',
      title: 'Enterprise Software Development',
      description: 'We provide you with end to end custom software solutions that enhances business efficiency.'
    },
  ];

  let index = _.findIndex(widgets, { path: location.pathname });
  let currentService = widgets.splice(index, 1);
  widgets = [...currentService, ...widgets];

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    // autoplay: true,
    // autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  return (
    <div className="development-services">
      <Container>
        <div className="main-heading alter-main-heading">
          <Row>
            <Col xs='12' lg='5'>
              <h2 className="main-title wow fadeInUp">{title}</h2>
            </Col>
            <Col xs='12' lg='7'>
              <p className="description wow fadeInUp">{description}</p>
            </Col>
          </Row>
        </div>
        <Slider {...settings} className="development-services-slider">
          {widgets.map((widget, i) => (
            <div key={i} className="development-services-slider-item">
              <div href={widget.path} className="deve-card-wrap wow fadeInUp">
                <Card className={`deve-card ${widget.path == location.pathname ? 'active' : ''}`} onClick={() => history.push(widget.path)}>
                  <CardBody>
                    <CardTitle tag="h3">{widget.title}</CardTitle>
                    <CardText>{widget.description}</CardText>
                    <svg width="25" height="18" viewBox="0 0 25 18" className="card-icon">
                      <g fillRule="evenodd">
                        <g>
                          <g>
                            <g>
                              <path d="M26.922 204.414l-7.254-7.568c-.443-.461-1.16-.461-1.603 0-.443.462-.443 1.21 0 1.672l5.32 5.55H4.008c-.626 0-1.133.529-1.133 1.182 0 .653.507 1.182 1.133 1.182h19.375l-5.32 5.55c-.442.461-.442 1.21 0 1.672.222.23.512.346.802.346.29 0 .58-.115.802-.346l7.254-7.568c.443-.462.443-1.21 0-1.672z" transform="translate(-1303 -1426) translate(1273 1206) translate(27.5 23.5)"></path>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </CardBody>
                </Card>
              </div>
            </div>
            ))}
        </Slider>
        {/* <Row>
          {widgets.map((widget, i) => (
            <Col xs='12' md='6' lg='3' key={i}>
              <a href={widget.path} className="deve-card-wrap wow fadeInUp">
                <Card className={`deve-card ${widget.path == location.pathname ? 'active' : ''}`}>
                  <CardBody>
                    <CardTitle tag="h3">{widget.title}</CardTitle>
                    <CardText>{widget.description}</CardText>
                    <svg width="25" height="18" viewBox="0 0 25 18" className="card-icon">
                      <g fillRule="evenodd">
                        <g>
                          <g>
                            <g>
                              <path d="M26.922 204.414l-7.254-7.568c-.443-.461-1.16-.461-1.603 0-.443.462-.443 1.21 0 1.672l5.32 5.55H4.008c-.626 0-1.133.529-1.133 1.182 0 .653.507 1.182 1.133 1.182h19.375l-5.32 5.55c-.442.461-.442 1.21 0 1.672.222.23.512.346.802.346.29 0 .58-.115.802-.346l7.254-7.568c.443-.462.443-1.21 0-1.672z" transform="translate(-1303 -1426) translate(1273 1206) translate(27.5 23.5)"></path>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </CardBody>
                </Card>
              </a>
            </Col>
          ))}
        </Row> */}
      </Container>
    </div>
  )
};

DevelopmentServices.propTypes = {};

export default DevelopmentServices;
