/**
 *
 * Mobylogix
 * Author: Mobylogix
 * Email: info@mobylogix.com
 * Website: mobylogix.com
 * Version: 1.0
 * Date: Tue Nov 09 2021
 * Category: Pages
 * Title: CreativeSection
 *
 */

import React, { useState } from 'react';
import { Container, TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import classnames from 'classnames';

// styles
import './creative-section.css';

const CreativeSection = ({ title, description }) => {
  const [activeTab, setActiveTab] = useState('1');

  const toggle = tab => activeTab !== tab && setActiveTab(tab);

  return (
    <div className="creative-section">
      <Container fluid>
        <Row className="row-holder">
          <Col xs="12" lg="5">
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <figure>
                  <img src="/images/fintech.jpg" alt="" />
                </figure>
              </TabPane>
              <TabPane tabId="2">
                <figure>
                  <img src="/images/healthcare.jpg" alt="" />
                </figure>
              </TabPane>
              <TabPane tabId="3">
                <figure>
                  <img src="/images/edtech.jpg" alt="" />
                </figure>
              </TabPane>
              <TabPane tabId="4">
                <figure>
                  <img src="/images/realestate.jpg" alt="" />
                </figure>
              </TabPane>
            </TabContent>
          </Col>
          <Col xs="12" lg="7">
            <div className="tabs-content">
              <div className="main-heading">
                <h2 className="main-title">{title}</h2>
                <p className="description">{description}</p>
              </div>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '1' })}
                    onClick={() => toggle('1')}
                  >
                    Fintech
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '2' })}
                    onClick={() => toggle('2')}
                  >
                    Healthcare
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '3' })}
                    onClick={() => toggle('3')}
                  >
                    EdTech
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '4' })}
                    onClick={() => toggle('4')}
                  >
                  Blockchain
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <div className="tabs-content-caption">
                    <h3 className="title">Fintech</h3>
                    <p className="description">We craft creative and inventive on demand apps that compete with the traditional financial methods in financial deliveries. Features include multiple payment options, order and delivery tracking tc.</p>
                  </div>
                </TabPane>
                <TabPane tabId="2">
                  <div className="tabs-content-caption">
                    <h3 className="title">Healthcare</h3>
                    <p className="description">Our healthcare tech has healthcare features that provide customers with improved administrative and hospital productivity, the most relevant medicines, treatments and online prescriptions.</p>
                  </div>
                </TabPane>
                <TabPane tabId="3">
                  <div className="tabs-content-caption">
                    <h3 className="title">EdTech</h3>
                    <p className="description">Take e-learning to a whole new level with our distinctive educational apps that allow students to study without any pressure by targeting behaviors outside a classroom.</p>
                  </div>
                </TabPane>
                <TabPane tabId="4">
                  <div className="tabs-content-caption">
                    <h3 className="title"> Blockchain</h3>
                    <p className="description">Blockchain development platform helps you in enhancing accountability and traceability. This will allow you to keep a record of your transactions made in any sort of cryptocurrency.</p>
                  </div>
                </TabPane>
              </TabContent>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
};

CreativeSection.propTypes = {};

export default CreativeSection;
