import React from "react";

const AwardsBlockchainIcon = ({ extraClass= '' }) => (
  <svg className={extraClass} xmlns="http://www.w3.org/2000/svg" fill={"#007eff"} width="20px" height="20px" version="1.1" style={{ shapeRendering: 'geometricPrecision', textRendering: 'geometricPrecision', imageRendering: 'optimizeQuality', fillRule: 'evenodd', clipRule: 'evenodd' }}
    viewBox="0 0 150.27 147.33">
    <g id="Layer_x0020_1">
      <metadata id="CorelCorpID_0Corel-Layer"/>
      <path d="M39.91 76.01c1.33,0.7 2.51,1.36 3.82,2.05 0.51,-0.77 0.78,-1.55 1.47,-2.05 0,3.55 -0.39,3.04 -1.66,5.38 -0.83,1.54 -1.59,3.25 -3.62,3.42 0.41,-0.78 0.76,-1.28 1.17,-2.05 -1.41,-0.38 -2.66,-1.72 -4.11,-2.05 -0.47,0.89 -0.37,1.17 -1.17,1.76 -0.99,-1.76 0.88,-4.08 1.75,-5.59 1.01,-1.78 1.23,-2.68 3.54,-3.21 -0.5,0.74 -0.94,1.32 -1.17,2.35zm10.81 -10.52c-0.04,-0.13 0.32,-0.1 0.75,-0.82 0.31,-0.52 0.34,-0.7 0.48,-1.29 -1.31,-0.69 -2.49,-1.35 -3.82,-2.05 -1.2,1.79 -0.49,1.41 -1.47,1.76 0,-3.27 0.63,-3.17 1.76,-5.28 1.58,-2.95 1.6,-2.6 3.52,-3.52 -0.31,1.31 -0.58,0.94 -0.88,2.05 0.5,0.68 2.87,2.1 3.82,2.35 0.55,-1.04 0.32,-1.45 1.47,-1.76 0,2.59 -2.22,6.4 -3.14,7.42 -0.82,0.91 -1.56,0.98 -2.14,1.38l-0.34 -0.24zm3.86 -15.6c-1.22,-0.03 -1.12,-0.29 -1.76,-0.29 -3.3,0 -6.09,2.1 -7.33,4.12 -0.75,1.21 -1.34,2.48 -2.12,3.75 -2.63,4.29 -1.22,7.11 0.94,10.33 -0.32,0.6 -0.41,0.7 -0.59,1.47 -2.27,0 -2.68,-0.54 -5,0.28 -4.13,1.45 -7.62,8.71 -7.62,10.87 0,9.82 4.53,2.29 -0.1,10.76 -0.62,1.13 -1.99,3.1 -2.25,4.21 1.45,0.34 2.7,1.68 4.11,2.05 0.25,-1.08 1.54,-2.94 2.14,-4.02 0.77,-1.38 1.45,-2.7 2.26,-3.91 2.72,0 3.42,0.23 5.46,-0.7 3.53,-1.61 7.16,-8.51 7.16,-11.33 0,-3.17 -1.45,-4.53 -2.35,-5.87 0.32,-0.61 0.41,-0.7 0.59,-1.47 1.8,0 2.03,0.22 3.51,0.01 1.02,-0.14 2.25,-0.65 3.02,-1.11 2.73,-1.64 6.14,-7.83 6.3,-10.69 0.14,-2.5 -1.07,-4.32 -2.27,-6.11 0.42,-1.56 4.04,-6.66 4.4,-8.22 -1.41,-0.38 -2.66,-1.72 -4.11,-2.05l-4.4 7.92z"/>
      <path d="M105.07 77.48c0,-1.57 -0,-1.04 0.59,-1.47 0.2,0.86 0.51,1.36 0.88,2.05 1.41,-0.38 2.66,-1.72 4.11,-2.05 -0.36,-0.68 -0.98,-1.63 -1.17,-2.35 2.22,0.52 2.55,1.79 3.54,3.51 1.12,1.95 1.75,2.2 1.75,5.3 -0.79,-0.28 -0.92,-0.66 -1.17,-1.76 -1.32,0.31 -2.91,1.42 -4.11,2.05 0.26,1.1 0.54,1.33 1.17,2.05 -1.3,1.82 -5.58,-5.92 -5.58,-7.34zm-10.86 -20.84l0.61 0.85c0.29,0.48 0.38,0.53 0.56,1.2l4.11 -2.05c-0.19,-0.71 -0.81,-1.67 -1.17,-2.35 2.12,0.49 2.76,1.87 3.71,3.62 0.51,0.94 1,1.53 1.37,2.45 0.64,1.59 0.32,1.55 0.2,3.02l-1.17 -2.05c-1.45,0.34 -2.7,1.68 -4.11,2.05l1.07 2.11c-0.4,0.83 -2.3,-1.17 -2.3,-1.17l-2.68 -4.66c-0.64,-1.59 -0.32,-1.55 -0.2,-3.02zm-6.75 -12.62c0.09,1.11 3.64,7.08 4.4,8.22 -0.67,0.77 -1.07,1.17 -1.58,2.23 -2.01,4.15 0.46,8.75 2.83,12.07 4.4,6.15 8.98,2.03 9.52,4.58 0.2,0.94 -0.1,0.71 -0.64,1.49 -2.32,3.35 -1.6,6.89 0.27,10.02 3.46,5.79 3.82,6.87 11.03,6.87 0.28,1.04 3.64,6.48 4.4,7.92 1.31,-0.69 2.49,-1.35 3.82,-2.05 -0.29,-1.25 -1.44,-2.99 -2.07,-4.09 -0.78,-1.36 -1.58,-2.7 -2.33,-4.12 3.47,-5.19 3.05,-7.62 -1.05,-13.92 -0.92,-1.41 -1.49,-2.06 -2.88,-2.98 -2.82,-1.86 -5.01,-1.11 -6.34,-1 -0.35,-0.65 -0.49,-0.88 -0.88,-1.47 0.66,-0.76 1.1,-1.18 1.56,-2.25 1.88,-4.38 -0.39,-8.75 -2.94,-12.22 -1.66,-2.27 -3.16,-3.12 -5.88,-3.54 -3.74,-0.57 -1.7,2.18 -5.09,-3.69 -0.78,-1.36 -1.58,-2.7 -2.33,-4.12 -1.33,0.7 -2.51,1.36 -3.82,2.05z"/>
      <path d="M62.22 114.75c2.1,0 5.43,-0.3 6.66,0.38l0.97 1.09 -2.35 0 0 4.4 2.64 0c-1.52,2.07 -4.47,1.47 -7.92,1.47 -1.21,0 -2.15,-0.73 -2.64,-1.47l2.35 0 0 -4.4 -2.35 0c0.49,-0.74 1.43,-1.47 2.64,-1.47zm27.59 2.05l0 3.82 2.35 0c-1.38,2.06 -4.2,1.47 -7.63,1.47 -1.21,0 -2.15,-0.73 -2.64,-1.47l2.35 0 0 -4.4 -2.35 0c1.43,-2.13 3.32,-1.47 7.92,-1.47 1.11,0 1.87,0.52 2.35,1.17 -1.21,0 -2.35,-0.36 -2.35,0.88zm-35.22 -0.59l-9.39 0c0,6.48 -0.31,4.45 4.1,4.39 1.75,-0.02 3.54,0.01 5.29,0.01 1.9,8.17 14.17,6.87 17.37,4.46 2.25,-1.7 2.29,-2.62 3.17,-4.46l1.76 0c0.44,1.88 1.51,3.45 3.1,4.53 3.35,2.28 13.16,2.88 16.27,-2.19 1.81,-2.96 -0.99,-2.34 10.28,-2.34l0 -4.7 -9.39 0c-0.14,-1.68 -1.58,-3.27 -2.67,-4.08 -3.11,-2.32 -5.24,-1.79 -9.65,-1.79 -3.69,0 -7.65,2.9 -7.92,6.16l-1.76 0c-0.9,-1.7 -0.41,-1.85 -2.22,-3.65 -3.57,-3.55 -11.95,-3.11 -15.22,-0.89 -1.44,0.98 -2.67,2.68 -3.1,4.53z"/>
      <path d="M121.8 83.64c2.13,1.13 3.88,2.15 5.99,3.4l18.07 10.4 0 29.94c-1.45,0.34 -4.92,2.69 -6.46,3.52 -1.03,0.56 -2.12,1.18 -3.17,1.81 -2.1,1.27 -15.25,9.05 -16.49,9.34 -2.46,-1.65 -24.23,-14.15 -25.24,-14.38 -0.6,1.13 -1.94,2.79 -2.05,4.11 0.88,0.21 1.11,0.38 1.82,0.82l25.77 14.73 15.07 -8.7c1.36,-0.74 2.5,-1.43 3.73,-2.14l7.45 -4.29c0.98,-0.59 3.02,-1.93 3.98,-2.18l0 -35.51 -19.66 -11.15c-1.04,-0.56 -2.11,-1.18 -3.17,-1.82 -0.91,-0.54 -2.49,-1.61 -3.58,-1.7 -0.7,1.33 -1.36,2.51 -2.05,3.82z"/>
      <path d="M-0 95.09l0 34.04c0,0.92 0.82,1.15 1.46,1.48 6.02,3.09 14.61,8.61 21.04,12.12l7.73 4.59 24.55 -14.19c1,-0.55 2.66,-1.7 3.62,-1.95l-2.05 -4.11 -26.12 14.97 -25.83 -14.97 0 -29.94 24.07 -13.79c-0.27,-1 -1.54,-2.83 -2.05,-3.82 -1.55,0.36 -17.81,10.12 -20.06,11.35l-5.03 2.9c-0.75,0.43 -1.33,0.45 -1.33,1.31z"/>
      <path d="M44.9 17.9l0 29.64 4.4 0 0 -27.59c0.86,-0.2 2.47,-1.21 3.29,-1.7l9.64 -5.62c2.22,-1.28 4.25,-2.46 6.53,-3.74 1.12,-0.62 2.14,-1.25 3.15,-1.84 1.05,-0.61 2.48,-1.54 3.51,-1.78 0.95,0.64 2.04,1.24 3.14,1.85 1.21,0.68 1.97,1.15 3.15,1.84 1.17,0.69 2.02,1.14 3.2,1.79 1.24,0.69 2.05,1.25 3.33,1.95l11.37 6.54c0.76,0.39 1.36,0.4 1.36,1.29l0 27 4.7 0 0 -30.23c-1.02,-0.24 -2.93,-1.52 -3.83,-2.04l-26.4 -15.28c-1.45,0.34 -14.17,7.97 -15.39,8.68 -2.66,1.57 -4.98,2.83 -7.63,4.4l-5.76 3.34c-0.74,0.41 -1.74,0.56 -1.74,1.48z"/>
      <path d="M51.95 24.36c1.57,0.42 19.49,11.42 20.84,11.74l0 24.65 4.7 0 0 -24.65c1.5,-0.35 8.97,-5.01 10.57,-5.87l10.57 -6.16c-0.52,-0.98 -1.79,-2.81 -2.05,-3.82 -1.18,0.27 -1.74,0.78 -2.73,1.37 -0.96,0.58 -1.67,0.94 -2.61,1.5 -3.74,2.25 -12.73,7.11 -15.79,9.15 -1.41,-0.33 -18.58,-10.53 -21.42,-12.03 -0.56,0.83 -1.96,2.97 -2.05,4.11z"/>
      <path d="M96.56 101.55c3.7,1.96 6.96,4.04 10.67,6.06l8.02 4.6c1.45,0.79 2.45,1.1 2.45,1.95l0 23.77 4.7 0c0,-8.25 -0.29,-15.9 -0.29,-24.36 0.92,-0.25 1.89,-1.01 2.66,-1.45l18.48 -10.58c-0.31,-1.32 -1.42,-2.91 -2.05,-4.11 -5.94,3.15 -13.46,7.87 -19.76,11.35 -0.71,0.39 -0.13,0.02 -0.73,0.45 -0.29,0.21 -0.46,0.37 -0.64,0.53l-21.42 -12.33c-0.38,1.62 -1.68,2.48 -2.05,4.11z"/>
      <path d="M7.04 101.55c1.18,0.27 1.74,0.78 2.73,1.37l15.99 9.25c0.85,0.51 2.41,0.94 2.41,2l0 23.77 4.4 0 0 -24.65c0.93,-0.22 1.82,-0.86 2.73,-1.38l13.02 -7.52c1.82,-1.09 3.71,-2.02 5.38,-3.14 -0.69,-1.31 -1.35,-2.49 -2.05,-3.82 -1.99,0.46 -20.22,11.76 -21.23,11.89 -0.68,0.09 -1.87,-0.83 -2.59,-1.27l-18.73 -10.62c-0.56,0.83 -1.96,2.97 -2.05,4.11z"/>
      <path d="M63.1 63.1c3.03,2.03 8.87,4.93 12.03,7.04 2.06,-1.09 4.09,-2.35 6.15,-3.54 2.03,-1.17 4.07,-2.39 6.18,-3.51 -0.69,-1.31 -1.35,-2.49 -2.05,-3.82 -1.29,0.11 -4.09,2.02 -5.22,2.7 -1.65,0.99 -3.41,2.01 -5.05,2.88 -1.27,-0.85 -8.68,-5.44 -10.27,-5.58 -0.4,1.73 -1.36,2.08 -1.76,3.82z"/>
      <path d="M46.66 91.86l9.39 5.28 -0.03 8.24c-0.02,2.44 -0.39,2.03 4.72,2.03l0 -12.91 -6.04 -3.35c-2.12,-1.24 -3.86,-2.27 -5.99,-3.4 -0.39,0.73 -0.57,1.12 -1.05,1.89 -0.5,0.81 -0.93,1.26 -1.01,2.22z"/>
      <path d="M89.51 95.38l0 11.45 4.7 0 0 -9.68c1.77,-0.41 8.09,-4.86 9.69,-5.28 -0.38,-1.41 -1.72,-2.66 -2.05,-4.11 -0.99,0.27 -2.29,1.26 -3.23,1.76 -1.33,0.72 -9.1,4.73 -9.1,5.87z"/>
      <path d="M7.34 125.61l16.14 9.1c0.56,-1.06 1.95,-2.83 2.05,-4.11 -1.47,-0.78 -12.43,-7.31 -13.79,-7.63l0 -2.35 -4.4 0 0 4.99z"/>
      <path d="M71.9 10.27c0.23,0.98 0.46,1.23 0.94,2 0.46,0.75 0.73,1.37 1.12,2.11 2.06,-1.09 2.94,0.4 6.85,2.54 1.22,0.66 2.22,1.22 3.37,1.92 1.05,0.63 2.22,1.59 3.58,1.71 0.69,-1.31 1.35,-2.49 2.05,-3.82l-14.38 -8.22c-1.38,0.32 -2.38,1.16 -3.52,1.76z"/>
      <path d="M138.53 122.38c0,0.87 -0.48,0.82 -1.14,1.21l-11.19 6.42c0.22,0.95 0.52,1.16 0.97,1.97 0.63,1.13 0.34,1.15 1.38,1.85l11.05 -6.26c4,-2.19 3.33,-1.24 3.33,-6.06l-4.4 0 0 0.88z"/>
      <path d="M58.99 17.61c0.77,1.46 1.6,2.69 2.35,4.11 0.75,-0.51 2.91,-1.84 3.82,-2.05 -0.31,-1.32 -1.42,-2.91 -2.05,-4.11 -1.62,0.38 -2.48,1.68 -4.11,2.05z"/>
      <polygon points="7.34,117.98 11.74,117.98 11.74,113.29 7.34,113.29 "/>
      <polygon points="138.53,118.86 142.93,118.86 142.93,114.17 138.53,114.17 "/>
      <polygon points="7.34,110.64 11.74,110.64 11.74,105.95 7.34,105.95 "/>
      <polygon points="138.53,111.52 142.93,111.52 142.93,106.83 138.53,106.83 "/>
      <path d="M65.45 14.09c0.39,0.73 0.57,1.12 1.05,1.89 0.43,0.69 0.82,1.21 1.01,1.93l4.11 -2.05c-0.7,-1.33 -1.36,-2.51 -2.05,-3.82 -1.32,0.11 -3.17,1.42 -4.11,2.05z"/>
    </g>
  </svg>
)

export default AwardsBlockchainIcon;
