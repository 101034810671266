/**
 *
 * Mobylogix
 * Author: Mobylogix
 * Email: info@mobylogix.com
 * Website: mobylogix.com
 * Version: 1.0
 * Date: Tue Sep 28 2021
 * Category: Pages
 * Title: MainBanner
 *
 */

import React from 'react';
import Slider from "react-slick";
import { useHistory } from 'react-router-dom';
import { Container, Row, Col, Button } from 'reactstrap';

// styles
import './main-banner.css';

const MainBanner = (props) => {

  const history = useHistory();

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="main-banner">
      <Slider {...settings} className="banner-slider">
        <div className="banner-slider-item">
          <div className="banner-caption-wrapper" style={{backgroundImage: `url('images/augmented-&-virtual-reality.jpg')`}}>
            <Container>
              <Row>
                <Col xs='12' lg='8' xl='6'>
                  <div className="banner-caption">
                    <h1 className="main-title wow fadeInDown" data-wow-duration="1.5s" data-wow-delay="0.5s">
                      Innovative Virtual <span>Reality Solutions</span>
                    </h1>
                    <p className="description wow fadeInDown" data-wow-duration="1.5s" data-wow-delay="0.5s">Overcome the biggest challenges of industry with virtual reality development. Image oriented scenarios within real settings will transform your business like no one else.</p>
                    <Button onClick={() => window.location.href = '/contact'} color="primary" className="wow fadeInDown">Let's Talk</Button>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <div className="banner-slider-item">
          <div className="banner-caption-wrapper" style={{backgroundImage: `url('images/ai-and-machine-learning.jpg')`}}>
            <Container>
              <Row>
                <Col xs='12' lg='10' xl='6'>
                  <div className="banner-caption">
                    <h1 className="main-title wow fadeInDown">
                      AI Transforming <span>Augmented Reality</span>
                    </h1>
                    <p className="description wow fadeInDown">We bring artificial intelligence to life by reforming the world of augmented reality where we allow correct detection and tracking of any object virtually.</p>
                    <Button onClick={() => window.location.href = '/contact'} color="primary" className="wow fadeInDown">Let's Talk</Button>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <div className="banner-slider-item">
          <div className="banner-caption-wrapper" style={{backgroundImage: `url('images/blockchain-development.jpg')`}}>
            <Container>
              <Row>
                <Col xs='12' lg='8' xl='6'>
                  <div className="banner-caption">
                    <h1 className="main-title wow fadeInDown">
                      Transforming <span>Enterprises Digitally</span>
                    </h1>
                    <p className="description wow fadeInDown">Combine data repositories and content management into a single system that provides impeccable experience and discover the potential of enterprise</p>
                    <Button onClick={() => window.location.href = '/contact'} color="primary" className="wow fadeInDown">Let's Talk</Button>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <div className="banner-slider-item">
          <div className="banner-caption-wrapper" style={{backgroundImage: `url('images/banner.jpg')`}}>
            <Container>
              <Row>
                <Col xs='12' lg='8' xl='6'>
                  <div className="banner-caption">
                    <h1 className="main-title wow fadeInDown">
                      Modernizing<span>Mobile Platforms</span>
                    </h1>
                    <p className="description wow fadeInDown">Customized mobile apps. Affiance and user experience. Intelligence and design. Native and cross-platform</p>
                    <Button onClick={() => window.location.href = '/contact'} color="primary" className="wow fadeInDown">Let's Talk</Button>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </Slider>
      <div className="badges-wrap">
        <Container>
          <Row>
            <Col xs='12' lg='6'>
              <div className="badges-holder">
                <h4 className="title">Trusted by</h4>
                <ul className="badges-list">
                  <li><img src="images/bazingalogo.png" alt="" /></li>
                  <li><img src="images/carelogo.png" alt="" /></li>
                  <li><img src="images/hubstaff.png" alt="" /></li>
                </ul>
              </div>
            </Col>
            <Col xs='12' lg='6'>
              <div className="badges-holder awards-holder">
                <h4 className="title">Awards</h4>
                <ul className="badges-list">
                  <li><img src="https://mly5n1ylek6q.i.optimole.com/YAOBLcw-DrGx9vtz/w:150/h:123/q:90/https://mobylogix.com/wp-content/uploads/2020/10/Top-Mobile-app-developer-USA-2020-1.png" alt="" /></li>
                  <li><img src="https://mly5n1ylek6q.i.optimole.com/YAOBLcw-F8jRk5VT/w:150/h:123/q:90/https://mobylogix.com/wp-content/uploads/2020/08/badge.png" alt="" /></li>
                  <li><img src="https://mly5n1ylek6q.i.optimole.com/YAOBLcw-k0WDo-R1/w:159/h:144/q:90/https://mobylogix.com/wp-content/uploads/2020/10/goodfirms-co-1.png" alt="" /></li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
};

MainBanner.propTypes = {};

export default MainBanner;
