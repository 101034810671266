/**
 *
 * Mobylogix
 * Author: Mobylogix
 * Email: info@mobylogix.com
 * Website: mobylogix.com
 * Version: 1.0
 * Date: Tue Nov 09 2021
 * Category: Pages
 * Title: MobileAppsDevelopment
 *
 */

import React, { useEffect, useState } from 'react';
import Slider from "react-slick";
import { useCountUp } from 'react-countup';
import { Container, Row, Col, TabContent, TabPane, Nav, NavItem, NavLink,  } from 'reactstrap';
import classnames from 'classnames';

// Components
import InnerBanner from 'components/InnerBanner';
import DevelopmentServices from 'components/DevelopmentServices';
import CreativeSection from 'components/CreativeSection';
import ProcessSection from 'components/ProcessSection';
import TechnologyStackSection from 'components/TechnologyStackSection';
import InsightsSection from 'components/InsightsSection';
import HighlightsSection from 'components/HighlightsSection';
import TestimonialsSection from 'components/TestimonialsSection';
import AwardsSection from 'components/AwardsSection';
import ProjectForm from 'components/ProjectForm';

// util
import { swift, javascript, php, python, Go, kotlin, sqlite, objectivec } from "../../utils/technolgy";

const IotAppsDevelopment = (props) => {

  const [activeTab, setActiveTab] = useState('1');

  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }

  // const totalHeight = document.getElementsByClassName('mobile-app-development')[0].offsetHeight;

  // useEffect(() => {
  //   let height = document.getElementsByClassName('mobile-app-development')[0].offsetHeight;

  //   console.log('height', height);

  //   const calcHeight = (height / 100) * 75;

  //   console.log('height1', height);

  //   if (calcHeight > window.scrollY) {

  //   }
  // });

  const settings = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  return (
    <div className="inner-page mobile-app-development">
      {/* Inner Banner */}
      <InnerBanner title={"INTERNET OF THINGS APPS DEVELOPMENT SERVICES"} description={"Be IoT Ready! Build, Connect & Empower Your Business with Our IoT Services"} />
      {/* Inner Banner End */}
      {/* Development Services */}
      <DevelopmentServices title={"INTERNET OF THINGS APPS DEVELOPMENT SERVICES"} description={"IoT (Internet of Things) refers to a set of devices connected through embedded technology. It has the potential to leverage the technology you already have and solve your specific business needs by offering ways to connect, automate, and collaborate. Are you looking for an advanced and reliable IoT app development company? Mobylogix offers high-tech IoT solutions to startups, SMBs, and enterprises."}  />
      {/* Development Services End */}
      {/* Creative Section */}
      <CreativeSection />
      {/* Creative Section End */}
      {/* Product Slider */}
      <section className="product-section">
        <Container>
          <div className="main-heading">
            <h2 className="main-title">Our featured works</h2>
          </div>
          <Slider {...settings} className="product-slider">
            <div className="product-slider-item">
              <Row className="row-holder">
                <Col xs="12" lg="6">
                  <div className="product-imgs">
                    <figure className="product-img">
                      <img src="/images/deribit.png" alt="" />
                    </figure>
                    <figure className="product-img-1">
                      <img src="https://www.Mobylogix.co/themes/responsiv-clean/assets/images/home/foodly-mobile-image-3.png" alt="" />
                    </figure>
                  </div>
                </Col>
                <Col xs="12" lg="6">
                  <div className="product-caption">
                    <figure className="product-icon">
                      <img src="/images/deribit.icon.png" alt="" />
                    </figure>
                    <strong className="sub-title">Internet Of Things</strong>
                    <h3 className="title">Foodly - the hub of delicious cuisines</h3>
                    <p className="description">Order breakfast, lunch, or dinner, rate your favorite restaurants and track your order - one platform to curb your hunger.</p>
                    <a href="#" rel="nooppener noreferrer" className="link-underline">View Case Study</a>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="product-slider-item">
              <Row className="row-holder">
                <Col xs="12" lg="6">
                  <div className="product-imgs">
                    <figure className="product-img">
                      <img src="/images/craftsman.png" alt="" />
                    </figure>
                    <figure className="product-img-1">
                      <img src="" alt="" />
                    </figure>
                  </div>
                </Col>
                <Col xs="12" lg="6">
                  <div className="product-caption">
                    <figure className="product-icon">
                      <img src="/images/craftsman-icon.png" alt="" />
                    </figure>
                    <strong className="sub-title">Internet Of Things</strong>
                    <h3 className="title">Foodly - the hub of delicious cuisines</h3>
                    <p className="description">Order breakfast, lunch, or dinner, rate your favorite restaurants and track your order - one platform to curb your hunger.</p>
                    <a href="#" rel="nooppener noreferrer" className="link-underline">View Case Study</a>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="product-slider-item">
              <Row className="row-holder">
                <Col xs="12" lg="6">
                  <div className="product-imgs">
                    <figure className="product-img">
                      <img src="/images/ahcip.chs.harvard.edu.png" alt="" />
                    </figure>
                    <figure className="product-img-1">
                      <img src="" alt="" />
                    </figure>
                  </div>
                </Col>
                <Col xs="12" lg="6">
                  <div className="product-caption">
                    <figure className="product-icon">
                      <img src="/images/ahcip.chs.harvard.edu.icon.png" alt="" />
                    </figure>
                    <strong className="sub-title">Internet Of Things</strong>
                    <h3 className="title">Foodly - the hub of delicious cuisines</h3>
                    <p className="description">Order breakfast, lunch, or dinner, rate your favorite restaurants and track your order - one platform to curb your hunger.</p>
                    <a href="#" rel="nooppener noreferrer" className="link-underline">View Case Study</a>
                  </div>
                </Col>
              </Row>
            </div>
          </Slider>
        </Container>
      </section>
      {/* Product Slider End */}
      {/* Caption Section */}
      <section className="deve-caption-wrap deve-caption-primary">
        <Container>
          <Row className="row-justify-center">
            <Col xs="12" md="9" lg="7">
              <div className="main-heading">
                <h2 className="main-title">Why Should You Get IoT App Development Services For Your Company?</h2>
                <p className="description">IoT provides an interconnected network across an organization by connecting devices and platforms, thereby increasing efficiency and reducing time.
                  According to IDC surveys, the IoT market will grow in the coming years as more devices will come around and businesses will grow around them. The growth of your business highly depends on connectivity and IoT is an amazing revelation to amp up smartness in your work with the least effort.</p>
                <p className="description">Mobylogix is the leading IoT app development service provider in the USA. Our enterprise-level IoT solutions will enable you to accelerate your business. </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Caption Section End */}
      {/* Caption Section */}
      <section className="deve-caption-wrap">
        <Container>
          <Row className="row-justify-center">
            <Col xs="12" md="9" lg="7">
              <div className="main-heading">
                <h2 className="main-title">Our IoT Expertise</h2>
                <p className="description">We have a world-class IoT application development team, well-versed with IoT technologies to help you achieve operational efficiency. They are capable of providing you adaptable IoT cloud integration services to combine all your data sources and systems to develop a well-connected IoT ecosystem.
                 Our years of experience in multi-faceted technologies development have made it easier for us to customize IoT data management systems to transfer and secure your significant data and optimize the overall processes. We provide IoT app development services to clients belonging to all business verticals and create applications that store the data collected by the internet devices and also manage these devices anywhere, anytime.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Caption Section End */}
      {/* Caption Section */}
      <section className="deve-caption-wrap deve-caption-primary">
        <Container>
          <Row className="row-justify-center">
            <Col xs="12" md="9" lg="7">
              <div className="main-heading">
                <h2 className="main-title">Result-Oriented IoT Services For Different Industries</h2>
                <p className="description">Our IoT app development solutions will connect devices present in the field. They keep a record of the performance, analyze maintenance issues, and optimize resources.</p>
                <p className="description">With our IoT Services, we enable manufacturing industries to analyze workforce performance and productivity. These IoT solutions automate activities, manage data flow, and machine-to-machine communication for maximum performance.</p>
                <p className="description">IoT app development solutions for retail optimize supply chains and improve customer engagement and online experience. eCommerce businesses can regulate their businesses with the help of our data-driven IoT services.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Caption Section End */}
      {/* Process Section */}
      <ProcessSection />
      {/* Process Section End */}
      {/* Caption Section */}
      <section className="deve-caption-wrap deve-caption-primary">
        <Container>
          <Row className="row-justify-center">
            <Col xs="12" md="9" lg="7">
              <div className="main-heading">
                <h2 className="main-title">Why Choose Our IoT Services?</h2>
                <p className="description">Mobylogix is the leading  IoT app development services company in the USA. We strive to be the modern-day technology provider that offers highly-advanced IoT services to give you next-level experience. We provide uncompromised IoT app development services & staff augmentation services to manage your business, so you don’t have to use energy and time to manage secondary functions. Our team will create flexible and cost-effective IoT technologies as per modern-day standards to bring you more ROI.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Caption Section End */}
      {/* Technology Section */}
      <div className="technology-stack-section">
        <Container>
          <div className="main-heading main-heading-left">
            <h2 className="main-title">Internet OF Things – technology stack</h2>
            <p className="description">We create mobile apps with scalable and tested technology stack to deliver apps par excellence.</p>
          </div>
          <Row className="row-holder">
            <Col xs="12" lg="5">
              <h3 className="title">Language</h3>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '1' })}
                    onClick={() => { toggle('1'); }}
                  >
                    <img src="/images/iot/php.png" alt="" />
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '2' })}
                    onClick={() => { toggle('2'); }}
                  >
                    <img src="/images/iot/swift.png" alt="" />
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '3' })}
                    onClick={() => { toggle('3'); }}
                  >
                    <img src="/images/iot/javascipt.png" alt="" />
                  </NavLink>
                </NavItem>
              </Nav>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '4' })}
                    onClick={() => { toggle('4'); }}
                  >
                    <img src="/images/iot/go.png" alt="" />
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '5' })}
                    onClick={() => { toggle('5'); }}
                  >
                    <img src="/images/iot/python.png" alt="" />
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
            <Col xs="12" lg="9">
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <div className="tabs-content-caption">
                    <div className="title-wrap">
                      <img src="/images/kotlin.png" alt="" className="icon" />
                      <h3 className="title">{kotlin.title}</h3>
                    </div>
                    <p className="description">{kotlin.description}</p>
                  </div>
                </TabPane>
                <TabPane tabId="2">
                  <div className="tabs-content-caption">
                    <div className="title-wrap">
                      <img src="/images/swift.png" alt="" className="icon" />
                      <h3 className="title">{swift.title}</h3>
                    </div>
                    <p className="description">{swift.description}</p>
                  
                  </div>
                </TabPane>
                <TabPane tabId="3">
                  <div className="tabs-content-caption">
                    <div className="title-wrap">
                      <img src="/images/sqlite.png" alt="" className="icon" />
                      <h3 className="title">{sqlite.title}</h3>
                    </div>
                    <p className="description">{sqlite.description}</p>
                  </div>
                </TabPane>
                <TabPane tabId="4">
                  <div className="tabs-content-caption">
                    <div className="title-wrap">
                      <img src="/images/objective-c.png" alt="" className="icon" />
                      <h3 className="title">{objectivec.title}</h3>
                    </div>
                    <p className="description">{objectivec.description}</p>
                  </div>
                </TabPane>
                <TabPane tabId="5">
                  <div className="tabs-content-caption">
                    <div className="title-wrap">
                      <img src="/images/javascript.png" alt="" className="icon" />
                      <h3 className="title">{javascript.title}</h3>
                    </div>
                    <p className="description">{javascript.description}</p>
                  </div>
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </Container>
      </div>
      {/* Technology Section End */}
      {/* Insights Section */}
      <InsightsSection />
      {/* Insights Section End */}
      {/* Highlight Section */}
      <HighlightsSection />
      {/* Highlight Section End */}
      {/* Testimonials Section */}
      <TestimonialsSection />
      {/* Testimonials Section End */}
      {/* Awards Section */}
      <AwardsSection />
      {/* Awards Section End */}
      {/* Project Form */}
      <ProjectForm />
      {/* Project Form End */}
    </div>
  )
};

export default IotAppsDevelopment;

