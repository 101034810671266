/**
 *
 * Mobylogix
 * Author: Mobylogix
 * Email: info@mobylogix.com
 * Website: mobylogix.com
 * Version: 1.0
 * Date: Tue Oct 12 2021
 * Category: Pages
 * Title: Drawer
 *
 */

import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { useHistory } from "react-router-dom";

// styles
import './drawer.css';

const Drawer = ({ drawerShow, setDrawerShow }) => {
  const [dropdownShowSec, setDropdownShowSec] = useState(false);

  const history = useHistory();
  
  return (
    <div className={`drawer ${drawerShow ? 'show-drawer' : ''}`}>
      <div className="drawer-inner">
        <a className="close-drawer-icon" onClick={() => setDrawerShow(!drawerShow)}>
          <svg height="24" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" width="24">
            <line x1="3" x2="21" y1="12" y2="12"/><line x1="3" x2="21" y1="6" y2="6"/><line x1="3" x2="21" y1="18" y2="18"/>
          </svg>
        </a>
        <ul className="main-menu">
          <ServicesMenu 
            dropdownShowSec={dropdownShowSec} 
            setDropdownShowSec={setDropdownShowSec} 
            drawerShow={drawerShow} 
            setDrawerShow={setDrawerShow} 
            history={history} 
          />
          <li>
            <a rel="nooppener noreferrer" className="uppercase" onClick={() => { history.push("/work"); setDrawerShow(!drawerShow) }}>Work</a>
          </li>
          <li>
            <a rel="nooppener noreferrer" className="uppercase" onClick={() => { history.push("/careers"); setDrawerShow(!drawerShow) }}>Careers</a>
          </li>
          <li>
            <a rel="nooppener noreferrer" className="uppercase" onClick={() => { history.push("/about"); setDrawerShow(!drawerShow) }}>About</a>
          </li>
          <li>
            <a rel="nooppener noreferrer" className="uppercase" onClick={() => { window.location.href = '/contact'; setDrawerShow(!drawerShow) }}>Contact</a>
          </li>
        </ul>
      </div>
    </div>
  )
};

Drawer.propTypes = {};

export default Drawer;

const ServicesMenu = ({ dropdownShowSec, setDropdownShowSec, drawerShow, setDrawerShow, history }) => (
  <li className="menu-dropdown-holder">
    <a rel="nooppener noreferrer" className="uppercase" onClick={() => setDropdownShowSec(!dropdownShowSec)}>Solutions</a>
    <div className={`menu-dropdown ${dropdownShowSec ? 'show-menu-dropdown' : ''}`}>
      <div className="menu-dropdown-wrap">
        <Container>
          <Row>
            <Col xs="12" lg="3">
              <div className="dropdown-heading">
                <h3 className="title">Resources</h3>
                <p className="description">We are building industry leading digital tools to create the most distinctive market ruling digital products.</p>
              </div>
            </Col>
            <Col xs="12" md="6" lg="3">
              <div className="dropdown-col-wrap">
                <h3 className="main-title">Servies</h3>
                <ul className="dropdown-col-list">
                  <li>
                    <a onClick={() => { history.push("/mobile-app-development"); setDropdownShowSec(false); setDrawerShow(!drawerShow) }} rel="nooppener noreferrer">
                      <h4 className="title">Mobile Apps Development</h4>
                      <p>iOS, Android and Wearable Apps</p>
                    </a>
                  </li>
                  <li>
                    <a onClick={() => { history.push("/saas-web-development"); setDropdownShowSec(false); setDrawerShow(!drawerShow) }} rel="nooppener noreferrer">
                      <h4 className="title">SaaS/Web Development</h4>
                      <p>Robust web development</p>
                    </a>
                  </li>
                  <li>
                    <a onClick={() => { history.push("/ecommerce-development"); setDropdownShowSec(false); setDrawerShow(!drawerShow) }} rel="nooppener noreferrer">
                      <h4 className="title">Ecommerce Development</h4>
                      <p>Ecommerce Development</p>
                    </a>
                  </li>
                  {/* <li>
                    <a onClick={() => history.push("/internet-of-things")} rel="nooppener noreferrer">
                      <h4 className="title">Internet of Things</h4>
                      <p>iOS, Android and Wearable Apps</p>
                    </a>
                  </li> */}
                  <li>
                    <a onClick={() => { history.push("/machine-learing"); setDropdownShowSec(false); setDrawerShow(!drawerShow) }} rel="nooppener noreferrer">
                      <h4 className="title">AI/Machine Learning</h4>
                      <p>AI and Machine Learning</p>
                    </a>
                  </li>
                  <li>
                    <a onClick={() => { history.push("/blockchain-development"); setDropdownShowSec(false); setDrawerShow(!drawerShow) }} rel="nooppener noreferrer">
                      <h4 className="title">Blockchain Development</h4>
                      <p>Blockchain DApp Development</p>
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
            <Col xs="12" md="6" lg="3">
              <div className="dropdown-col-wrap">
                <h3 className="main-title">&nbsp;</h3>
                <ul className="dropdown-col-list">
                  <li>
                    <a onClick={() => { history.push("/cloud-services"); setDropdownShowSec(false); setDrawerShow(!drawerShow) }} rel="nooppener noreferrer">
                      <h4 className="title">Cloud Services</h4>
                      <p>Cloud Services</p>
                    </a>
                  </li>
                  <li>
                    <a onClick={() => { history.push("/virtual-reality"); setDropdownShowSec(false); setDrawerShow(!drawerShow) }} rel="nooppener noreferrer">
                      <h4 className="title">Virtual Reality (VR)</h4>
                      <p>Modern AR apps</p>
                    </a>
                  </li>
                  <li>
                    <a onClick={() => { history.push("/mvp-development"); setDropdownShowSec(false); setDrawerShow(!drawerShow) }} rel="nooppener noreferrer">
                      <h4 className="title">MVP Development</h4>
                      <p>MVP for Startups</p>
                    </a>
                  </li>
                  <li>
                    <a onClick={() => { history.push("/custom-enterprise-software-development"); setDropdownShowSec(false); setDrawerShow(!drawerShow) }} rel="nooppener noreferrer">
                      <h4 className="title">Custom Enterprise Software Development</h4>
                      <p>Custom Enterprise Software Development</p>
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
            <Col xs="12" md="6" lg="3">
              <div className="dropdown-col-wrap">
                <h3 className="main-title">How we Work</h3>
                <ul className="dropdown-col-list">
                  <li>
                    <a rel="nooppener noreferrer">
                      <h4 className="title">Discovery</h4>
                      <p>Exploring project requirements</p>
                    </a>
                  </li>
                  <li>
                    <a rel="nooppener noreferrer">
                      <h4 className="title">Design</h4>
                      <p>Intuitive and engaging designs</p>
                    </a>
                  </li>
                  <li>
                    <a rel="nooppener noreferrer">
                      <h4 className="title">Development</h4>
                      <p>Agile development processes</p>
                    </a>
                  </li>
                  <li>
                    <a rel="nooppener noreferrer">
                      <h4 className="title">Testing</h4>
                      <p>Incremental testing and QA</p>
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  </li>
);
