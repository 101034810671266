import React from "react";

const AwardsEdtech = ({ extraClass= '' }) => (
  <svg className={extraClass} xmlns="http://www.w3.org/2000/svg" fill={"#007eff"} width="20px" height="20px" version="1.1" style={{ shapeRendering: 'geometricPrecision', textRendering: 'geometricPrecision', imageRendering: 'optimizeQuality', fillRule: 'evenodd', clipRule: 'evenodd' }}
    viewBox="0 0 223.8 223.8">
    <g id="Layer_x0020_1">
      <metadata id="CorelCorpID_0Corel-Layer"/>
      <path d="M207.19 143.37c-6.38,0 -4.66,-6.99 -0.87,-6.99 5.17,0 4.36,6.99 0.87,6.99zm-96.12 -143.37l1.66 0c1.43,0.4 2.67,1.86 2.67,4.37l0 51.58 6.99 0 0 -13.55c0,-5.12 6.99,-5.39 6.99,0l0 13.55 6.99 0c0,-40.88 1.19,-24.06 -4.89,-32.7 -5.35,-7.59 1.2,-16.25 7.51,-16.25 10.66,0 14.14,10.17 9.02,16.88 -0.75,0.98 -1.34,1.39 -2.38,1.99 -1.11,0.65 -2.27,0.77 -2.27,2.1l0 27.97 6.99 0c9.56,0 17.48,7.92 17.48,17.48l0 6.99c11.22,0 11.65,1.26 17.59,-4.7l18.69 -18.47c-0.36,-1.56 -0.87,-2.05 -0.87,-3.93 0,-6.77 4.27,-11.36 9.62,-11.36 6.49,0 10.93,4.6 10.93,9.62 0,10.7 -9.65,12.56 -14.86,10.05 -1.32,1.97 -5.37,5.59 -7.32,7.54 -17.92,17.92 -14.72,18.25 -33.77,18.25l0 6.99 13.55 0c5.39,0 5.12,6.99 0,6.99l-13.55 0 0 6.99 51.58 0c2.51,0 3.97,1.24 4.37,2.67l0 1.66c-0.4,1.43 -1.86,2.67 -4.37,2.67l-51.58 0 0 6.99 13.55 0c5.12,0 5.39,6.99 0,6.99l-13.55 0 0 6.99c40.88,0 24.06,1.19 32.7,-4.89 7.59,-5.35 16.25,1.2 16.25,7.51 0,12.85 -14.35,14.37 -18.88,6.64 -0.65,-1.11 -0.77,-2.27 -2.1,-2.27l-27.97 0 0 6.99c0,9.91 -6.63,7.67 -7.01,3.95l0.02 -67.33c0,-8.91 1.37,-17.06 -3.15,-21.33 -2.71,-2.56 -4.42,-2.73 -9.52,-2.72l-73.43 0c-4.24,0 -8.31,0.9 -8.31,-3.5 0,-4.49 9.57,-3.5 13.99,-3.5 0,-11.22 1.26,-11.65 -4.7,-17.59l-18.47 -18.69c-1.56,0.36 -2.05,0.87 -3.93,0.87 -7,0 -11.36,-3.91 -11.36,-10.93 0,-4.81 4.81,-9.62 9.62,-9.62 9.68,0 13.1,8.52 10.05,14.86 1.97,1.32 5.59,5.37 7.54,7.32 17.92,17.92 18.25,14.73 18.25,33.77l6.99 0 0 -13.55c0,-5.39 6.99,-5.12 6.99,0l0 13.55 6.99 0 0 -51.58c0,-2.51 1.24,-3.97 2.67,-4.37zm102.24 48.96l0.44 0c4.72,0 3.98,6.99 0,6.99 -4.8,0 -5.1,-6.99 -0.44,-6.99zm-76.93 -31.47c0,-1.91 1.39,-3.55 3.17,-3.75 1.71,-0.19 3.82,0.87 3.82,3.75 0,4.66 -6.99,4.2 -6.99,0zm-87.42 -6.99l0 -0.44c0,-4.72 6.99,-3.98 6.99,0 0,4.8 -6.99,5.1 -6.99,0.44z"/>
      <path d="M167.85 213.75c0,-4.8 6.99,-5.1 6.99,-0.44l0 0.44c0,4.72 -6.99,3.98 -6.99,0zm-55.12 10.05l-1.66 0c-1.43,-0.4 -2.67,-1.86 -2.67,-4.37l0 -51.58 -6.99 0 0 13.55c0,5.12 -6.99,5.39 -6.99,0l0 -13.55 -6.99 0 0 27.97c0,1.72 7.85,3.99 6.85,11.63 -0.61,4.65 -4.53,9.36 -9.47,9.36 -12.85,0 -14.37,-14.35 -6.64,-18.88 1.11,-0.65 2.27,-0.77 2.27,-2.1l0 -27.97 -6.99 0c-9.56,0 -17.48,-7.92 -17.48,-17.48l0 -6.99c-11.22,0 -11.65,-1.26 -17.59,4.7l-18.69 18.47c0.36,1.56 0.87,2.05 0.87,3.93 0,6.77 -4.27,11.36 -9.62,11.36 -6.49,0 -10.93,-4.6 -10.93,-9.62 0,-10.7 9.65,-12.56 14.86,-10.05l14.75 -14.97c2.58,-2.58 4.77,-5 7.59,-7.27 5.52,-4.42 10.88,-3.55 18.74,-3.55l0 -6.99 -13.55 0c-5.39,0 -5.12,-6.99 0,-6.99l13.55 0 0 -6.99 -51.58 0c-2.51,0 -3.97,-1.24 -4.37,-2.67l0 -1.66c0.4,-1.43 1.86,-2.67 4.37,-2.67l51.58 0 0 -6.99 -13.55 0c-5.12,0 -5.39,-6.99 0,-6.99l13.55 0 0 -6.99c-40.88,0 -24.06,-1.19 -32.7,4.89 -7.59,5.35 -16.25,-1.2 -16.25,-7.51 0,-12.85 14.35,-14.37 18.88,-6.64 0.65,1.11 0.77,2.27 2.1,2.27l27.97 0 0 -6.99c0,-9.91 6.63,-7.67 7.01,-3.95l-0.02 67.33c0,8.91 -1.37,17.06 3.15,21.33 2.71,2.56 4.42,2.73 9.52,2.72l73.43 -0c4.24,-0 8.31,-0.9 8.31,3.5 0,4.49 -9.57,3.5 -13.99,3.5 0,11.22 -1.26,11.65 4.7,17.59l18.47 18.69c1.56,-0.36 2.05,-0.87 3.93,-0.87 6.76,0 11.36,4.16 11.36,9.62 0,6.49 -4.6,10.93 -9.62,10.93 -9.68,0 -13.1,-8.52 -10.05,-14.86 -1.97,-1.32 -5.59,-5.37 -7.54,-7.32 -17.92,-17.92 -18.25,-14.73 -18.25,-33.77l-6.99 0 0 13.55c0,5.39 -6.99,5.12 -6.99,0l0 -13.55 -6.99 0 0 51.58c0,2.51 -1.24,3.97 -2.67,4.37zm-32.3 -16.61c0,-6.38 6.99,-4.66 6.99,-0.87 0,5.17 -6.99,4.36 -6.99,0.87zm-70.37 -39.34c4.8,0 5.1,6.99 0.44,6.99l-0.44 0c-4.72,0 -3.98,-6.99 0,-6.99zm6.56 -87.42c6.38,0 4.66,6.99 0.87,6.99 -5.17,0 -4.36,-6.99 -0.87,-6.99z"/>
      <path d="M95.73 86.11c0,-7.44 10.82,-7.16 12.48,-1.99 0.79,2.44 0.55,4.89 3.69,4.89 5.93,0 -0.2,-8.58 10.93,-8.58 1.61,0 3.21,1.11 4.08,2.04 1.4,1.51 1.17,2.31 1.17,4.95 -2.32,0.54 -5.84,0.96 -7.03,2.61 -1.47,2.04 -0.4,5.26 2.66,5.26 2.56,0 1.45,-0.87 6.99,-0.87 7.84,0 12.47,9.72 6.44,14.75 -1.68,1.4 -3.82,1.64 -3.82,4.48 0,2.7 2,3.07 3.58,4.29 4.55,3.52 3.47,10.93 -0.08,11.88 -0.46,-1.72 -2.13,-2.97 -3.42,-4.01 -8.11,-6.5 -11.53,1.16 -7.24,3.74 2.82,1.69 5.85,2.21 5.85,7.7 0,7.59 -16.61,9.07 -16.61,-0.87l0 -20.98c0,-9.19 11.8,-4.88 11.8,-10.49 0,-4.36 -5.37,-3.57 -8.03,-2.79 -1.79,0.52 -2.08,1.09 -3.77,1.48 0,-3.71 0.2,-6.99 -3.5,-6.99 -4.26,0 -3.5,4.56 -3.5,8.74 0,4.08 0,8.16 0,12.24 -2.66,-0.62 -1.13,-1.31 -5.25,-1.31 -1.14,0 -2.62,1.93 -2.62,2.62 0,5.21 7.87,4.04 7.87,10.05 0,7.79 1.39,14.42 -9.18,14.42 -3.5,0 -7.43,-2.46 -7.43,-6.99 0,-4.55 4.45,-5.32 2.29,-8.81 -2.71,-4.38 -6.71,1.27 -7.53,1.82 -2.18,-4.11 -2.62,-2.4 -2.62,-8.3 0,-2.99 3.27,-6.88 7.05,-8.25 1.38,-0.5 6.56,-2.03 3.98,-5.81 -2.14,-3.15 -6.1,-0.97 -9.72,0.95 -0.62,-2.32 -1.31,-1.15 -1.31,-5.25 0,-3.85 2.63,-6 5.38,-7.3 2.48,-1.17 2.22,-0.6 5.01,-1.11 6.13,-1.12 1.42,-6.59 1.42,-8.2zm-6.99 -0.44c0,1.65 0.29,0.83 0.44,2.62 -8.58,2 -15.85,11.93 -10.21,22.01 0.58,1.04 0.05,0.21 0.67,1.07 0.31,0.43 0.55,0.68 0.79,0.96 -4.18,6.24 -5.04,13.01 -0.32,19.56 4.6,6.39 3.8,1.43 5.17,7.51 2.36,10.51 16.38,13.96 24.07,7.97 2.91,-2.27 1.8,-2.78 4.87,-0.2 6.9,5.8 20.88,3.46 24.03,-6.74 2.07,-6.71 0.88,-1.64 5.2,-7.04 6.27,-7.84 2.92,-13.96 -0.95,-19.75 1.1,-1.64 1.93,-2.41 2.93,-4.5 4.31,-9.1 -2.11,-18.83 -10.8,-20.85 0.15,-1.8 0.44,-0.98 0.44,-2.62 0,-6.72 -6.36,-12.24 -13.11,-12.24 -5.25,0 -7.02,1.9 -10.05,3.93 -2.76,-1.84 -4.93,-3.93 -9.62,-3.93 -7.12,0 -13.55,5.29 -13.55,12.24z"/>
    </g>
  </svg>
)

export default AwardsEdtech;
