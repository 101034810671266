/**
 *
 * Mobylogix
 * Author: Mobylogix
 * Email: info@mobylogix.com
 * Website: mobylogix.com
 * Version: 1.0
 * Date: Tue Nov 09 2021
 * Category: Pages
 * Title: MobileAppsDevelopment
 *
 */

import React, { useState, useEffect } from 'react';
import Slider from "react-slick";
import { useCountUp } from 'react-countup';

import { Container, Row, Col, TabContent, TabPane, Nav, NavItem, NavLink,  } from 'reactstrap';
import classnames from 'classnames';

// util
import { googleCloud, amazon, azure } from "../../utils/technolgy";
import { CloudMeta } from 'utils/metas';

// Components
import InnerBanner from 'components/InnerBanner';
import DevelopmentServices from 'components/DevelopmentServices';
import CreativeSection from 'components/CreativeSection';
import ProcessSection from 'components/ProcessSection';
import TechnologyStackSection from 'components/TechnologyStackSection';
import InsightsSection from 'components/InsightsSection';
import HighlightsSection from 'components/HighlightsSection';
import TestimonialsSection from 'components/TestimonialsSection';
import AwardsSection from 'components/AwardsSection';
import ProjectForm from 'components/ProjectForm';
import FeaturedWork from 'components/FeaturedWork';

const MobileAppsDevelopment = (props) => {
  // const totalHeight = document.getElementsByClassName('mobile-app-development')[0].offsetHeight;

  // useEffect(() => {
  //   let height = document.getElementsByClassName('mobile-app-development')[0].offsetHeight;

  //   console.log('height', height);

  //   const calcHeight = (height / 100) * 75;

  //   console.log('height1', height);

  //   if (calcHeight > window.scrollY) {

  //   }
  // });

  const settings = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  const [activeTab, setActiveTab] = useState('1');

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  }

  return (
    <div className="inner-page mobile-app-development">
      {/* Meta */}
      <CloudMeta />
      {/* Meta End */}
      {/* Inner Banner */}
      <InnerBanner title={"Cloud Computing Service Provider"} description={"Describe a global network of servers, each with a unique function"} />
      {/* Inner Banner End */}
      {/* Development Services */}
      <DevelopmentServices title={"Cloud Computing Service Provider"} description={"Mobylogix provides cloud service, or CSP. We offer components of cloud computing -- typically, infrastructure as a service (IaaS), software as a service (SaaS) or platform as a service."} />
      {/* Development Services End */}
      {/* Creative Section */}
      <div className="creative-section">
      <Container fluid>
        <Row className="row-holder">
          <Col xs="12" md="6" lg="5">
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <figure>
                  <img src="/images/Amazon.jpg" alt="" />
                </figure>
              </TabPane>
              <TabPane tabId="2">
                <figure>
                  <img src="/images/Google Cloud.jpg" alt="" />
                </figure>
              </TabPane>
              <TabPane tabId="3">
                <figure>
                  <img src="/images/Microsoft azure.jpg" alt="" />
                </figure>
              </TabPane>
            </TabContent>
          </Col>
          <Col xs="12" md="6" lg="7">
            <div className="main-heading">
              <h2 className="main-title">Cloud Computing Service Supplier</h2>
              <p className="description">Mobylogix provides distinctive cloud services that include servers, networking, databases, analytics, intelligence and so much more.</p>
            </div>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '1' })}
                  onClick={() => { toggle('1'); }}
                >
                  Amazon Web Service
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '2' })}
                  onClick={() => { toggle('2'); }}
                >
                  Google Cloud 
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '3' })}
                  onClick={() => { toggle('3'); }}
                >
                  Microsoft Azure
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <div className="tabs-content-caption">
                  <h3 className="title">Amazon Web Service</h3>
                  <p className="description">Amazon Web Services (aws) is a cloud computing platform services provider that provides such as compute power, database storage, content delivery and many other functions which will help to integrate a business.</p>
                </div>
              </TabPane>
              <TabPane tabId="2">
                <div className="tabs-content-caption">
                  <h3 className="title">Google Cloud </h3>
                  <p className="description">Google cloud platform (GCP) is one of the leading Cloud Computing services which are offered by Google and it runs on the same infrastructure that Google uses for its end-user products.</p>
                </div>
              </TabPane>
              <TabPane tabId="3">
                <div className="tabs-content-caption">
                  <h3 className="title">Microsoft Azure</h3>
                  <p className="description">Microsoft Azure is a cloud computing service operator which is used for building, testing, managing and deploying the application. All processes are done in a global network of the Microsoft-managed data center. It is a private as well as a public cloud platform.</p>
                </div>
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </Container>
    </div>
      {/* Creative Section End */}
      {/* Featured Work Slider */}
      <FeaturedWork />
      {/* Featured Work Slider End */}
      {/* Product Slider */}
      {/* <section className="product-section">
        <Container>
          <div className="main-heading">
            <h2 className="main-title">Our featured works</h2>
          </div>
          <Slider {...settings} className="product-slider">
            <div className="product-slider-item">
              <Row className="row-holder">
                <Col xs="12" lg="6">
                  <div className="product-imgs">
                    <figure className="product-img">
                      <img src="/images/deribit.png" alt="" />
                    </figure>
                    <figure className="product-img-1">
                      <img src="https://www.Mobylogix.co/themes/responsiv-clean/assets/images/home/foodly-mobile-image-3.png" alt="" />
                    </figure>
                  </div>
                </Col>
                <Col xs="12" lg="6">
                  <div className="product-caption">
                    <figure className="product-icon">
                      <img src="/images/deribit.icon.png" alt="" />
                    </figure>
                    <strong className="sub-title">Mobile App</strong>
                    <h3 className="title">Foodly - the hub of delicious cuisines</h3>
                    <p className="description">Order breakfast, lunch, or dinner, rate your favorite restaurants and track your order - one platform to curb your hunger.</p>
                    <a href="#" rel="nooppener noreferrer" className="link-underline">View Case Study</a>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="product-slider-item">
              <Row className="row-holder">
                <Col xs="12" lg="6">
                  <div className="product-imgs">
                    <figure className="product-img">
                      <img src="/images/craftsman.png" alt="" />
                    </figure>
                    <figure className="product-img-1">
                      <img src="" alt="" />
                    </figure>
                  </div>
                </Col>
                <Col xs="12" lg="6">
                  <div className="product-caption">
                    <figure className="product-icon">
                      <img src="/images/craftsman-icon.png" alt="" />
                    </figure>
                    <strong className="sub-title">Mobile App</strong>
                    <h3 className="title">Foodly - the hub of delicious cuisines</h3>
                    <p className="description">Order breakfast, lunch, or dinner, rate your favorite restaurants and track your order - one platform to curb your hunger.</p>
                    <a href="#" rel="nooppener noreferrer" className="link-underline">View Case Study</a>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="product-slider-item">
              <Row className="row-holder">
                <Col xs="12" lg="6">
                  <div className="product-imgs">
                    <figure className="product-img">
                      <img src="/images/ahcip.chs.harvard.edu.png" alt="" />
                    </figure>
                    <figure className="product-img-1">
                      <img src="" alt="" />
                    </figure>
                  </div>
                </Col>
                <Col xs="12" lg="6">
                  <div className="product-caption">
                    <figure className="product-icon">
                      <img src="/images/ahcip.chs.harvard.edu.icon.png" alt="" />
                    </figure>
                    <strong className="sub-title">Mobile App</strong>
                    <h3 className="title">Foodly - the hub of delicious cuisines</h3>
                    <p className="description">Order breakfast, lunch, or dinner, rate your favorite restaurants and track your order - one platform to curb your hunger.</p>
                    <a href="#" rel="nooppener noreferrer" className="link-underline">View Case Study</a>
                  </div>
                </Col>
              </Row>
            </div>
          </Slider>
        </Container>
      </section> */}
      {/* Product Slider End */}
      {/* Caption Section */}
      <section className="deve-caption-wrap deve-caption-primary">
        <Container>
          <Row className="row-justify-center">
            <Col xs="12" md="9" lg="7">
              <div className="main-heading">
                <h2 className="main-title">Amazon Web Services (AWS)</h2>
                <p className="description">Amazon Web Services (aws) is a cloud computing platform services provider that provides such as compute power, database storage, content delivery and many other functions which will help to integrate a business.</p>
                <p className="description">The Amazon Web Services (aws) is flexible, scalable, and  reliable or due to this most of the companies are implementing it in their work. There is no upfront cost and the customers have to pay only for what they have used. Amazon Web Services (aws) is one of the leading cloud service providers among all.</p>
                <p className="description">With the help of the internet, the customers can access highly durable storage such as Amazon Glacier, Amazon EBS and Amazon S3. It also has a high-performance database such as Amazon Redshift, Amazon ElastiCache, Amazon Dynamo DB and Amazon RDS.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Caption Section End */}
      {/* Caption Section */}
      <section className="deve-caption-wrap">
        <Container>
          <Row className="row-justify-center">
            <Col xs="12" md="9" lg="7">
              <div className="main-heading">
                <h2 className="main-title">Google Cloud Platform</h2>
                <p className="description">Google cloud platform (GCP) is one of the leading Cloud Computing services which are offered by Google and it runs on the same infrastructure that Google uses for its end-user products.</p>
                <p className="description">The Google cloud platform is fundamentally used for Google search and YouTube. There are different services offered by Google Cloud platform (GCP) such as data analysis, data storage and machine learning.</p>
                <p className="description">The data which is stored in Google Cloud is secure and can be accessed easily. It offers different types of services from infrastructure as a service to platform service. Google also provides a strong commitment to stability and security.</p>
                <p className="description">With the help of the Google cloud platform(GCP), the user is totally free to think about the code and the features that are needed to develop without worrying about the operations side.</p>
                <p className="description">Here most of the services are detailed or fully managed, and quite easy for the customer to concentrate on their work. The use of API and Machine learning are very easy. The API helps in speech detection language translation very easily. So it is preferred among the customers.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Caption Section End */}
      {/* Caption Section */}
      <section className="deve-caption-wrap deve-caption-primary">
        <Container>
          <Row className="row-justify-center">
            <Col xs="12" md="9" lg="7">
              <div className="main-heading">
                <h2 className="main-title">Apps developed by Mobylogix rake in success</h2>
                <p className="description">It doesn’t matter if you are an enterprise, a unicorn startup or a small-scale business, our development process stays the same, which has awesome written all over it. We begin by asking the number one question “How can I turn my idea into a successful app”. “An app that becomes an integral part of everyone’s life”. The answer: our app development process. We want our apps to be intuitive, appealing, robust and friendly.</p>
                <p className="description">We aren’t in a rush to escalate your project as we believe in detailed evaluation of a product, through various stages including Planning, sketching, wireframing, prototyping, developing, quality assurance, and launching an MVP. Our goal is to create mobile apps that are bound to rake in popularity, downloads and marvelous word of mouth.</p>
                <p className="description">You can find Mobylogix’s leading mobile app development team in USA, Lithuania, Lahore, and Islamabad.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Caption Section End */}
      {/* Process Section */}
      <ProcessSection />
      {/* Process Section End */}
      {/* Caption Section */}
      {/* <section className="deve-caption-wrap deve-caption-primary">
        <Container>
          <Row className="row-justify-center">
            <Col xs="12" md="9" lg="7">
              <div className="main-heading">
                <h2 className="main-title">World-famous mobile application development company</h2>
                <p className="description">Mobile apps indeed hold a significant chunk of the market, currently (about 58%), making it important for your business to invest in one. If you have a wide customer base from your e-commerce website, imagine the power of pairing that with a mobile app!</p>
                <p className="description">Mobylogix is a popular mobile apps development company that helps you achieve your goals. We examine your particular need and work to develop your app solution to perfection! And it all starts with a rock-solid plan that not only sets the parameters of your project features, but also minutely defines and strategizes the length and breadth of programming required. This concrete plan is matched against a strict timeline to ensure delivery on time.</p>
                <p className="description">Every customer is important to us, as we believe in taking everyone who approaches us to the next level. So, no matter what kind of app you are desirous of building; we can build anything for you from scratch.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section> */}
      {/* Caption Section End */}
      {/* Technology Section */}
      <div className="technology-stack-section">
        <Container>
          <div className="main-heading main-heading-left">
            <h2 className="main-title">Cloud Technology</h2>
            <p className="description">We provide Cloud computing service with scalable and tested technology stack to deliver par excellence.</p>
          </div>
          <Row className="row-holder">
            <Col xs="12" lg="5">
              <h3 className="title">Cloud</h3>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '1' })}
                    onClick={() => { toggle('1'); }}
                  >
                    <img src="/images/cloude/Microsoft-Azure-Logo.png" alt="" />
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '2' })}
                    onClick={() => { toggle('2'); }}
                  >
                    <img src="/images/cloude/Amazon logo.png" alt="" />
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '3' })}
                    onClick={() => { toggle('3'); }}
                  >
                    <img src="/images/cloude/google-cloud-logo-1.png" alt="" />
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
            <Col xs="12" lg="7">
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <div className="tabs-content-caption">
                    <div className="title-wrap">
                      <img src="/images/cloude/Microsoft-Azure-Logo.png" alt="" className="icon" />
                      <h3 className="title">{azure.title}</h3>
                    </div>
                    <p className="description">{azure.description}</p>
                  </div>
                </TabPane>
                <TabPane tabId="2">
                  <div className="tabs-content-caption">
                    <div className="title-wrap">
                      <img src="/images/cloude/Amazon logo.png" alt="" className="icon" />
                      <h3 className="title">{amazon.title}</h3>
                    </div>
                    <p className="description">{amazon.description}</p>
                  
                  </div>
                </TabPane>
                <TabPane tabId="3">
                  <div className="tabs-content-caption">
                    <div className="title-wrap">
                      <img src="/images/cloude/google-cloud-logo-1.png" alt="" className="icon" />
                      <h3 className="title">{googleCloud.title}</h3>
                    </div>
                    <p className="description">{googleCloud.description}</p>
                  </div>
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </Container>
      </div>
      {/* Technology Section End */}
      {/* Insights Section */}
      {/* <InsightsSection /> */}
      {/* Insights Section End */}
      {/* Highlight Section */}
      <HighlightsSection />
      {/* Highlight Section End */}
      {/* Testimonials Section */}
      <TestimonialsSection />
      {/* Testimonials Section End */}
      {/* Awards Section */}
      <AwardsSection />
      {/* Awards Section End */}
      {/* Project Form */}
      <ProjectForm />
      {/* Project Form End */}
    </div>
  )
};

export default MobileAppsDevelopment;
