/**
 *
 * Mobylogix
 * Author: Mobylogix
 * Email: info@mobylogix.com
 * Website: mobylogix.com
 * Version: 1.0
 * Date: Wed Nov 17 2021
 * Category: Pages
 * Title: About
 *
 */

import React, { useState } from 'react';
import { Container, Row, Col, Card, CardBody, CardTitle } from 'reactstrap';
import CountUp from 'react-countup';
import ModalVideo from 'react-modal-video';

// Components
import AboutBanner from 'components/AboutBanner';
import TestimonialsSection from 'components/TestimonialsSection';

const About = (props) => {

  const [isOpen, setOpen] = useState(false);

  const widgets = [
    {
      title: 'Startup partener',
      subTitle: 'program™',
      description: 'We have created Startup Partner Program™ for funding early stage startups. We make small investments in return for small stakes in the startups we build.'
    },
    {
      title: 'Startup partener',
      subTitle: 'program™',
      description: 'We have created Startup Partner Program™ for funding early stage startups. We make small investments in return for small stakes in the startups we build.'
    },
    {
      title: 'Startup partener',
      subTitle: 'program™',
      description: 'We have created Startup Partner Program™ for funding early stage startups. We make small investments in return for small stakes in the startups we build.'
    }
  ];

  const mediaWidgets = [
    {
      path: '',
      title: `There's Finally a Dating App for People With Disabilities`,
    },
    {
      path: '',
      title: `There's Finally a Dating App for People With Disabilities`,
    },
    {
      path: '',
      title: `There's Finally a Dating App for People With Disabilities`,
    },
  ];

  const techWidgets = [
    {
      path: '/images/arslan-nasir-ceo.png',
      title: `Arslan Nasir`,
      position: 'CEO'
    },
    {
      path: '/images/serhii-marketing.jpeg',
      title: `Serhii Monochkov`,
      position: 'Director of Business Development'
    },
    {
      path: '/images/osman-khan-pm.png',
      title: `Osman Khan`,
      position: 'Sr. Project Manager'
    },
    // {
    //   path: '/images/Jamshaid Ali (Digital Marketing Head).png',
    //   title: `Jamshaid Ali`,
    //   position: 'Digital Marketing Head'
    // },
    // {
    //   path: '/images/waseem-akram-qa.png',
    //   title: `Waseem Akram`,
    //   position: 'Business Analyst / Sr. SQA Engineer'
    // },
    {
      path: '/images/fiza-fayyaz-hr.png',
      title: `Fiza Fayyaz`,
      position: 'HR Manager'
    }
  ];

  return (
    <div className="inner-page about">
      {/* About Banner */}
      <AboutBanner
        title={'About'}
        description={`Mobylogix is a software and mobile app development company
        with a world-class team of talented data scientists,
        developers, designers, mathematicians, engineers, and
        creative artists. We are motivated to build elegant and
        functional digital products that solve complex problems.`}
        btnText={`Let's Get Started`}
      />
      {/* About Banner End */}
      {/* Tech Leader */}
      <section className="tech-leader">
        <Container>
          <Row>
            <Col xs="12" md="6">
              <figure className="img-wrap wow fadeInLeft">
                <img src="images/header.png" alt="" />
              </figure>
            </Col>
            <Col xs="12" md="6">
              <div className="tech-caption wow fadeInRight">
                <p className="sub-title">Work with the Tech Leader.</p>
                <h4 className="title">Mobylogix brings ideas to life, leveraging modern technologies.</h4>
                <p className="description">Mobylogix started its journey in 2015 with a team of two. Today, it has grown into a full-cycle, mobile-first software development company,
                  with the highest team satisfaction in the industry, independent analyst recognition, and an ever-growing list of delighted clients who work 
                  with us again and again. We are proud to have worked with Deribit, Brand Bassador, Tsenso, Craftsman Club. We have helped 
                  dozens of startups with modern technologies.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Tech Leader End */}
      {/* Count Wrap */}
      <section className="count-wrap">
        <Container>
          <hr className="hr-line" />
          <Row>
            <Col xs="12" md="3">
              <div className="count-box">
                <CountUp
                  className="count-balance"
                  start={0}
                  end={7}
                  duration={1.75}
                  suffix="+"
                  // onEnd={() => console.log('Ended! 👏')}
                  // onStart={() => console.log('Started! 💨')}
                  delay={0}
                >
                  {({ countUpRef, start }) => (
                    <div className="title-wrap">
                      <span className="title" ref={countUpRef} />
                      {/* <button onClick={start}>Start</button> */}
                    </div>
                  )}
                </CountUp>
                <span className="sub-title">Years</span>
              </div>
            </Col>
            <Col xs="12" md="3">
              <div className="count-box">
                <CountUp
                  className="count-balance"
                  start={0}
                  end={100}
                  duration={1.75}
                  suffix="+"
                  // onEnd={() => console.log('Ended! 👏')}
                  // onStart={() => console.log('Started! 💨')}
                  delay={0}
                >
                  {({ countUpRef, start }) => (
                    <div>
                      <span className="title" ref={countUpRef} />
                      {/* <button onClick={start}>Start</button> */}
                    </div>
                  )}
                </CountUp>
                <span className="sub-title">Clients</span>
              </div>
            </Col>
            <Col xs="12" md="3">
              <div className="count-box">
                <CountUp
                  className="count-balance"
                  start={0}
                  end={50}
                  duration={1.75}
                  suffix="+"
                  // onEnd={() => console.log('Ended! 👏')}
                  // onStart={() => console.log('Started! 💨')}
                  delay={0}
                >
                  {({ countUpRef, start }) => (
                    <div>
                      <span className="title" ref={countUpRef} />
                      {/* <button onClick={start}>Start</button> */}
                    </div>
                  )}
                </CountUp>
                <span className="sub-title">Employees</span>
              </div>
            </Col>
            <Col xs="12" md="3">
              <div className="count-box">
                <CountUp
                  className="count-balance"
                  start={0}
                  end={200}
                  duration={1.75}
                  suffix="+"
                  // onEnd={() => console.log('Ended! 👏')}
                  // onStart={() => console.log('Started! 💨')}
                  delay={0}
                >
                  {({ countUpRef, start }) => (
                    <div>
                      <span className="title" ref={countUpRef} />
                      {/* <button onClick={start}>Start</button> */}
                    </div>
                  )}
                </CountUp>
                <span className="sub-title">Projects</span>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Count Wrap End */}
      {/* Gredient Section */}
      {/* <section className="gredient-sec">
        <Container>
          <Row>
            {widgets.map((widget, i) => (
              <Col xs="12" md="4" key={i}>
                <div className="gredient-caption text-center">
                  <h4 className="title">{widget.title} <span className="d-block">{widget.subTitle}</span></h4>
                  <p className="description">{widget.description}</p>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section> */}
      {/* Gredient Section End */}
      {/* Creative Section */}
      <section className="about-creative-sec">
        <Container>
          <div className="main-heading">
            <h2 className="main-title">Our creative heads build beautiful digital products.</h2>
            <p className="description">Software and mobile app development for businesses from across the globe.</p>
          </div>
          <Row>
            <Col xs="12" md="8">
              {/* <figure className="img-wrap">
                <img src="/images/meetings.jpg" alt="" />
              </figure> */}
              <Row>
                <Col xs="12" md="6">
                  <h3>We are proud to have the best team of creative thinkers and fantastic clients from across the world, trusting our developing, designing, and branding.</h3>
                </Col>
                <Col xs="12" md="6">
                  <h3>Our Approach</h3>
                  <p className="text">To be the number one choice for businesses when it comes to digital product development, design, quality, and innovation.</p>
                </Col>
              </Row>
            </Col>
            <Col xs="12" md="4">
              {/* <figure className="img-wrap">
                <img src="/images/meetings1.jpg" alt="" />
              </figure> */}
              <h3>Our Mission</h3>
              <p className="text">To help businesses become competitive with the power of digital innovation, outstanding design, and emerging technologies via building intelligent digital products.</p>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Creative Section End */}
      {/* Media Mentions Section */}
      {/* <section className="media-mentions-sec">
        <Container>
          <div className="main-heading">
            <h2 className="main-title">Media mentions</h2>
            <p className="description">We stay in the news for our incredible tech achievements.</p>
          </div>
          <Row>
            {mediaWidgets.map((item, i) => (
              <Col xs="12" md="4" key={i}>
                <Card className="media-card">
                  <CardBody>
                    <figure className="img-wrap">
                      <img src={item.path} alt="" />
                    </figure>
                    <CardTitle tag="h3">{item.title}</CardTitle>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </section> */}
      {/* Media Mentions Section End */}
      {/* Ceo Section */}
      {/* <section className="ceo-sec">
        <img src="https://www.cubix.co/themes/responsiv-clean/assets/images/about/salman-lakhani-ceo-cubixinc.jpg" alt="" />
      </section> */}
      {/* Ceo Section End */}
      {/* Tech Experts Section */}
      <section className="tech-experts-sec">
        <Container>
          <div className="main-heading">
            <h2 className="main-title">Some of our tech experts</h2>
            <p className="description">Talent wins games, but teamwork and intelligence win championships.</p>
          </div>
          <Row className="row-justify-center">
            {techWidgets.map((item, i) => (
              <Col xs="12" md="4" lg="3" key={i}>
                <div className="tech-box">
                  <figure>
                    <img src={item.path} alt="" />
                  </figure>
                  <div className="tech-box-caption">
                    <h3 className="box-name">{item.title}</h3>
                    <h4 className="box-position">{item.position}</h4>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
      {/* Tech Experts Section End */}
      {/* Clients Section */}
      <section className="client-sec">
        <Container>
          <Row className="row-justify-center">
            <Col xs="12" md="8">
              <div className="main-heading">
                <h2 className="main-title">Headquartered in Los Angeles, California</h2>
                <p className="description">Mobylogix has maintained an unbroken record of successful custom software architecture and development since 2015, across startups, enterprises, and Fortune 500 Companies.</p>
              </div>
            </Col>
          </Row>
          {/* <Row className="row-justify-center">
            <Col xs="12" md="8">
              <div className="client-logo-wrap">
                <img src="https://www.cubix.co/themes/responsiv-clean/assets/images/about/politico.png" alt="" />
                <img src="https://www.cubix.co/themes/responsiv-clean/assets/images/about/bigfish-logo.png" alt="" />
                <img src="https://www.cubix.co/themes/responsiv-clean/assets/images/about/politico.png" alt="" />
                <img src="https://www.cubix.co/themes/responsiv-clean/assets/images/about/bigfish-logo.png" alt="" />
                <img src="https://www.cubix.co/themes/responsiv-clean/assets/images/about/politico.png" alt="" />
                <img src="https://www.cubix.co/themes/responsiv-clean/assets/images/about/bigfish-logo.png" alt="" />
                <img src="https://www.cubix.co/themes/responsiv-clean/assets/images/about/politico.png" alt="" />
                <img src="https://www.cubix.co/themes/responsiv-clean/assets/images/about/bigfish-logo.png" alt="" />
                <img src="https://www.cubix.co/themes/responsiv-clean/assets/images/about/politico.png" alt="" />
                <img src="https://www.cubix.co/themes/responsiv-clean/assets/images/about/bigfish-logo.png" alt="" />
              </div>
            </Col>
          </Row> */}
        </Container>
      </section>
      {/* Clients Section End */}
      {/* Office Videos Section */}
      {/* <section className="office-video-sec">
        <Container>
          <div className="main-heading">
            <h2 className="main-title">Live from our office</h2>
            <p className="description">Talent wins games, but teamwork and intelligence wins championships</p>
          </div>
          <Row className="video-items-wrap">
            <Col xs="12" md="4">
              <div className="video-item" onClick={()=> setOpen(true)}>
                <figure className="img-wrap">
                  <img src="images/team-3.png" alt="" />
                </figure>
                <div className="icon-wrap">
                  <span className="icon">
                    <svg height="20px" id="Layer_1" version="1.1" viewBox="0 0 512 512" width="20px">
                      <path d="M405.2,232.9L126.8,67.2c-3.4-2-6.9-3.2-10.9-3.2c-10.9,0-19.8,9-19.8,20H96v344h0.1c0,11,8.9,20,19.8,20  c4.1,0,7.5-1.4,11.2-3.4l278.1-165.5c6.6-5.5,10.8-13.8,10.8-23.1C416,246.7,411.8,238.5,405.2,232.9z"/>
                    </svg>
                  </span>
                </div>
              </div>
            </Col>
            <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="RJ-dcSDtDeE" onClose={() => setOpen(false)} />
            <Col xs="12" md="4">
              <div className="video-item">
                <figure className="img-wrap">
                  <img src="images/team-2.png" alt="" />
                </figure>
                <div className="icon-wrap">
                  <span className="icon">
                    <svg height="20px" id="Layer_1" version="1.1" viewBox="0 0 512 512" width="20px">
                      <path d="M405.2,232.9L126.8,67.2c-3.4-2-6.9-3.2-10.9-3.2c-10.9,0-19.8,9-19.8,20H96v344h0.1c0,11,8.9,20,19.8,20  c4.1,0,7.5-1.4,11.2-3.4l278.1-165.5c6.6-5.5,10.8-13.8,10.8-23.1C416,246.7,411.8,238.5,405.2,232.9z"/>
                    </svg>
                  </span>
                </div>
              </div>
            </Col>
            <Col xs="12" md="4">
              <div className="video-item">
                <figure className="img-wrap">
                  <img src="images/team-1.png" alt="" />
                </figure>
                <div className="icon-wrap">
                  <span className="icon">
                    <svg height="20px" id="Layer_1" version="1.1" viewBox="0 0 512 512" width="20px">
                      <path d="M405.2,232.9L126.8,67.2c-3.4-2-6.9-3.2-10.9-3.2c-10.9,0-19.8,9-19.8,20H96v344h0.1c0,11,8.9,20,19.8,20  c4.1,0,7.5-1.4,11.2-3.4l278.1-165.5c6.6-5.5,10.8-13.8,10.8-23.1C416,246.7,411.8,238.5,405.2,232.9z"/>
                    </svg>
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section> */}
      {/* Office Videos Section End */}
      {/* Testimonials Section */}
      <TestimonialsSection />
      {/* Testimonials Section End */}
    </div>
  )
};

export default About;
