/**
 *
 * Mobylogix
 * Author: Mobylogix
 * Email: info@mobylogix.com
 * Website: mobylogix.com
 * Version: 1.0
 * Date: Thu Nov 11 2021
 * Category: Pages
 * Title: DiscoverSection
 *
 */

import React from 'react';
import { Container, Row, Col } from 'reactstrap';

// styles
import './discover-section.css';

const DiscoverSection = (props) => (
  <div className="discover-section">
    <div className="half-content-wrap bg-base">
      <Container>
        <Row className="row-justify-center">
          <Col xs="12" md="7" lg="7">
            <div className="main-heading main-heading-left">
              <h2 className="main-title">Discover the world around you</h2>
              <p className="description">The unique feature of Curious is that it offers a ton of content based on the user's location. The geotagging attribute ensures that people only see posts of their immediate vicinity. For travelers, it is a way to share pictures of their adventures and for tourists to discover the town like a local. Furthermore, the app features a Visited Places tab as a memento to rediscover destinations where you traveled recently or years ago.</p>
              <p className="description">Furthermore, users can expand or limit the location coverage radius to see posts from near or far areas. It allows individuals to customize their feed and only receive updates on locations of their interests.</p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs="12" md="6" lg="4">
            <figure className="img-wrap">
              <img src="https://www.cubix.co/themes/responsiv-clean/assets/images/curious-portfolio/Curious-section-1mobile.png" alt="" />
            </figure>
          </Col>
          <Col xs="12" md="6" lg="4">
            <figure className="img-wrap">
              <img src="https://www.cubix.co/themes/responsiv-clean/assets/images/curious-portfolio/Curious-section-1mobile.png" alt="" />
            </figure>
          </Col>
          <Col xs="12" md="6" lg="4">
            <figure className="img-wrap">
              <img src="https://www.cubix.co/themes/responsiv-clean/assets/images/curious-portfolio/Curious-section-1mobile.png" alt="" />
            </figure>
          </Col>
        </Row>
      </Container>
    </div>
    <Container>
      <Row className="row-justify-center">
        <Col xs="12" md="7" lg="7">
          <div className="main-heading main-heading-left single-description">
            <p className="description">There are also groups to join and interact with others who share similar passions. Additionally, while Curious offers complete anonymity throughout, the Groups are a different story. The members' list displays the actual names instead of usernames, allowing individuals the opportunity to connect with people on a personal level. Curious is a social app that offers users the freedom to express themselves without fear of repercussions.</p>
          </div>
          <div className="main-heading main-heading-left">
            <h2 className="main-title">Curb your curiosity of the world</h2>
            <p className="description">For those curious about the latest happenings or want quick updates on the nearest trendy locations, Curious is the app that offers a phenomenal experience. It has an intuitive UI and easy navigation from one screen to the next. It provides separate logins and profiles, customizable feed, and multiple communities to explore and discuss shared interests.</p>
            <p className="description">The app is a freemium model, meaning, for some features, users would need to get a VIP membership or purchase coins from the in-app store. So, not only does Curious allows users to increase their social network, but it also provides a chance to stay on top of the latest activities in the area.</p>
          </div>
        </Col>
      </Row>
    </Container>
    <div className="big-img"></div>
  </div>
);

DiscoverSection.propTypes = {};

export default DiscoverSection;
