/**
 *
 * Mobylogix
 * Author: Mobylogix
 * Email: info@mobylogix.com
 * Website: mobylogix.com
 * Version: 1.0
 * Date: Mon Nov 15 2021
 * Category: Pages
 * Title: WorkBanner
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

// styles
import './work-banner.css';

const WorkBanner = (props) => (
  <div className="work-banner" style={{ backgroundImage: `url('/images/portfolio.jpg')` }}>
    <div className="main-heading">
      <h2 className="main-title wow fadeInDown">Our portfolio</h2>
      <p className="description wow fadeInDown">We help our clients grow with cutting-edging technologies, extravagant designs, <span className="d-block">and innovative software solutions.</span></p>
    </div>
    <a href="#Beninga">
      <span className="icon-wrap">
        <img src="https://img.icons8.com/ios-glyphs/30/000000/expand-arrow--v1.png"/>
      </span>
    </a>
  </div>
);

WorkBanner.propTypes = {};

export default WorkBanner;
