/**
 *
 * Mobylogix
 * Author: Mobylogix
 * Email: info@mobylogix.com
 * Website: mobylogix.com
 * Version: 1.0
 * Date: Tue Nov 09 2021
 * Category: Pages
 * Title: MobileAppsDevelopment
 *
 */

import React, { useState, useEffect } from 'react';
import Slider from "react-slick";
import { useCountUp } from 'react-countup';
import { Container, Row, Col, TabContent, TabPane, Nav, NavItem, NavLink,  } from 'reactstrap';
import classnames from 'classnames';

// util
import { androidstudio, unity, arkit, arcore, mysql, sqlitevr, javavr, javascriptvr, csharp  } from "../../utils/technolgy";
import { VirtualRealityMeta } from 'utils/metas';

// Components
import InnerBanner from 'components/InnerBanner';
import DevelopmentServices from 'components/DevelopmentServices';
import CreativeSection from 'components/CreativeSection';
import ProcessSection from 'components/ProcessSection';
import TechnologyStackSection from 'components/TechnologyStackSection';
import InsightsSection from 'components/InsightsSection';
import HighlightsSection from 'components/HighlightsSection';
import TestimonialsSection from 'components/TestimonialsSection';
import AwardsSection from 'components/AwardsSection';
import ProjectForm from 'components/ProjectForm';
import FeaturedWork from 'components/FeaturedWork';

const VirtualRealityAppsDevelopment = (props) => {
  // const totalHeight = document.getElementsByClassName('mobile-app-development')[0].offsetHeight;

  // useEffect(() => {
  //   let height = document.getElementsByClassName('mobile-app-development')[0].offsetHeight;

  //   console.log('height', height);

  //   const calcHeight = (height / 100) * 75;

  //   console.log('height1', height);

  //   if (calcHeight > window.scrollY) {

  //   }
  // });

  const [activeTab, setActiveTab] = useState('1');

  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }

  const settings = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  return (
    <div className="inner-page mobile-app-development">
      {/* Meta */}
      <VirtualRealityMeta />
      {/* Meta End */}
      {/* Inner Banner */}
      <InnerBanner title={"Delivering Purpose-Driven Augmented Reality Solutions"} description={"Overcome industry hurdles with Virtual Reality Development that offers image-oriented scenarios within real settings."} />
      {/* Inner Banner End */}
      {/* Development Services */}
      <DevelopmentServices title={"AR/VR development services"} description={"So many industries are enjoying such dramatic success with AR/VR, it’s very easy to get excited about what’s possible. Now it’s up to you to visualize just how these technologies can overpass the physical and digital worlds — and for Mobylogix to bring that vision to life. We develop high tech AR/VR solutions across industries, applications and platforms use cases. We have helped businesses to accelerate engagement and transform complex processes into seamless immersive digital experiences."} />
      {/* Development Services End */}
      {/* Creative Section */}
      <CreativeSection 
        title={"Offering purpose-driven virtual reality solutions"}
        description={"Virtual reality development offers image-centric scenarios within real settings that help you in overcoming the challenges of the industry."}
      />
      {/* Creative Section End */}
      {/* Featured Work Slider */}
      <FeaturedWork />
      {/* Featured Work Slider End */}
      {/* Caption Section */}
      {/* <section className="deve-caption-wrap deve-caption-primary">
        <Container>
          <Row className="row-justify-center">
            <Col xs="12" md="9" lg="7">
              <div className="main-heading">
                <h2 className="main-title">Work with a globally-recognized mobile app development company</h2>
                <p className="description">We are the leading mobile app development company in the USA providing apps to power the visibility and mobility of your business. As an expert in mobile application development services in the USA, we have been creating feature-packed, high-performance mobile applications for all types of businesses and industries.</p>
                <p className="description">We are recognized as one of the world’s most desired mobile app development companies. Our mobile app developers, strategists, and designers are fueled with creativity and passion for app design. This is why our mobile app development services are recognized among the best in the industry.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section> */}
      {/* Caption Section End */}
      {/* Caption Section */}
      {/* <section className="deve-caption-wrap">
        <Container>
          <Row className="row-justify-center">
            <Col xs="12" md="9" lg="7">
              <div className="main-heading">
                <h2 className="main-title">Mobile app services the Mobylogix way</h2>
                <p className="description">Whether you are a startup, a small-scale business or an enterprise looking to build a mobile app, our development process remains the same. We convert your ideas into successful mobile apps that become an integral part of everyone’s lives. With our fast and smooth app development process, the apps we build are user-friendly, robust, engaging, and well-crafted.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section> */}
      {/* Caption Section End */}
      {/* Caption Section */}
      <section className="deve-caption-wrap deve-caption-primary">
        <Container>
          <Row className="row-justify-center">
            <Col xs="12" md="9" lg="7">
              <div className="main-heading">
                <h2 className="main-title">Renowned Augmented reality (AR) app development</h2>
                <p className="description">In the next 12 months, AR/VR users will number around 1.96 billion worldwide. During this  period, AR/VR users will account for 85 million in the US. By 2023, Virtual reality (AR/VR) users will be around 2.4 billion.  Recognized among leading mobile app development companies, Mobylogix builds thriving virtual reality solutions. With experienced and highly skilled developers, designers, and strategists, the company leads the way in AR/VR tech and also a variety of mobile applications development solutions.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Caption Section End */}
      {/* Process Section */}
      <ProcessSection />
      {/* Process Section End */}
      {/* Caption Section */}
      {/* <section className="deve-caption-wrap deve-caption-primary">
        <Container>
          <Row className="row-justify-center">
            <Col xs="12" md="9" lg="7">
              <div className="main-heading">
                <h2 className="main-title">World-famous mobile application development company</h2>
                <p className="description">Mobile apps indeed hold a significant chunk of the market, currently (about 58%), making it important for your business to invest in one. If you have a wide customer base from your e-commerce website, imagine the power of pairing that with a mobile app!</p>
                <p className="description">Mobylogix is a popular mobile apps development company that helps you achieve your goals. We examine your particular need and work to develop your app solution to perfection! And it all starts with a rock-solid plan that not only sets the parameters of your project features, but also minutely defines and strategizes the length and breadth of programming required. This concrete plan is matched against a strict timeline to ensure delivery on time.</p>
                <p className="description">Every customer is important to us, as we believe in taking everyone who approaches us to the next level. So, no matter what kind of app you are desirous of building; we can build anything for you from scratch.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section> */}
      {/* Caption Section End */}
      {/* Technology Section */}
      <div className="technology-stack-section">
        <Container>
          <div className="main-heading main-heading-left">
            <h2 className="main-title"> Virtual Reality development – technology stack</h2>
            <p className="description">We create VirtualReality with scalable and tested technology stack to deliver par excellence.</p>
          </div>
          <Row className="row-holder">
            <Col xs="12" lg="5">
            <h3 className="title">VirtualReality</h3>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '1' })}
                    onClick={() => { toggle('1'); }}
                  >
                    <img src="/images/Augmented Reality/android-studio.png" alt="" />
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '2' })}
                    onClick={() => { toggle('2'); }}
                  >
                    <img src="/images/Augmented Reality/arcore.png" alt="" />
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '3' })}
                    onClick={() => { toggle('3'); }}
                  >
                    <img src="/images/Augmented Reality/arkit.png" alt="" />
                  </NavLink>
                </NavItem>
              </Nav>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '4' })}
                    onClick={() => { toggle('4'); }}
                  >
                    <img src="/images/Augmented Reality/unity3d.png" alt="" />
                  </NavLink>
                </NavItem>
              </Nav>
              <h3 className="title">Language</h3>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '5' })}
                    onClick={() => { toggle('5'); }}
                  >
                    <img src="/images/Augmented Reality/csharp.png" alt="" />
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '6' })}
                    onClick={() => { toggle('6'); }}
                  >
                    <img src="/images/Augmented Reality/java.png" alt="" />
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '7' })}
                    onClick={() => { toggle('7'); }}
                  >
                    <img src="/images/Augmented Reality/java-script.png" alt="" />
                  </NavLink>
                </NavItem>
              </Nav>
             
              <h3 className="title">Database</h3>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '8' })}
                    onClick={() => { toggle('8'); }}
                  >
                    <img src="/images/Augmented Reality/mysql.png" alt="" />
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '9' })}
                    onClick={() => { toggle('9'); }}
                  >
                    <img src="/images/Augmented Reality/sqlite.png" alt="" />
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
            <Col xs="12" lg="7">
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <div className="tabs-content-caption">
                    <div className="title-wrap">
                      <img src="/images/Augmented Reality/android-studio.png" alt="" className="icon" />
                      <h3 className="title">{androidstudio.title}</h3>
                    </div>
                    <p className="description">{androidstudio.description}</p>
                  </div>
                </TabPane>
                <TabPane tabId="2">
                  <div className="tabs-content-caption">
                    <div className="title-wrap">
                      <img src="/images/Augmented Reality/arcore.png" alt="" className="icon" />
                      <h3 className="title">{arcore.title}</h3>
                    </div>
                    <p className="description">{arcore.description}</p>
                  
                  </div>
                </TabPane>
                <TabPane tabId="3">
                  <div className="tabs-content-caption">
                    <div className="title-wrap">
                      <img src="/images/Augmented Reality/arkit.png" alt="" className="icon" />
                      <h3 className="title">{arkit.title}</h3>
                    </div>
                    <p className="description">{arkit.description}</p>
                  </div>
                </TabPane>
                <TabPane tabId="4">
                  <div className="tabs-content-caption">
                    <div className="title-wrap">
                      <img src="/images/Augmented Reality/unity3d.png" alt="" className="icon" />
                      <h3 className="title">{unity.title}</h3>
                    </div>
                    <p className="description">{unity.description}</p>
                  </div>
                </TabPane>
                <TabPane tabId="5">
                  <div className="tabs-content-caption">
                    <div className="title-wrap">
                      <img src="/images/Augmented Reality/csharp.png" alt="" className="icon" />
                      <h3 className="title">{csharp.title}</h3>
                    </div>
                    <p className="description">{csharp.description}</p>
                  </div>
                </TabPane>
                <TabPane tabId="6">
                  <div className="tabs-content-caption">
                    <div className="title-wrap">
                      <img src="/images/Augmented Reality/java.png" alt="" className="icon" />
                      <h3 className="title">{javavr.title}</h3>
                    </div>
                    <p className="description">{javavr.description}</p>
                  </div>
                </TabPane>
                <TabPane tabId="7">
                  <div className="tabs-content-caption">
                    <div className="title-wrap">
                      <img src="/images/Augmented Reality/java-script.png" alt="" className="icon" />
                      <h3 className="title">{javascriptvr.title}</h3>
                    </div>
                    <p className="description">{javascriptvr.description}</p>
                  </div>
                </TabPane>
                <TabPane tabId="8">
                  <div className="tabs-content-caption">
                    <div className="title-wrap">
                      <img src="/images/Augmented Reality/mysql.png" alt="" className="icon" />
                      <h3 className="title">{mysql.title}</h3>
                    </div>
                    <p className="description">{mysql.description}</p>
                  </div>
                </TabPane>
                <TabPane tabId="9">
                  <div className="tabs-content-caption">
                    <div className="title-wrap">
                      <img src="/images/Augmented Reality/sqlite.png" alt="" className="icon" />
                      <h3 className="title">{sqlitevr.title}</h3>
                    </div>
                    <p className="description">{sqlitevr.description}</p>
                  </div>
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </Container>
      </div>
      {/* Technology Section End */}
      {/* Insights Section */}
      {/* <InsightsSection /> */}
      {/* Insights Section End */}
      {/* Highlight Section */}
      <HighlightsSection />
      {/* Highlight Section End */}
      {/* Testimonials Section */}
      <TestimonialsSection />
      {/* Testimonials Section End */}
      {/* Awards Section */}
      <AwardsSection />
      {/* Awards Section End */}
      {/* Project Form */}
      <ProjectForm />
      {/* Project Form End */}
    </div>
  )
};

export default VirtualRealityAppsDevelopment;

