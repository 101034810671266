/**
 *
 * Mobylogix
 * Author: Mobylogix
 * Email: info@mobylogix.com
 * Website: mobylogix.com
 * Version: 1.0
 * Date: Wed Nov 17 2021
 * Category: Pages
 * Title: AboutBanner
 *
 */

import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';

// styles
import './about-banner.css';

const AboutBanner = ({ title, description, btnText }) => (
  <div className="about-banner">
    <Container>
      <Row>
        <Col xs='24' lg='6'>
          <div className="about-banner-caption">
            <h1 className="title wow fadeInUp">{title}</h1>
            <p className="description wow fadeInUp">{description}</p>
            <Button onClick={() => window.location.href = '/contact'} color="primary" className="wow fadeInUp">{btnText}</Button>
          </div>
        </Col>
      </Row>
    </Container>
  </div>
);

AboutBanner.propTypes = {};

export default AboutBanner;
