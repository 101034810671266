/**
 *
 * Mobylogix
 * Author: Mobylogix
 * Email: info@mobylogix.com
 * Website: mobylogix.com
 * Version: 1.0
 * Date: Mon Nov 15 2021
 * Category: Pages
 * Title: SolutionsBanner
 *
 */

import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import Slider from "react-slick";

// styles
import './solutions-banner.css';

const SolutionsBanner = (props) => {
  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  return (
    <div className="solutions-banner">
      <Container>
        <Row>
          <Col xs='24' lg='6'>
            <div className="solutions-banner-caption">
              <h1 className="title wow fadeInUp">Happyforce - a powerful <span className="d-block">solution for all your HR</span> needs</h1>
              <p className="description wow fadeInUp">Data-driven HR management focused on people. Engage with your employees and evaluate their performance accordingly.</p>
              <Button color="primary" className="wow fadeInUp">Get In Touch</Button>
              <a href="/" rel="nooppener noreferrer" className="link wow fadeInUp">
                <span className="icon-wrap">
                  <svg version="1.1" width="60px" height="80px" x="0px" y="0px" viewBox="0 0 600 800" enable-background="new 0 0 600 800" ><path d="M0-1.79v800L600,395L0-1.79z"></path></svg>
                </span>
                <span className="link-text">Play Now</span>
              </a>
            </div>
          </Col>
          <Col xs='24' lg='6'>
            <Slider {...settings} className="solutions-banner-slider wow fadeInDown">
              <div className="solutions-banner-slider-item">
                <figure className="slider-img-wrap">
                  <img src="https://www.cubix.co/themes/responsiv-clean/assets/images/cubix-happyforce/cubix-happyforce-banner-slider01.png" alt="" />
                </figure>
              </div>
              <div className="solutions-banner-slider-item">
                <figure className="slider-img-wrap">
                  <img src="https://www.cubix.co/themes/responsiv-clean/assets/images/cubix-happyforce/cubix-happyforce-banner-slider02.png" alt="" />
                </figure>
              </div>
            </Slider>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

SolutionsBanner.propTypes = {};

export default SolutionsBanner;
