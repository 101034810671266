/**
 *
 * Mobylogix
 * Author: Mobylogix
 * Email: info@mobylogix.com
 * Website: mobylogix.com
 * Version: 1.0
 * Date: Tue Nov 09 2021
 * Category: Pages
 * Title: MobileAppsDevelopment
 *
 */

import React, { useState, useEffect } from 'react';
import Slider from "react-slick";
import { useCountUp } from 'react-countup';
import { Container, Row, Col, TabContent, TabPane, Nav, NavItem, NavLink,  } from 'reactstrap';
import classnames from 'classnames';

// util
import { aion, bitcoin, cardano, ethereum, neblio, quorum, ripple } from "../../utils/technolgy";
import { BlockchainMeta } from 'utils/metas';

// Components
import InnerBanner from 'components/InnerBanner';
import DevelopmentServices from 'components/DevelopmentServices';
import CreativeSection from 'components/CreativeSection';
import ProcessSection from 'components/ProcessSection';
import TechnologyStackSection from 'components/TechnologyStackSection';
import InsightsSection from 'components/InsightsSection';
import HighlightsSection from 'components/HighlightsSection';
import TestimonialsSection from 'components/TestimonialsSection'; 
import AwardsSection from 'components/AwardsSection';
import ProjectForm from 'components/ProjectForm';
import FeaturedWork from 'components/FeaturedWork';

const BlockchainAppsDevelopment = (props) => {
  // const totalHeight = document.getElementsByClassName('mobile-app-development')[0].offsetHeight;

  // useEffect(() => {
  //   let height = document.getElementsByClassName('mobile-app-development')[0].offsetHeight;

  //   console.log('height', height);

  //   const calcHeight = (height / 100) * 75;

  //   console.log('height1', height);

  //   if (calcHeight > window.scrollY) {

  //   }
  // });

  const [activeTab, setActiveTab] = useState('1');

  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }

  const settings = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  return (
    <div className="inner-page mobile-app-development">
      {/* Meta */}
      <BlockchainMeta />
      {/* Meta End */}
      {/* Inner Banner */}
      <InnerBanner title={"BLOCKCHAIN DEVELOPMENT"} description={"Increase Traceability & Accountability With Our Enterprise-Level Blockchain Solutions"} />
      {/* Inner Banner End */}
      {/* Development Services */}
      <DevelopmentServices title={"Our blockchain solutions"} description={"Enjoy heightened security, Transparency, and efficiency. Gain the best in blockchain integration and adaptation"}/>
      {/* Development Services End */}
      {/* Creative Section */}
      <CreativeSection 
        title={"Blockchain Development Services"}
        description={"Blockchain development platform helps you in enhancing accountability and traceability."}
      />
      {/* Creative Section End */}
      {/* Featured Work Slider */}
      <FeaturedWork />
      {/* Featured Work Slider End */}
      {/* Caption Section */}
      <section className="deve-caption-wrap deve-caption-primary">
        <Container>
          <Row className="row-justify-center">
            <Col xs="12" md="9" lg="7">
              <div className="main-heading">
                <h2 className="main-title">Why choose us</h2>
                <p className="description">Boost Operational Efficiency, Security and Traceability With Our Robust Blockchain Solutions.</p>
                <p className="description">We are a well-known blockchain development services provider in USA that provides support and enhancement to your blockchain infrastructure resources. Moreover, our skilled developers can design and develop simple to complex blockchain solutions to help you manage your business as per modern-day standards. We believe in building a transparent relationship with our client so we deliver solutions that are up to the mark and error-free.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Caption Section End */}
      {/* Caption Section */}
      <section className="deve-caption-wrap">
        <Container>
          <Row className="row-justify-center">
            <Col xs="12" md="9" lg="7">
              <div className="main-heading">
                <h2 className="main-title">Our process</h2>
                <p className="description">Our refined process - We run rapid tests and achieve product maturity at the earliest stages to convert your ideas into extensible and robust applications. Our process is optimized to meet existing blockchain industry changes.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Caption Section End */}
      {/* Caption Section */}
      <section className="deve-caption-wrap deve-caption-primary">
        <Container>
          <Row className="row-justify-center">
            <Col xs="12" md="9" lg="7">
              <div className="main-heading">
                <h2 className="main-title">Blockchain the future of hybrid projects</h2>
                <p className="description">Security is always the number one reason for enterprises for blockchain solutions. A crucial pain point when it comes to the future of blockchain usage is expandability. Hybrid solution’s Idea can solve many issues related to scalability, a traditionally known restriction. </p>
                <p className="description">Fully functional hybrid blockchain seamlessly linking a public chain with a private network is a huge achievement. A hybrid blockchain made up a permissioned blockchain with a public blockchain, allowing entities to enjoy secure background transactions with associates while allowing consumers to access product information on an open ledger.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Caption Section End */}
      {/* Process Section */}
      <ProcessSection />
      {/* Process Section End */}
      {/* Caption Section */}
      <section className="deve-caption-wrap deve-caption-primary">
        <Container>
          <Row className="row-justify-center">
            <Col xs="12" md="9" lg="7">
              <div className="main-heading">
                <h2 className="main-title">Top Rated Blockchain Development Company in USA</h2>
                <p className="description">Blockchain has attracted the attention of many businesses, especially in the technological world, and why not? It supports a wide range of platforms: Cryptocurrency, Bitcoin. Mobylogix, being a goal-oriented and well-established blockchain development company, specializes in creating smart blockchain solutions for various industrial verticals.</p>
                <p className="description">We are a team of highly-skilled engineers with a primary focus on developing advanced Blockchain solutions to speed up your business processes, make them robust, secure, and lower down the costs. Further, our QA Testing Services will ensure if these blockchain solutions have resistance against hacking or manipulations.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Caption Section End */}
      {/* Technology Section */}
      <div className="technology-stack-section">
        <Container>
          <div className="main-heading main-heading-left">
            <h2 className="main-title">Blockchain development – technology stack</h2>
            <p className="description">We create Blockchain with scalable and tested technology stack to deliver apps par excellence.</p>
          </div>
          <Row className="row-holder">
            <Col xs="12" lg="5">
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '1' })}
                    onClick={() => { toggle('1'); }}
                  >
                    <img src="/images/Blockchain Development/aion.png" alt="" />
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '2' })}
                    onClick={() => { toggle('2'); }}
                  >
                    <img src="/images/Blockchain Development/bitcoin.png" alt="" />
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '3' })}
                    onClick={() => { toggle('3'); }}
                  >
                    <img src="/images/Blockchain Development/cardano.png" alt="" />
                  </NavLink>
                </NavItem>
              </Nav>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '4' })}
                    onClick={() => { toggle('4'); }}
                  >
                    <img src="/images/Blockchain Development/ethereum.png" alt="" />
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '5' })}
                    onClick={() => { toggle('5'); }}
                  >
                    <img src="/images/Blockchain Development/neblio.png" alt="" />
                  </NavLink>
                </NavItem>
              </Nav>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '6' })}
                    onClick={() => { toggle('6'); }}
                  >
                    <img src="/images/Blockchain Development/quorum.png" alt="" />
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '7' })}
                    onClick={() => { toggle('7'); }}
                  >
                    <img src="/images/Blockchain Development/ripple.png" alt="" />
                  </NavLink>
                </NavItem>
              </Nav>
          
            </Col>
            <Col xs="12" lg="7">
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <div className="tabs-content-caption">
                    <div className="title-wrap">
                      <img src="/images/Blockchain Development/aion.png" alt="" className="icon" />
                      <h3 className="title">{aion.title}</h3>
                    </div>
                    <p className="description">{aion.description}</p>
                  </div>
                </TabPane>
                <TabPane tabId="2">
                  <div className="tabs-content-caption">
                    <div className="title-wrap">
                      <img src="/images/Blockchain Development/bitcoin.png" alt="" className="icon" />
                      <h3 className="title">{bitcoin.title}</h3>
                    </div>
                    <p className="description">{bitcoin.description}</p>
                  
                  </div>
                </TabPane>
                <TabPane tabId="3">
                  <div className="tabs-content-caption">
                    <div className="title-wrap">
                      <img src="/images/Blockchain Development/cardano.png" alt="" className="icon" />
                      <h3 className="title">{cardano.title}</h3>
                    </div>
                    <p className="description">{cardano.description}</p>
                  </div>
                </TabPane>
                <TabPane tabId="4">
                  <div className="tabs-content-caption">
                    <div className="title-wrap">
                      <img src="/images/Blockchain Development/ethereum.png" alt="" className="icon" />
                      <h3 className="title">{ethereum.title}</h3>
                    </div>
                    <p className="description">{ethereum.description}</p>
                  </div>
                </TabPane>
                <TabPane tabId="5">
                  <div className="tabs-content-caption">
                    <div className="title-wrap">
                      <img src="/images/Blockchain Development/neblio.png" alt="" className="icon" />
                      <h3 className="title">{neblio.title}</h3>
                    </div>
                    <p className="description">{neblio.description}</p>
                  </div>
                </TabPane>
                <TabPane tabId="6">
                  <div className="tabs-content-caption">
                    <div className="title-wrap">
                      <img src="/images/Blockchain Development/quorum.png" alt="" className="icon" />
                      <h3 className="title">{quorum.title}</h3>
                    </div>
                    <p className="description">{quorum.description}</p>
                  </div>
                </TabPane>
                <TabPane tabId="7">
                  <div className="tabs-content-caption">
                    <div className="title-wrap">
                      <img src="/images/Blockchain Development/ripple.png" alt="" className="icon" />
                      <h3 className="title">{ripple.title}</h3>
                    </div>
                    <p className="description">{ripple.description}</p>
                  </div>
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </Container>
      </div>
      {/* Technology Section End */}
      {/* Insights Section */}
      {/* <InsightsSection title="Key Highlights our work in numbers." description="Build your web presence to thrive just like many others have, and let experienced technicians and digital creators develop functionalities that rivet your target audience." /> */}
      {/* Insights Section End */}
      {/* Highlight Section */}
      <HighlightsSection />
      {/* Highlight Section End */}
      {/* Testimonials Section */}
      <TestimonialsSection />
      {/* Testimonials Section End */}
      {/* Awards Section */}
      <AwardsSection />
      {/* Awards Section End */}
      {/* Project Form */}
      <ProjectForm />
      {/* Project Form End */}
    </div>
  )
};


export default BlockchainAppsDevelopment;

