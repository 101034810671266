/**
 *
 * Mobylogix
 * Author: Mobylogix
 * Email: info@mobylogix.com
 * Website: mobylogix.com
 * Version: 1.0
 * Date: Tue Nov 09 2021
 * Category: Pages
 * Title: MobileAppsDevelopment
 *
 */

import React from 'react';
import { Container, Row, Col } from 'reactstrap';

// util
import { PrivacyPolicyMeta } from 'utils/metas';

// Components
import InnerBanner from 'components/InnerBanner';
 
const PrivacyPolicy = (props) => (
  <div className="inner-page privacy-policy">
    {/* Meta */}
    <PrivacyPolicyMeta />
    {/* Meta End */}
    {/* Inner Banner */}
    <InnerBanner title={"Explore our operational agile methods"} description={"Build and launch solutions quickly. — With us grow your business Successfully."} />
    {/* Inner Banner End */}
    <Container>
      <Row>
        <Col xs='12'>
          <article>
            <p>This Privacy Policy has been prepared by Mobylogix regarding Personal data collected on or through our website (“Site”). 
              All capitalized terms shall have the meaning defined herein.
            </p>
            <h3>Personal data</h3>
            <p>Should You, the user, voluntarily choose to register with Mobylogix, we will use your personal data in order to improve our services, otherwise set forth in this Privacy Policy and in any applicable service-specific agreement, such as the Terms and Conditions 
              for Publishers or the Terms and Conditions for Advertisers. We may collect personal data such as first name, last name,
              address, email address, telephone number, birth date, billing and credit card information.
            </p>
            <h3>Use of personal data</h3>
            <p>Mobylogix will use personal data provided by You keeping consistent with this Privacy Policy or with any applicable service-specific
            agreement. We will use personal data that You provide only for the particular reason it was provided for. An example would be
            personal data collected when You register or gain access to the Site. We and our affiliates also use your personal data and
            other personally non-identifiable information, which is made available to us through the Site to help us improve the content,
            functionality, and our services, for a better user experience.
            </p>
            <h3>Personally non-identifiable information</h3>
            <p>Personally non-identifiable information provided by You at the time of registration, or when You choose to use some of our
            services, will not, by itself, be sufficient to identify or contact you. The nature of such information may be, but not restricted to,
            demographics, age, education level, profession, gender, etc. Not only may Mobylogix collect this information, it may also be
            included in databases owned and maintained by Mobylogix’s affiliates, agents or service providers. Mobylogix may pool this with other
            information to track, for example, the total number of visitors to our Site and the domain names of our visitors’ Internet service
            providers. It is important to note that we don’t use personal data available in this process.</p>
            <h3>Passively collected information</h3>
            <p>We receive and stored certain additional personally non-identifiable information provided by You through the SIte. This
            passively collected information, cannot, at present, be used to identify you. Such information may include, but not be restricted
            to IP addresses, browser types, domain names, other anonymous statistical data, etc., involving your use of the Site and/or our
            services.</p>
            <h3>Aggregated personal data</h3>
            <p>Mobylogix may aggregate the personal data that is provided to the Site by You. This is conducted in an effort to better understand
            our users. The aggregated information does not identify You personally. This aggregated data may be shared with its affiliates,
            agents and business partners. These aggregated user statistics may be shared so that our current and prospective business
            partners understand our services. We may also share this data with third parties for lawful purposes.</p>
            <h3>Cookies</h3>
            <p>We may use “cookies” on this Site. A cookie is a piece of information sent from our Site and stored on your computer by the
            user’s web browser while browsing. Cookies help provide additional functionality to the Site and help us analyze Site usage
            more accurately.</p>
            <h3>Sharing information</h3>
            <p>Certain circumstances may require Mobylogix to share your personal data with certain third parties without notice to You. This may
              include:</p>
            <p>Agents, Consultants and Related Third Parties: Mobylogix may employ other companies to perform certain business-related
            functions. This could include mailing information, maintaining databases and processing payments. We only provide these
            companies information they need to perform their specific function.</p>
            <p>Business Partners: We may aggregate your personal data along with personal data of other users. This information is used in a
            non-personally identifiable manner to advertisers and other third parties for marketing and promotional purposes. We will not
            disclose information that could be used to identify You personally to our business partners.</p>
            <p>Legal Requirements: If needed, Mobylogix may disclose your personal data by law or in good faith that such action is necessary to
            (a) comply with a legal obligation, (b) protect and defend the rights or property of Mobylogix, (c) act in urgent circumstances to
            protect the personal safety of users of the Site or the public, or (d) protect against legal liability.</p>
            <p>Business Transfers: Buying or selling businesses or assets is a part of any business, and we are no different. If we affect a
            corporate sale, merger, reorganization, dissolution or similar event, personal data may be part of the transferred assets.
            </p>
            <h3>Minor</h3>
            <p>Mobylogix does not knowingly collect personal data from minors under the age of 18 through this Site. If You are under 18, please
            do not give us any personal data.</p>
            <h3>Links to other web sites</h3>
            <p>This Privacy Policy is only applicable to this Site and no third-party sites.</p>
            <h3>Security</h3>
            <p>Mobylogix protects personal data provided to this Site from loss, misuse, unauthorized access, disclosure, alteration, or destruction.
            As the Internet is not a secure environment and no e-mail transmission is fully error free, You should be careful what
            information is sent to us via e-mail.</p>
            <h3>Other terms and conditions</h3>
            <p>Mobylogix reserves the right to update or modify this Privacy Policy at any time and without prior notice. We advise You to review
            this policy periodically, especially before providing any personal data. With your continued use of the Site or our services
            following any modifications or revisions of this Privacy Policy it shall be deemed that you agree with the terms of the revised
            Privacy Policy.</p>
            <h3>Access to information</h3>
            <p>It is our policy to keep the information provided by You in the form of personal data as accurate, current, and complete. If You
            find the information is inaccurate or not up to date, please contact us as specified below. We will update or correct personal
            data in our possession previously submitted by You on this Site.
            </p>
            <h3>Opt-out policy</h3>
            <p>In case, after registering, You don’t want to receive any information from us or You have any questions about the use of
            information provided by You, please contact us as specified below.
            </p>
            <h3>Update your information</h3>
            <p>In case, after registering, You want to modify your personally identifiable information previously provided, please contact us as
            specified below. We will respond to any such update or request within thirty (30) days of receiving the same.</p>
            <h3>How to contact us</h3>
            <p>Do get in touch with us if You have any questions regarding this Privacy Policy or collection of personal data from this Site.
            Please contact us by going to https://www.mobylogix.com/contact and completing the online email form.</p>
          </article>
        </Col>
      </Row>
    </Container>
  </div>
);
 
export default PrivacyPolicy;
 