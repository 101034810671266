/**
 *
 * Mobylogix
 * Author: Mobylogix
 * Email: info@mobylogix.com
 * Website: mobylogix.com
 * Version: 1.0
 * Date: Tue Sep 28 2021
 * Category: Pages
 * Title: WhyUsSection
 *
 */

import React from 'react';
import { Container, Row, Col } from 'reactstrap';

// Components
import AwardsBlockchainIcon from 'icons/AwardsBlockchainIcon';
import BlockchainIcon from 'icons/BlockchainIcon';
import MetaverseIcon from 'icons/MetaverseIcon';
import MobileAppIcon from 'icons/MobileAppIcon';
import WebDevelopmentIcon from 'icons/WebDevelopmentIcon';

// styles
import './why-us-section.css';

const WhyUsSection = (props) => (
  <section className="why-us-section">
    <Container>
      {/* Main Heading */}
      <Row className="row-justify-center">
        <Col xs='12' sm='10' md='9' lg='9'>
          <div className="main-heading">
            <strong className="sub-title wow fadeInUp">DESIGN. DEVELOPMENT. CONSULTANCY.</strong>
            <h3 className="main-title wow fadeInUp">Develop your results-driven products for entrepreneurs, startups, and enterprises with a leading software engineering and marketing company.</h3>
          </div>
        </Col>
      </Row>
      {/* Main Heading */}
      <Row>
        <Col xs='12' sm='6' md='6' lg='3'>
          <div className="why-us-card wow fadeInUp">
            <WebDevelopmentIcon extraClass='icon' />
            <h4 className="title">Web Development</h4>
          </div>
        </Col>
        <Col xs='12' sm='6' md='6' lg='3'>
          <div className="why-us-card wow fadeInUp">
            <MetaverseIcon extraClass='icon' />
            <h4 className="title">Virtual Reality</h4>
          </div>
        </Col>
        <Col xs='12' sm='6' md='6' lg='3'>
          <div className="why-us-card wow fadeInUp">
            <BlockchainIcon extraClass='icon' /> 
            <h4 className="title">Blockchain Development</h4>
          </div>
        </Col>
        <Col xs='12' sm='6' md='6' lg='3'>
          <div className="why-us-card wow fadeInUp">
            <MobileAppIcon extraClass='icon' />
            <h4 className="title">App Development</h4>
          </div>
        </Col>
      </Row>
    </Container>
  </section>
);

WhyUsSection.propTypes = {};

export default WhyUsSection;
