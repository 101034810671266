/**
 *
 * Mobylogix
 * Author: Mobylogix
 * Email: info@mobylogix.com
 * Website: mobylogix.com
 * Version: 1.0
 * Date: Thu Nov 11 2021
 * Category: Pages
 * Title: InnerHighlightsSection
 *
 */

import React from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';

// styles
import './inner-highlights-section.css';

const InnerHighlightsSection = (props) => {
  let widgets = [
    {
      icon: <PhoneIcon />,
      title: 'User Profile',
      description: 'Sign up with a social account or email ID to register with Curious and discover the world around you.'
    },
    {
      icon: <PhoneIcon />,
      title: 'User Profile',
      description: 'Sign up with a social account or email ID to register with Curious and discover the world around you.'
    },
    {
      icon: <PhoneIcon />,
      title: 'User Profile',
      description: 'Sign up with a social account or email ID to register with Curious and discover the world around you.'
    },
    {
      icon: <PhoneIcon />,
      title: 'User Profile',
      description: 'Sign up with a social account or email ID to register with Curious and discover the world around you.'
    },
    {
      icon: <PhoneIcon />,
      title: 'User Profile',
      description: 'Sign up with a social account or email ID to register with Curious and discover the world around you.'
    },
    {
      icon: <PhoneIcon />,
      title: 'User Profile',
      description: 'Sign up with a social account or email ID to register with Curious and discover the world around you.'
    }
  ];

  return (
    <div className="inner-highlights-section">
      <Container>
        <Row className="row-justify-center">
          <Col xs="12" md="7" lg="7">
            <div className="main-heading main-heading-left">
              <h2 className="main-title">Curious highlights</h2>
              <p className="description">Curious Highlights Curious is a social platform that offers users complete anonymity to express their thoughts freely. It is the embodiment of the statement, 'freedom of speech.'</p>
              <p className="description">The app offers multiple features for phenomenal user experience. From separate user logins to geotagging on posts, in-app chat, navigation, and more are integrated within the app. Individuals can find places to visit in their vicinity or discover the most happening events. Users can also find groups and connect with individuals around the world with similar interests.</p>
            </div>
          </Col>
        </Row>
        <Row className="row-justify-center">
          <Col xs="12" md="8" lg="8">
            <Row>
              {widgets.map((widget, i) => (
                <Col xs="12" md="6" lg="4" key={i}>
                  <Card className="highlights-card">
                    <CardBody>
                      {widget.icon}
                      <h3 className="title">{widget.title}</h3>
                      <p className="description">{widget.description}</p>
                    </CardBody>
                  </Card>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  )
};

InnerHighlightsSection.propTypes = {};

export default InnerHighlightsSection;

const PhoneIcon = () => (
  <svg id="elvn-1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 116.6 116.6" className="icon">
    <path className="icon-01" d="M83.1,105.4H32.7c-1.4,0-2.6-1.2-2.6-2.6V13.6c0-1.4,1.2-2.6,2.6-2.6h50.2c1.4,0,2.6,1.2,2.6,2.6v89.2 C85.6,104.2,84.4,105.4,83.1,105.4z" stroke="url(#grad746328)" fill="none" style={{strokeDasharray: "296, 298", strokeDashoffset: "0"}}></path>
    <path className="icon-02" d="M30.1,88.1L85.6,88.1" stroke="url(#grad746328)" fill="none" style={{strokeDasharray: "56, 58", strokeDashoffset: "0"}}></path>
    <path className="icon-01" d="M53.9,96.6A3.9,3.9 0,1,1 61.699999999999996,96.6A3.9,3.9 0,1,1 53.9,96.6" stroke="url(#grad746328)" fill="none" style={{strokeDasharray: "25, 27", strokeDashoffset: "0"}}></path>
  </svg>
);
