/**
 *
 * Mobylogix
 * Author: Mobylogix
 * Email: info@mobylogix.com
 * Website: mobylogix.com
 * Version: 1.0
 * Date: Thu Nov 11 2021
 * Category: Pages
 * Title: SubInnerBanner
 *
 */

import React from 'react';
import { Container } from 'reactstrap';

// styles
import './sub-inner-banner.css';

const SubInnerBanner = (props) => (
  <div className="sub-inner-banner">
    <Container>
      <h1 className="main-title wow fadeInUp">
        Connect globally with<span>complete anonymity</span>
      </h1>
      <figure className="img-wrap wow fadeInUp">
        <img src="https://www.cubix.co/themes/responsiv-clean/assets/images/curious-portfolio/Curious-mobile-header.png" alt="" />
      </figure>
    </Container>
  </div>
);

SubInnerBanner.propTypes = {};

export default SubInnerBanner;
