/**
 *
 * Mobylogix
 * Author: Mobylogix
 * Email: info@mobylogix.com
 * Website: mobylogix.com
 * Version: 1.0
 * Date: Wed Nov 10 2021
 * Category: Pages
 * Title: HighlightsSection
 *
 */

import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import CountUp from 'react-countup';

// styles
import './highlights-section.css';

const HighlightsSection = (props) => (
  <div className="highlights-section">
    <Container>
      {props.title &&
        <div className="main-heading alter-main-heading">
          <Row>
            <Col xs='12' md='5'>
              <h2 className="main-title">{props.title}</h2>
            </Col>
            <Col xs='12' md='7'>
              <p className="description">{props.description}</p>
            </Col>
          </Row>
        </div>
      }
      <Row>
        <Col xs="12" md="6" lg="3">
          <div className="count-box">
            <CountUp
              className="count-balance"
              start={0}
              end={7}
              duration={1.75}
              suffix="+"
              // onEnd={() => console.log('Ended! 👏')}
              // onStart={() => console.log('Started! 💨')}
              delay={0}
            >
              {({ countUpRef, start }) => (
                <div className="title-wrap">
                  <span className="title" ref={countUpRef} />
                </div>
              )}
            </CountUp>
            <span className="sub-title">Years</span>
          </div>
        </Col>
        <Col xs="12" md="6" lg="3">
          <div className="count-box">
            <CountUp
              className="count-balance"
              start={0}
              end={100}
              duration={1.75}
              suffix="+"
              // onEnd={() => console.log('Ended! 👏')}
              // onStart={() => console.log('Started! 💨')}
              delay={0}
            >
              {({ countUpRef, start }) => (
                <div>
                  <span className="title" ref={countUpRef} />
                </div>
              )}
            </CountUp>
            <span className="sub-title">Clients</span>
          </div>
        </Col>
        <Col xs="12" md="6" lg="3">
          <div className="count-box">
            <CountUp
              className="count-balance"
              start={0}
              end={50}
              duration={1.75}
              suffix="+"
              // onEnd={() => console.log('Ended! 👏')}
              // onStart={() => console.log('Started! 💨')}
              delay={0}
            >
              {({ countUpRef, start }) => (
                <div>
                  <span className="title" ref={countUpRef} />
                </div>
              )}
            </CountUp>
            <span className="sub-title">Employees</span>
          </div>
        </Col>
        <Col xs="12" md="6" lg="3">
          <div className="count-box">
            <CountUp
              className="count-balance"
              start={0}
              end={200}
              duration={1.75}
              suffix="+"
              // onEnd={() => console.log('Ended! 👏')}
              // onStart={() => console.log('Started! 💨')}
              delay={0}
            >
              {({ countUpRef, start }) => (
                <div>
                  <span className="title" ref={countUpRef} />
                </div>
              )}
            </CountUp>
            <span className="sub-title">Projects</span>
          </div>
        </Col>
      </Row>
    </Container>
  </div>
);

HighlightsSection.propTypes = {};

export default HighlightsSection;
