import React from "react";

const AwardsAnalyticsIcon = ({ extraClass= '' }) => (
  <svg className={extraClass} xmlns="http://www.w3.org/2000/svg" fill={"#007eff"} width="20px" height="20px" version="1.1" style={{ shapeRendering: 'geometricPrecision', textRendering: 'geometricPrecision', imageRendering: 'optimizeQuality', fillRule: 'evenodd', clipRule: 'evenodd' }}
    viewBox="0 0 61.21 41.39">
    <g id="Layer_x0020_1">
      <metadata id="CorelCorpID_0Corel-Layer"/>
      <path d="M4.28 39.34c-2.73,0 -3.06,-4.21 0.14,-4.21 1.23,0 2.75,1.94 1.32,3.5 -0.24,0.26 -1.05,0.71 -1.46,0.71zm52.43 -39.34l0.45 0c2.17,0.11 4.21,1.83 4.05,4.29 -0.16,2.31 -1.92,4.12 -4.28,4.12 -0.31,0 -1.06,-0.2 -1.36,-0.27 -0.32,0.6 -0.68,1.08 -1.06,1.65l-4.88 7.32c-0.21,0.32 -0.29,0.52 -0.51,0.84 -0.43,0.64 -1.95,2.72 -2.09,3.33 0.28,0.41 0.46,0.55 0.68,1.08 1.06,2.54 -0.91,5.84 -3.8,5.84 -2.49,0 -4.34,-1.99 -4.34,-4.48 0,-0.35 0.09,-0.14 0.11,-0.63l-5.94 -2.9c-0.55,0.37 -0.5,0.53 -1.26,0.91 -1.35,0.67 -2.63,0.59 -3.93,-0.13l-7.43 7.5c0.21,0.89 1.26,2.68 -0.37,4.65 -0.52,0.63 -1.07,1.1 -1.85,1.4 -2.46,0.96 -3.94,-0.67 -4.7,-1.17l-5.83 2.85c0,1.98 -0.01,2.53 -0.93,3.69 -1.67,2.11 -5.41,2.02 -6.89,-0.62 -1.26,-2.23 -0.34,-5.64 3.16,-6.24 2.07,-0.35 3.1,1 3.7,1.4l5.83 -2.85c0,-1.94 -0.01,-2.43 0.87,-3.61 1.29,-1.72 3.6,-1.95 5.48,-1.04l7.36 -7.43c-0.15,-0.67 -0.54,-1.08 -0.54,-1.9 0,-2.56 1.88,-4.48 4.07,-4.48 2.59,0 4.77,2.12 4.23,5.1l5.94 2.9c0.46,-0.31 0.66,-0.58 1.26,-0.91 1.46,-0.79 2.24,-0.31 3.35,-0.31l1.08 -1.63c0.94,-1.58 7.29,-10.73 7.47,-11.39 -0.98,-1.46 -0.95,-1.58 -0.95,-3.26 0,-0.92 0.75,-2.08 1.25,-2.55 0.76,-0.72 1.68,-1.07 2.61,-1.11zm-41.44 30.66c0,-2.66 4.21,-3.08 4.21,0.14 0,1.49 -2.2,2.63 -3.5,1.33 -0.29,-0.29 -0.7,-0.94 -0.7,-1.47zm28.76 -4.61c-3.36,0 -2.6,-4.07 -0.54,-4.07l0.68 0c2.23,0 2.28,4.07 -0.14,4.07zm-15.47 -8.41c0,-3.41 4.07,-2.6 4.07,-0.54 0,3.42 -4.07,2.63 -4.07,0.54zm26.32 -13.29c0,-0.67 0.23,-1.22 0.57,-1.6 1.86,-2.03 4.92,0.78 3.08,2.81 -1.4,1.55 -3.66,0.21 -3.66,-1.22z"/>
    </g>
  </svg>
)

export default AwardsAnalyticsIcon;
