/**
 *
 * Mobylogix
 * Author: Mobylogix
 * Email: info@mobylogix.com
 * Website: mobylogix.com
 * Version: 1.0
 * Date: Thu Nov 11 2021
 * Category: Pages
 * Title: Portfolio
 *
 */

import React from 'react';

// Components
import SubInnerBanner from 'components/SubInnerBanner';
import ProductIntroduction from 'components/ProductIntroduction';
import InnerHighlightsSection from 'components/InnerHighlightsSection';
import ExploreSection from 'components/ExploreSection';
import ParallaxSection from 'components/ParallaxSection';
import DiscoverSection from 'components/DiscoverSection';

const Portfolio = (props) => (
  <div className="inner-page portfolio">
    {/* Sub Inner Banner */}
    <SubInnerBanner />
    {/* Sub Inner Banner Ebd */}
    {/* Product Introduction */}
    <ProductIntroduction />
    {/* Product Introduction End */}
    {/* Inner Highlights Section */}
    <InnerHighlightsSection />
    {/* Inner Highlights Section End */}
    {/* Explore Section */}
    <ExploreSection />
    {/* Explore Section End */}
    {/* Parallax Section */}
    <ParallaxSection />
    {/* Parallax Section End */}
    {/* Discover Section */}
    <DiscoverSection />
    {/* Discover Section End */}
  </div>
);

Portfolio.propTypes = {
};

export default Portfolio;

