/**
 *
 * Mobylogix
 * Author: Mobylogix
 * Email: info@mobylogix.com
 * Website: mobylogix.com
 * Version: 1.0
 * Date: Thu Nov 11 2021
 * Category: Pages
 * Title: ParallaxSection
 *
 */

import React from 'react';
import { Container, Row, Col } from 'reactstrap';

// styles
import './parallax-section.css';

const ParallaxSection = (props) => (
  <div className="parallax-section">
    <Container>
      <Row className="row-holder">
        <Col xs="12" md="6" lg="5">
          <figure className="img-wrap">
            <img src="https://www.cubix.co/themes/responsiv-clean/assets/images/curious-portfolio/Curious-man-with-plan.png" alt="" />
          </figure>
        </Col>
        <Col xs="12" md="6" lg="7">
          <div className="parallax-caption">
            <h2>Curious Was Designed</h2>
            <h3>To break social barriers and unite</h3>
            <h3>The world's population</h3>
          </div>
        </Col>
      </Row>
    </Container>
  </div>
);

ParallaxSection.propTypes = {};

export default ParallaxSection;
