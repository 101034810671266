/**
 *
 * Mobylogix
 * Author: Mobylogix
 * Email: info@mobylogix.com
 * Website: mobylogix.com
 * Version: 1.0
 * Date: Thu Nov 04 2021
 * Category: Pages
 * Title: ProductSection
 *
 */

import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Slider from "react-slick";

// styles
import './product-section.css';

const ProductSection = (props) => {

  const settings = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  return (
    <section className="product-section">
      <Container>
        {/* Main Heading */}
        <Row className="row-justify-center">
          <Col xs='12' sm='9' md='7' lg='7'>
            <div className="main-heading">
              <h3 className="main-title wow fadeInUp">We are expert in transforming product ideas to digital realities.</h3>
            </div>
          </Col>
        </Row>
        {/* Main Heading */}
        <Slider {...settings} className="product-slider">
          <div className="product-slider-item">
            <Row className="row-holder">
              <Col xs="12" lg="6">
                <div className="product-imgs">
                  <figure className="product-img">
                    <img src={`${process.env.REACT_APP_S3_URL}/v4/case-studies/benzinga.png`} alt="BenzingaPro" />
                  </figure>
                </div>
              </Col>
              <Col xs="12" lg="6">
                <div className="product-caption">
                  <figure className="product-icon">
                    <img src={`${process.env.REACT_APP_S3_URL}/v4/brand-logos/BenzingaPro.png`} height="50px" alt="Logo" />
                  </figure>
                  {/* <strong className="sub-title">Mobile App</strong> */}
                  <h3 className="title">Trading Ideas</h3>
                  <p className="description">Benzinga Pro brings you fast stock market news and alerts. Business News, Financial News, Trading Ideas, and Stock Research by Professionals. Sentiment Indicators. Custom Filters. Keyword Search. Highlights: Save Time, Live Chat Option Available.</p>
                  {/* <a href="#" rel="nooppener noreferrer" className="link-underline">View Case Study</a> */}
                  <a href="https://pro.benzinga.com" target="_blank" rel="nooppener noreferrer" className="link-underline">View Now</a>
                </div>
              </Col>
            </Row>
          </div>
          <div className="product-slider-item">
            <Row className="row-holder">
              <Col xs="12" lg="6">
                <div className="product-imgs">
                  <figure className="product-img">
                    <img src={`${process.env.REACT_APP_S3_URL}/v4/case-studies/care.png`} alt="Care" />
                  </figure>
                </div>
              </Col>
              <Col xs="12" lg="6">
                <div className="product-caption">
                  <figure className="product-icon">
                    <img src={`${process.env.REACT_APP_S3_URL}/v4/brand-logos/care.png`} alt="Logo" />
                  </figure>
                  {/* <strong className="sub-title">Mobile App</strong> */}
                  <h3 className="title">Find Child Care, Babysitters, Senior Care, Pet Care.</h3>
                  <p className="description">Get the help you need for your children, pets, elderly parents, home and lifestyle. Making it easier to find better care for your whole family.</p>
                  {/* <a href="#" rel="nooppener noreferrer" className="link-underline">View Case Study</a> */}
                  <a href="https://care.com" target={'_blank'} rel="nooppener noreferrer" className="link-underline">View Now</a>
                </div>
              </Col>
            </Row>
          </div>
        </Slider>
      </Container>
    </section>
  )
};

ProductSection.propTypes = {};

export default ProductSection;
