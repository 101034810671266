/**
 *
 * Mobylogix
 * Author: Mobylogix
 * Email: info@mobylogix.com
 * Website: mobylogix.com
 * Version: 1.0
 * Date: Wed Oct 06 2021
 * Category: Pages
 * Title: InnerBanner
 *
 */

import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Slider from "react-slick";

// Components
import InnerBanerForm from 'components/InnerBanerForm';

// styles
import './inner-banner.css';

const InnerBanner = ({title, description}) => {
  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  return (
    <div className="inner-banner">
      <Container>
        <Row>
          <Col xs='24' lg='6'>
            <div className="inner-banner-caption">
              <h1 className="title wow fadeInDown">{title}</h1>
              <p className="description wow fadeInDown">{description}</p>
              <Slider {...settings} className="inner-banner-slider wow fadeInDown">
                <div className="inner-banner-slider-item">
                  <figure className="slider-img-wrap">
                    <img src="images/bazingalogo.png" alt="" />
                  </figure>
                </div>
                <div className="inner-banner-slider-item">
                  <figure className="slider-img-wrap">
                    <img src="images/carelogo.png" alt="" />
                  </figure>
                </div>
                <div className="inner-banner-slider-item">
                  <figure className="slider-img-wrap">
                    <img src="images/hubstaff.png" alt="" />
                  </figure>
                </div>
                {/* <div className="inner-banner-slider-item">
                  <figure className="slider-img-wrap">
                    <img src="images/monday.png" alt="" />
                  </figure>
                </div> */}
                <div className="inner-banner-slider-item">
                  <figure className="slider-img-wrap">
                    <img src="images/shale.aipngwhite.png" alt="" />
                  </figure>
                </div>
                <div className="inner-banner-slider-item">
                  <figure className="slider-img-wrap">
                    <img src="images/tsensowhite.png" alt="" />
                  </figure>
                </div>
              </Slider>
            </div>
          </Col>
          <Col xs='24' lg='6'>
            <InnerBanerForm />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default InnerBanner;
