import React from "react";

const MobileAppIcon = ({ extraClass= '' }) => (
  <svg className={extraClass} xmlns="http://www.w3.org/2000/svg" width="120px" height="120px" version="1.1" style={{ shapeRendering: 'geometricPrecision', textRendering: 'geometricPrecision', imageRendering: 'optimizeQuality', fillRule: 'evenodd', clipRule: 'evenodd' }}
    viewBox="0 0 24.85 27.3">
    <g id="Layer_x0020_1">
      <metadata id="CorelCorpID_0Corel-Layer"/>
      <g id="_2070630397824">
        <path d="M16.45 26.25l-14.88 0c-0.81,0 -0.53,-0.98 -0.53,-2.98l16.1 0c0,1.54 0.29,2.98 -0.7,2.98zm-3.71 -17.29l1.06 -0.95 0.88 0.77c0.88,-0.48 1.23,-0.66 2.31,-0.91l0 -1.23 1.4 0 0 1.23c1.3,0.3 1.77,0.72 2.45,0.88l0.84 -0.73 0.87 0.95 -0.8 0.87c0.39,0.6 0.81,1.19 0.84,2.24l1.23 0 0 1.57 -1.23 0c-0.08,1 -0.51,1.58 -0.88,2.28l0.91 0.84 -1.06 0.95 -0.88 -0.77c-0.88,0.48 -1.23,0.66 -2.31,0.91l0 1.23 -1.4 0 0 -1.23c-1,-0.08 -1.58,-0.51 -2.28,-0.88l-0.84 0.73 -1.05 -0.94 0.8 -0.88c-1.55,-2.43 -0,-2.41 -2.24,-2.41l0 -1.4c1.6,0 1.23,0.06 1.6,-1.02 0.2,-0.57 0.36,-0.78 0.67,-1.25l-0.91 -0.84zm3.19 -1.79c-0.51,0.12 -0.81,0.31 -1.23,0.53l-1.01 -1.09 -2.18 2.28 0.87 0.95c-0.21,1.68 -0.78,1.36 -1.89,1.36l0 3.33c1.33,0 1.52,-0.34 1.93,1.4l-0.91 0.84 2.18 2.28 1.05 -0.97c0.37,0.18 0.67,0.37 1.18,0.48l0 1.4 1.23 0 0 2.45 -15.75 0c-0.4,0 -0.53,-0.12 -0.53,-0.53l0 -16.8 16.28 0 0 0.7 -1.23 0 0 1.4zm-15.05 -5.43c0,-0.41 0.29,-0.7 0.7,-0.7l14.88 0c0.99,0 0.7,1.43 0.7,2.98l-16.28 0 0 -2.27zm-0.87 0l0 23.8c0,1.09 0.97,1.75 2.1,1.75l13.83 0c1.1,0 2.1,-0.47 2.1,-1.58l0 -5.78 1.23 0 0 -1.4c0.65,-0.05 0.93,-0.27 1.4,-0.53l0.91 1.02 2.28 -2.2 -0.88 -0.96c0.27,-1.57 0.51,-1.36 1.89,-1.36l0 -3.33c-1.5,0 -1.55,0.19 -1.93,-1.4l0.91 -0.84 -2.27 -2.36 -0.95 1.06c-0.45,-0.22 -0.76,-0.35 -1.37,-0.49l0 -1.4 -1.23 0c0,-3.74 0.63,-5.78 -2.1,-5.78l-13.83 0c-1.13,0 -2.1,0.66 -2.1,1.75z"/>
        <path d="M14.53 13.13c0,-4.66 6.3,-4.72 6.3,0 0,3.91 -6.3,3.91 -6.3,0zm-1.05 -0.35c0,2.29 1.72,4.2 4.55,4.2 1.94,0 3.85,-2.15 3.85,-4.03 0,-2.44 -1.75,-4.2 -4.55,-4.2 -1.99,0 -3.85,2.05 -3.85,4.03z"/>
        <path d="M7 2.98l4.03 0c0.48,0 0.42,-0.14 0.53,-0.53 -0.2,-0.29 -0.03,-0.35 -0.53,-0.35l-4.03 0c-0.75,0 -0.75,0.88 0,0.88z"/>
        <path d="M8.75 24.5l0.53 0 0 0.53c-0.73,-0.19 -0.29,-0.07 -0.53,-0.53zm-0.88 0c0,2.03 2.28,1.76 2.28,0.18 0,-1.68 -2.28,-1.34 -2.28,-0.18z"/>
      </g>
    </g>
  </svg>
)

export default MobileAppIcon;
