/**
 *
 * Mobylogix
 * Author: Mobylogix
 * Email: info@mobylogix.com
 * Website: mobylogix.com
 * Version: 1.0
 * Date: Wed Nov 10 2021
 * Category: Pages
 * Title: TechnologyStackSection
 *
 */

import React, { useState } from 'react';
import { Container, Row, Col, TabContent, TabPane, Nav, NavItem, NavLink,  } from 'reactstrap';
import classnames from 'classnames';

// util
import { swift, roomDatabase, sqlite, javascript, firebase, ffmpeg, objectivec, realm, redux, kotlin } from "../../utils/technolgy";

// styles
import './technology-stack-section.css';

const TechnologyStackSection = (props) => {
  const [activeTab, setActiveTab] = useState('1');

  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }

  return (
    <div className="technology-stack-section">
      <Container>
        <div className="main-heading main-heading-left">
          <h2 className="main-title">Mobile apps development – technology stack</h2>
          <p className="description">We create mobile apps with scalable and tested technology stack to deliver apps par excellence.</p>
        </div>
        <Row className="row-holder">
          <Col xs="12" lg="5">
            <h3 className="title">Language</h3>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '1' })}
                  onClick={() => { toggle('1'); }}
                >
                  <img src="/images/kotlin.png" alt="" />
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '2' })}
                  onClick={() => { toggle('2'); }}
                >
                  <img src="/images/swift.png" alt="" />
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '3' })}
                  onClick={() => { toggle('3'); }}
                >
                  <img src="/images/sqlite.png" alt="" />
                </NavLink>
              </NavItem>
            </Nav>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '4' })}
                  onClick={() => { toggle('4'); }}
                >
                  <img src="/images/objective-c.png" alt="" />
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '5' })}
                  onClick={() => { toggle('5'); }}
                >
                  <img src="/images/javascript.png" alt="" />
                </NavLink>
              </NavItem>
            </Nav>
            <h3 className="title">Database</h3>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '6' })}
                  onClick={() => { toggle('6'); }}
                >
                  <img src="/images/redux.png" alt="" />
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '7' })}
                  onClick={() => { toggle('7'); }}
                >
                  <img src="/images/realm.png" alt="" />
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '8' })}
                  onClick={() => { toggle('8'); }}
                >
                  <img src="/images/ffmpeg.png" alt="" />
                </NavLink>
              </NavItem>
            </Nav>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '9' })}
                  onClick={() => { toggle('9'); }}
                >
                  <img src="/images/firebase.png" alt="" />
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '10' })}
                  onClick={() => { toggle('10'); }}
                >
                  <img src="/images/room-database.png" alt="" />
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
          <Col xs="12" lg="7">
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <div className="tabs-content-caption">
                  <div className="title-wrap">
                    <img src="/images/kotlin.png" alt="" className="icon" />
                    <h3 className="title">{kotlin.title}</h3>
                  </div>
                  <p className="description">{kotlin.description}</p>
                </div>
              </TabPane>
              <TabPane tabId="2">
                <div className="tabs-content-caption">
                  <div className="title-wrap">
                    <img src="/images/swift.png" alt="" className="icon" />
                    <h3 className="title">{swift.title}</h3>
                  </div>
                  <p className="description">{swift.description}</p>
                </div>
              </TabPane>
              <TabPane tabId="3">
                <div className="tabs-content-caption">
                  <div className="title-wrap">
                    <img src="/images/sqlite.png" alt="" className="icon" />
                    <h3 className="title">{sqlite.title}</h3>
                  </div>
                  <p className="description">{sqlite.description}</p>
                </div>
              </TabPane>
              <TabPane tabId="4">
                <div className="tabs-content-caption">
                  <div className="title-wrap">
                    <img src="/images/objective-c.png" alt="" className="icon" />
                    <h3 className="title">{objectivec.title}</h3>
                  </div>
                  <p className="description">{objectivec.description}</p>
                </div>
              </TabPane>
              <TabPane tabId="5">
                <div className="tabs-content-caption">
                  <div className="title-wrap">
                    <img src="/images/javascript.png" alt="" className="icon" />
                    <h3 className="title">{javascript.title}</h3>
                  </div>
                  <p className="description">{javascript.description}</p>
                </div>
              </TabPane>
              <TabPane tabId="6">
                <div className="tabs-content-caption">
                  <div className="title-wrap">
                    <img src="/images/redux.png" alt="" className="icon" />
                    <h3 className="title">{redux.title}</h3>
                  </div>
                  <p className="description">{redux.description}</p>
                </div>
              </TabPane>
              <TabPane tabId="7">
                <div className="tabs-content-caption">
                  <div className="title-wrap">
                    <img src="/images/realm.png" alt="" className="icon" />
                    <h3 className="title">{realm.title}</h3>
                  </div>
                  <p className="description">{realm.description}</p>
                </div>
              </TabPane>
              <TabPane tabId="8">
                <div className="tabs-content-caption">
                  <div className="title-wrap">
                    <img src="/images/ffmpeg.png" alt="" className="icon" />
                    <h3 className="title">{ffmpeg.title}</h3>
                  </div>
                  <p className="description">{ffmpeg.description}</p>
                </div>
              </TabPane>
              <TabPane tabId="9">
                <div className="tabs-content-caption">
                  <div className="title-wrap">
                    <img src="/images/firebase.png" alt="" className="icon" />
                    <h3 className="title">{firebase.title}</h3>
                  </div>
                  <p className="description">{firebase.description}</p>
                </div>
              </TabPane>
              <TabPane tabId="10">
                <div className="tabs-content-caption">
                  <div className="title-wrap">
                    <img src="/images/room-database.png" alt="" className="icon" />
                    <h3 className="title">{roomDatabase.title}</h3>
                  </div>
                  <p className="description">{roomDatabase.description}</p>
                </div>
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </Container>
    </div>
  )
};

TechnologyStackSection.propTypes = {};

export default TechnologyStackSection;
