/**
 *
 * Mobylogix
 * Author: Mobylogix
 * Email: info@mobylogix.com
 * Website: mobylogix.com
 * Version: 1.0
 * Date: Wed Nov 17 2021
 * Category: Pages
 * Title: AboutProcess
 *
 */

import React from 'react';
import { Container, Row, Col } from 'reactstrap';

// Components
import AboutBanner from 'components/AboutBanner';

const AboutProcess = (props) => {
  const processItem = [
    {
      number: '01',
      title: 'Ip protection',
      icon: <ProcessIcon />,
      description: 'It’s our top priority. We start every project with an NDA, to make sure that your crucial information and Intellectual Property is always safe and secure.'
    },
    {
      number: '02',
      title: 'Consultation & project discovery',
      icon: <ProcessIcon />,
      description: 'Next we’ll brainstorm your idea and inform you more about our methodology so we can see if we’re a good fit for your product development.'
    },
    {
      number: '03',
      title: 'Rapid strategy workshop™',
      icon: <ProcessIcon />,
      description: 'Then, we will identify a best way to cook your idea ready for development, review commercial & technical risk and create a level of efforts (LOE) document for you to review.'
    },
    {
      number: '04',
      title: 'User interfaces & experiences',
      icon: <ProcessIcon />,
      description: 'Then, our team of talented UI/UX engineers ensures that whenever we build a particular screen, an experience, the transition, icon or any other element, it always looks and feels like it came from Cubix.'
    },
    {
      number: '05',
      title: 'Consultation & project discovery',
      icon: <ProcessIcon />,
      description: 'Next we’ll brainstorm your idea and inform you more about our methodology so we can see if we’re a good fit for your product development.'
    },
    {
      number: '06',
      title: 'Rapid strategy workshop™',
      icon: <ProcessIcon />,
      description: 'Then, we will identify a best way to cook your idea ready for development, review commercial & technical risk and create a level of efforts (LOE) document for you to review.'
    },
    {
      number: '07',
      title: 'User interfaces & experiences',
      icon: <ProcessIcon />,
      description: 'Then, our team of talented UI/UX engineers ensures that whenever we build a particular screen, an experience, the transition, icon or any other element, it always looks and feels like it came from Cubix.'
    }
  ];

  return (
    <div className="inner-page about about-process">
      {/* About Banner */}
      <AboutBanner 
        title={'Process'}
        description={`At Cubix, we deliver successful products by doing three
        things: being accountable for our projects, making our
        methods transparent, and building strong relationships with
        our clients.`}
        btnText={`Let's Get Started`}
      />
      {/* About Banner End */}
      {/* Process Detail Section */}
      <section className="process-detail-sec">
        <Container>
          <div className="main-heading">
            <h2 className="main-title wow fadeInDown">We start here</h2>
          </div>
          {processItem.map((item, i) => (
            <div className="process-item" key={i}>
              <div className="coonect-wrap wow fadeInUp"></div>
              <Row className="process-caption row-holder">
                <Col xs="12" md="5">
                  <h3 className="title wow fadeInLeft"><span className="d-block">{item.number}</span>{item.title}</h3>
                </Col>
                <Col xs="12" md="2">
                  <div className="icon-wrap wow fadeInUp">
                    {item.icon}
                  </div>
                </Col>
                <Col xs="12" md="5">
                  <p className="description wow fadeInRight">{item.description}</p>
                </Col>
              </Row>
            </div>
          ))}
        </Container>
      </section>
      {/* Process Detail Section End */}
    </div>
  )
};

AboutProcess.propTypes = {
};

export default AboutProcess;

const ProcessIcon = () => (
  <svg id="elvn-1" viewBox="0 0 128 128" className="enlivenem">
    <path className="cls-1" d="M69,53.17a3.84,3.84,0,0,0-2.26,3.5V72a2.78,2.78,0,0,1-5.55,0V56.68A3.84,3.84,0,0,0,59,53.17a12.27,12.27,0,0,1-7.22-11.26A12.41,12.41,0,0,1,63.31,29.78l.69,0a12.25,12.25,0,0,1,5,23.41Z"></path>
    <path className="cls-1" d="M39.39,91.39H88.61a0,0,0,0,1,0,0v7.73A10.56,10.56,0,0,1,78,109.68H49.95A10.56,10.56,0,0,1,39.39,99.12V91.39a0,0,0,0,1,0,0Z"></path>
    <path className="cls-1" d="M49.95,109.68a10.47,10.47,0,0,1-3.08-.46A17.4,17.4,0,0,0,64,124h0a17.4,17.4,0,0,0,17.14-14.79,10.47,10.47,0,0,1-3.09.47Z"></path>
    <path className="cls-1" d="M39.37,91.39H88.63a27.87,27.87,0,0,1,7.12-17.45,42.21,42.21,0,1,0-63.28.23A27.45,27.45,0,0,1,39.37,91.39Z"></path>
  </svg>
);
