/**
 *
 * Mobylogix
 * Author: Mobylogix
 * Email: info@mobylogix.com
 * Website: mobylogix.com
 * Version: 1.0
 * Date: Thu Nov 04 2021
 * Category: Pages
 * Title: TechnologiesSection
 *
 */

import React, { useState } from 'react';
import { Container, TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import classnames from 'classnames';

// styles
import './technologies-section.css';

const TechnologiesSection = (props) => {
  const [activeTab, setActiveTab] = useState('1');

  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }

  return (
    <section className="technologies-section">
      <Container>
        <div className="main-heading">
          <h2 className="main-title">Technologies we work with</h2>
        </div>
        <div className="tabs-wrap">
          <Nav tabs className="row-justify-center">
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '1' })}
                onClick={() => { toggle('1'); }}
              >
                Mobile
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '2' })}
                onClick={() => { toggle('2'); }}
              >
                Frontend
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '4' })}
                onClick={() => { toggle('4'); }}
              >
                Backend
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '3' })}
                onClick={() => { toggle('3'); }}
              >
                Database
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '5' })}
                onClick={() => { toggle('5'); }}
              >
                CMS
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '6' })}
                onClick={() => { toggle('6'); }}
              >
                Infra and devops
              </NavLink>
            </NavItem>
          </Nav>
          <Row className="row-justify-center">
            <Col xs="12" lg="9">
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <Row className="row-justify-center">
                    <Col xs="4" sm="2" md="2" lg='2' className="text-center">
                      <div className="tech-box">
                        <div className="icon-wrap">
                          <img src="/images/android.png" />
                        </div>
                        <h3 className="title">Android</h3>
                      </div>
                    </Col>
                    <Col xs="4" sm="2" md="2" lg='2' className="text-center">
                      <div className="tech-box">
                        <div className="icon-wrap">
                        <img src="/images/ios-bg.png" />
                        </div>
                        <h3 className="title">iOS</h3>
                      </div>
                    </Col>
                    <Col xs="4" sm="2" md="2" lg='2' className="text-center">
                      <div className="tech-box">
                        <div className="icon-wrap">
                        <img src="/images/flutter.png" />
                        </div>
                        <h3 className="title">Flutter</h3>
                      </div>
                    </Col>
                    <Col xs="4" sm="2" md="2" lg='2' className="text-center">
                      <div className="tech-box">
                        <div className="icon-wrap">
                        <img src="/images/ionic.png" />
                        </div>
                        <h3 className="title">Ionic</h3>
                      </div>
                    </Col>
                    <Col xs="4" sm="2" md="2" lg='2' className="text-center">
                      <div className="tech-box">
                        <div className="icon-wrap">
                        <img src="/images/react-native.png" />
                        </div>
                        <h3 className="title">React Native</h3>
                      </div>
                    </Col>
                    <Col xs="4" sm="2" md="2" lg='2' className="text-center">
                      <div className="tech-box">
                        <div className="icon-wrap">
                        <img src="/images/objectivec.png" />
                        </div>
                        <h3 className="title">Objective-C</h3>
                      </div>
                    </Col>
                    <Col xs="4" sm="2" md="2" lg='2' className="text-center">
                      <div className="tech-box">
                        <div className="icon-wrap">
                        <img src="/images/swiftbg.png" />
                        </div>
                        <h3 className="title">Swift</h3>
                      </div>
                    </Col>
                    <Col xs="4" sm="2" md="2" lg='2' className="text-center">
                      <div className="tech-box">
                        <div className="icon-wrap">
                        <img src="/images/kotlin-bg.png" />
                        </div>
                        <h3 className="title">Kotlin</h3>
                      </div>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="2">
                  <Row className="row-justify-center">
                    <Col xs="4" sm="2" md="2" lg='2' className="text-center">
                      <div className="tech-box">
                        <div className="icon-wrap">
                          <img src="/images/react-js.png" />
                        </div>
                        <h3 className="title">React</h3>
                      </div>
                    </Col>
                    <Col xs="4" sm="2" md="2" lg='2' className="text-center">
                      <div className="tech-box">
                        <div className="icon-wrap">
                          <img src="/images/angular.png" />
                        </div>
                        <h3 className="title">Angular</h3>
                      </div>
                    </Col>
                    <Col xs="4" sm="2" md="2" lg='2' className="text-center">
                      <div className="tech-box">
                        <div className="icon-wrap">
                          <img src="/images/html5.png" />
                        </div>
                        <h3 className="title">Html</h3>
                      </div>
                    </Col>
                    <Col xs="4" sm="2" md="2" lg='2' className="text-center">
                      <div className="tech-box">
                        <div className="icon-wrap">
                          <img src="/images/vue.png" />
                        </div>
                        <h3 className="title">Vue</h3>
                      </div>
                    </Col>
                    <Col xs="4" sm="2" md="2" lg='2' className="text-center">
                      <div className="tech-box">
                        <div className="icon-wrap">
                          <img src="/images/wpf.png" />
                        </div>
                        <h3 className="title">WPF</h3>
                      </div>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="3">
                  <Row className="row-justify-center">
                    <Col xs="4" sm="2" md="2" lg='2' className="text-center">
                      <div className="tech-box">
                        <div className="icon-wrap">
                        <img src="/images/mssql.png" />
                        </div>
                        <h3 className="title">MS-SQL</h3>
                      </div>
                    </Col>
                    <Col xs="4" sm="2" md="2" lg='2' className="text-center">
                      <div className="tech-box">
                        <div className="icon-wrap">
                        <img src="/images/mongo-db.png" />
                        </div>
                        <h3 className="title">MongoDB</h3>
                      </div>
                    </Col>
                    <Col xs="4" sm="2" md="2" lg='2' className="text-center">
                      <div className="tech-box">
                        <div className="icon-wrap">
                        <img src="/images/mysql.png" />
                        </div>
                        <h3 className="title">Mysql</h3>
                      </div>
                    </Col>
                    <Col xs="4" sm="2" md="2" lg='2' className="text-center">
                      <div className="tech-box">
                        <div className="icon-wrap">
                        <img src="/images/redis.png" />
                        </div>
                        <h3 className="title">Redis</h3>
                      </div>
                    </Col>
                    <Col xs="4" sm="2" md="2" lg='2' className="text-center">
                      <div className="tech-box">
                        <div className="icon-wrap">
                        <img src="/images/firebasebg.png" />
                        </div>
                        <h3 className="title">Firebase</h3>
                      </div>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="4">
                  <Row className="row-justify-center">
                    <Col xs="4" sm="2" md="2" lg='2' className="text-center">
                      <div className="tech-box">
                        <div className="icon-wrap">
                          <img src="/images/node.png" />
                        </div>
                        <h3 className="title">Node</h3>
                      </div>
                    </Col>
                    <Col xs="4" sm="2" md="2" lg='2' className="text-center">
                      <div className="tech-box">
                        <div className="icon-wrap">
                          <img src="/images/php.png" />
                        </div>
                        <h3 className="title">Php</h3>
                      </div>
                    </Col>
                    <Col xs="4" sm="2" md="2" lg='2' className="text-center">
                      <div className="tech-box">
                        <div className="icon-wrap">
                          <img src="/images/java.png" />
                        </div>
                        <h3 className="title">Java</h3>
                      </div>
                    </Col>
                    <Col xs="4" sm="2" md="2" lg='2' className="text-center">
                      <div className="tech-box">
                        <div className="icon-wrap">
                          <img src="/images/backend/python.png" />
                        </div>
                        <h3 className="title">Python</h3>
                      </div>
                    </Col>
                    <Col xs="4" sm="2" md="2" lg='2' className="text-center">
                      <div className="tech-box">
                        <div className="icon-wrap">
                          <img src="/images/backend/golang.png" />
                        </div>
                        <h3 className="title">GoLang</h3>
                      </div>
                    </Col>
                    <Col xs="4" sm="2" md="2" lg='2' className="text-center">
                      <div className="tech-box">
                        <div className="icon-wrap">
                          <img src="/images/backend/swift.png" />
                        </div>
                        <h3 className="title">Swift</h3>
                      </div>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="5">
                  <Row className="row-justify-center">
                    <Col xs="4" sm="2" md="2" lg='2' className="text-center">
                      <div className="tech-box">
                        <div className="icon-wrap">
                        <img src="/images/magento.png" />
                        </div>
                        <h3 className="title">Magento</h3>
                      </div>
                    </Col>
                    <Col xs="4" sm="2" md="2" lg='2' className="text-center">
                      <div className="tech-box">
                        <div className="icon-wrap">
                        <img src="/images/wordpress.png" />
                        </div>
                        <h3 className="title">Wordpress</h3>
                      </div>
                    </Col>
                    <Col xs="4" sm="2" md="2" lg='2' className="text-center">
                      <div className="tech-box">
                        <div className="icon-wrap">
                        <img src="/images/shopify.png" />
                        </div>
                        <h3 className="title">Shopify</h3>
                      </div>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="6">
                  <Row className="row-justify-center">
                    <Col xs="4" sm="2" md="2" lg='2' className="text-center">
                      <div className="tech-box">
                        <div className="icon-wrap">
                          <img src="/images/aws.png" />
                        </div>
                        <h3 className="title">AWS</h3>
                      </div>
                    </Col>
                    <Col xs="4" sm="2" md="2" lg='2' className="text-center">
                      <div className="tech-box">
                        <div className="icon-wrap">
                          <img src="/images/azure.png" />
                        </div>
                        <h3 className="title">Azure</h3>
                      </div>
                    </Col>
                    <Col xs="4" sm="2" md="2" lg='2' className="text-center">
                      <div className="tech-box">
                        <div className="icon-wrap">
                          <img src="/images/digital-ocean.png" />
                        </div>
                        <h3 className="title">Digital-Ocean</h3>
                      </div>
                    </Col>
                    <Col xs="4" sm="2" md="2" lg='2' className="text-center">
                      <div className="tech-box">
                        <div className="icon-wrap">
                          <img src="/images/gradle.png" />
                        </div>
                        <h3 className="title">Gradle</h3>
                      </div>
                    </Col>
                    <Col xs="4" sm="2" md="2" lg='2' className="text-center">
                      <div className="tech-box">
                        <div className="icon-wrap">
                          <img src="/images/jenkins.png" />
                        </div>
                        <h3 className="title">Jenkins</h3>
                      </div>
                    </Col>
                    <Col xs="4" sm="2" md="2" lg='2' className="text-center">
                      <div className="tech-box">
                        <div className="icon-wrap">
                          <img src="/images/rackspace.png" />
                        </div>
                        <h3 className="title">Rackspace</h3>
                      </div>
                    </Col>
                    <Col xs="4" sm="2" md="2" lg='2' className="text-center">
                      <div className="tech-box">
                        <div className="icon-wrap">
                          <img src="/images/appium.png" />
                        </div>
                        <h3 className="title">Appium</h3>
                      </div>
                    </Col>
                    <Col xs="4" sm="2" md="2" lg='2' className="text-center">
                      <div className="tech-box">
                        <div className="icon-wrap">
                          <img src="/images/selenium.png" />
                        </div>
                        <h3 className="title">Selenium</h3>
                      </div>
                    </Col>
                    <Col xs="4" sm="2" md="2" lg='2' className="text-center">
                      <div className="tech-box">
                        <div className="icon-wrap">
                          <img src="/images/linode.png" />
                        </div>
                        <h3 className="title">Linode</h3>
                      </div>
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  )
};

TechnologiesSection.propTypes = {};

export default TechnologiesSection;
