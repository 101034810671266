/**
 *
 * Mobylogix
 * Author: Mobylogix
 * Email: info@mobylogix.com
 * Website: mobylogix.com
 * Version: 1.0
 * Date: Wed Nov 17 2021
 * Category: Pages
 * Title: AboutClients
 *
 */

import React from 'react';
import { Container, Row, Col } from 'reactstrap';

const AboutClients = (props) => (
  <div className="inner-page about about-clients">
    {/* About Client Banner */}
    <div className="work-banner">
      <Container>
        <Row className="row-justify-center">
          <Col xs="12" md="10" lg="9">
            <div className="main-heading">
              <h2 className="main-title wow fadeInDown">We help startups, enterprises, and fortune 500 companies</h2>
              <p className="description wow fadeInDown">We have helped 600+ companies transform with world-class software solutions.</p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
    {/* About Client Banner End */}
    {/* Clients Section */}
    <section className="client-sec">
      <Container>
        <Row className="row-holder">
          <Col xs="12" sm="4" md="3" lg="2">
            <img src="https://www.cubix.co/themes/responsiv-clean/assets/images/about/politico.png" alt="" className="wow fadeInUp" />
          </Col>
          <Col xs="12" sm="4" md="3" lg="2">
            <img src="https://www.cubix.co/themes/responsiv-clean/assets/images/about/bigfish-logo.png" alt="" className="wow fadeInUp" />
          </Col>
          <Col xs="12" sm="4" md="3" lg="2">
            <img src="https://www.cubix.co/themes/responsiv-clean/assets/images/about/politico.png" alt="" className="wow fadeInUp" />
          </Col>
          <Col xs="12" sm="4" md="3" lg="2">
            <img src="https://www.cubix.co/themes/responsiv-clean/assets/images/about/bigfish-logo.png" alt="" className="wow fadeInUp" />
          </Col>
          <Col xs="12" sm="4" md="3" lg="2">
            <img src="https://www.cubix.co/themes/responsiv-clean/assets/images/about/politico.png" alt="" className="wow fadeInUp" />
          </Col>
          <Col xs="12" sm="4" md="3" lg="2">
            <img src="https://www.cubix.co/themes/responsiv-clean/assets/images/about/bigfish-logo.png" alt="" className="wow fadeInUp" />
          </Col>
          <Col xs="12" sm="4" md="3" lg="2">
            <img src="https://www.cubix.co/themes/responsiv-clean/assets/images/about/politico.png" alt="" className="wow fadeInUp" />
          </Col>
          <Col xs="12" sm="4" md="3" lg="2">
            <img src="https://www.cubix.co/themes/responsiv-clean/assets/images/about/bigfish-logo.png" alt="" className="wow fadeInUp" />
          </Col>
          <Col xs="12" sm="4" md="3" lg="2">
            <img src="https://www.cubix.co/themes/responsiv-clean/assets/images/about/politico.png" alt="" className="wow fadeInUp" />
          </Col>
          <Col xs="12" sm="4" md="3" lg="2">
            <img src="https://www.cubix.co/themes/responsiv-clean/assets/images/about/bigfish-logo.png" alt="" className="wow fadeInUp" />
          </Col>
          <Col xs="12" sm="4" md="3" lg="2">
            <img src="https://www.cubix.co/themes/responsiv-clean/assets/images/about/politico.png" alt="" className="wow fadeInUp" />
          </Col>
          <Col xs="12" sm="4" md="3" lg="2">
            <img src="https://www.cubix.co/themes/responsiv-clean/assets/images/about/bigfish-logo.png" alt="" className="wow fadeInUp" />
          </Col>
          <Col xs="12" sm="4" md="3" lg="2">
            <img src="https://www.cubix.co/themes/responsiv-clean/assets/images/about/politico.png" alt="" className="wow fadeInUp" />
          </Col>
          <Col xs="12" sm="4" md="3" lg="2">
            <img src="https://www.cubix.co/themes/responsiv-clean/assets/images/about/bigfish-logo.png" alt="" className="wow fadeInUp" />
          </Col>
        </Row>
        <div className="client-logo-wrap">
        </div>
      </Container>
    </section>
    {/* Clients Section End */}
  </div>
);

AboutClients.propTypes = {
};

export default AboutClients;

