// Components
import Home from 'pages/Home';
import MobileAppsDevelopment from 'pages/MobileAppsDevelopment';
import Portfolio from 'pages/Portfolio';
import SolutionsInnerPage from 'pages/SolutionsInnerPage';
import Work from 'pages/Work';
import About from 'pages/About';
import AboutProcess from 'pages/AboutProcess';
import AboutClients from 'pages/AboutClients';
import AboutTestimonials from 'pages/AboutTestimonials';
import Contact from 'pages/Contact';
import Careers from 'pages/Careers';
import SaaSWebDevelopment from 'pages/SaaSWebDevelopment';
import EcommerceDevelopment from 'pages/EcommerceDevelopment';
import InternetofThings from 'pages/InternetofThings';
import machineDevelopment from 'pages/machineDevelopment';
import CloudServices from 'pages/CloudServices';
import virtualRealityDevelopment from 'pages/virtualRealityDevelopment';
import MVPDevelopment from "pages/MVPDevelopment";
import BlockchainDevelopment from  "pages/BlockchainDevelopment";
import CustomEnterpriseSoftwareDevelopment from  "pages/CustomEnterpriseSoftwareDevelopment";
import PrivacyPolicy from "pages/PrivacyPolicy";
import CareersListing from 'pages/CareersListing';
// import CareerInner from 'components/CareerInner';
// import LandingLinkedcamp from 'pages/LandingLinkedcamp';

// Layouts
import AppLayout from 'layouts/app';

const routes = [
  {
    path: '/',
    component: Home,
    layout: AppLayout,
    exact: true,
  },
  {
    path: '/mobile-app-development',
    component: MobileAppsDevelopment,
    layout: AppLayout,
    exact: true,
  },
  {
    path: '/privacy-policy',
    component: PrivacyPolicy,
    layout: AppLayout,
    exact: true,
  },
  {
    path: '/internet-of-things',
    component: InternetofThings,
    layout: AppLayout,
    exact: true,
  },
  {
    path: '/saas-web-development',
    component: SaaSWebDevelopment,
    layout: AppLayout,
    exact: true,
  },
  {
    path: '/ecommerce-development',
    component: EcommerceDevelopment,
    layout: AppLayout,
    exact: true,
  },
  {
    path: '/machine-learing',
    component: machineDevelopment,
    layout: AppLayout,
    exact: true,
  },
  {
    path: '/cloud-services',
    component: CloudServices,
    layout: AppLayout,
    exact: true,
  },
  {
    path: '/virtual-reality',
    component: virtualRealityDevelopment,
    layout: AppLayout,
    exact: true,
  },
  {
    path: '/mvp-development',
    component: MVPDevelopment,
    layout: AppLayout,
    exact: true,
  },
  {
    path: '/blockchain-development',
    component: BlockchainDevelopment,
    layout: AppLayout,
    exact: true,
  },
  {
    path: '/custom-enterprise-software-development',
    component: CustomEnterpriseSoftwareDevelopment,
    layout: AppLayout,
    exact: true,
  },
  {
    path: '/inner-page',
    component: Portfolio,
    layout: AppLayout,
    exact: true,
  },
  {
    path: '/solutions-inner-page',
    component: SolutionsInnerPage,
    layout: AppLayout,
    exact: true,
  },
  {
    path: '/work',
    component: Work,
    layout: AppLayout,
    exact: true,
  },
  {
    path: '/about',
    component: About,
    layout: AppLayout,
    exact: true,
  },
  {
    path: '/about/process',
    component: AboutProcess,
    layout: AppLayout,
    exact: true,
  },
  {
    path: '/about/clients',
    component: AboutClients,
    layout: AppLayout,
    exact: true,
  },
  {
    path: '/about/testimonials',
    component: AboutTestimonials,
    layout: AppLayout,
    exact: true,
  },
  {
    path: '/contact',
    component: Contact,
    // layout: AppLayout,
    exact: true,
  },
  // {
  //   path: '/careers',
  //   component: Careers,
  //   layout: AppLayout,
  //   exact: true,
  // },
  // {
  //   path: '/careers-listing',
  //   component: CareersListing,
  //   layout: AppLayout,
  //   exact: true,
  // },
  // {
  //   path: '/career-inner',
  //   component: CareerInner,
  //   layout: AppLayout,
  //   exact: true,
  // },
  // {
  //   path: '/linkedcamp',
  //   component: LandingLinkedcamp,
  //   exact: true,
  // },
];

export default routes;
