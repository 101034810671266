/**
 *
 * Mobylogix
 * Author: Mobylogix
 * Email: info@mobylogix.com
 * Website: mobylogix.com
 * Version: 1.0
 * Date: Thu Nov 11 2021
 * Category: Pages
 * Title: ExploreSection
 *
 */

import React from 'react';
import { Container, Row, Col } from 'reactstrap';

// styles
import './explore-section.css';

const ExploreSection = (props) => (
  <div className="explore-section">
    <Container>
      <Row className="row-justify-center">
        <Col xs="12" md="7" lg="7">
          <div className="main-heading main-heading-left">
            <h2 className="main-title">Explore your interests</h2>
            <p className="description">Acknowledging the need for users to protect their identity, Curious connects diverse individuals from around the world with complete anonymity. The app displays usernames instead of real names throughout the app.</p>
            <p className="description">With separate logins and profiles, individuals can sign up with social accounts or an email ID to anonymously share pictures, upload audios or videos, and text posts. Explore top tourist attractions or join popular groups of interest and expand your social circle across the globe!</p>
          </div>
          <Row className="row-justify-center">
            <Col xs="12" md="6" lg="4">
              <figure className="img-wrap">
                <img src="https://www.cubix.co/themes/responsiv-clean/assets/images/curious-portfolio/Curious-section-1mobile.png" alt="" />
              </figure>
            </Col>
            <Col xs="12" md="6" lg="4">
              <figure className="img-wrap">
                <img src="https://www.cubix.co/themes/responsiv-clean/assets/images/curious-portfolio/Curious-section-1mobile.png" alt="" />
              </figure>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  </div>
);

ExploreSection.propTypes = {};

export default ExploreSection;
