/**
 *
 * Mobylogix
 * Author: Mobylogix
 * Email: info@mobylogix.com
 * Website: mobylogix.com
 * Version: 1.0
 * Date: Mon Nov 15 2021
 * Category: Pages
 * Title: Work
 *
 */

import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';

// Components
import WorkBanner from 'components/WorkBanner';

const Work = (props) => (
  <div className="inner=page work">
    {/* Work Banner */}
    <WorkBanner />
    {/* Work Banner End */}
    {/* Work Widget Section */}
    <section className="work-widget-section bg-primary" id="Beninga">
      <Container>
        <Row className="row-holder">
          <Col  xs="12" md="6">
            <figure className="img-wrap wow fadeInLeft">
              <img src={`${process.env.REACT_APP_S3_URL}/v4/case-studies/benzinga.png`} alt="Benzinga Pro" />
            </figure>
          </Col>
          <Col  xs="12" md="6">
            <div className="work-widget-caption wow fadeInRight">
              <a href="https://pro.benzinga.com/" target={'_blank'}>
                <h3 className="title">Benzinga - Trading Ideas</h3>
              </a>
              <p className="description">Benzinga Pro brings you fast stock market news and alerts. Business News, Financial News, Trading Ideas, and Stock Research by Professionals. Sentiment Indicators. Custom Filters. Keyword Search. Highlights: Save Time, Live Chat Option Available.</p>
              {/* <Button color="primary">View Case Study</Button> */}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
    {/* Work Widget Section End */}
    {/* Work Widget Section */}
    <section className="work-widget-section bg-success">
      <Container>
        <Row className="row-holder">
          <Col  xs="12" md="6">
            <div className="work-widget-caption">
              <a href="https://care.com" target={"_blank"}>
                <h3 className="title">Find Child Care, Babysitters, Senior Care, Pet Care.</h3>
              </a>
              <p className="description">Get the help you need for your children, pets, elderly parents, home and lifestyle. Making it easier to find better care for your whole family.</p>
              {/* <Button color="primary">View Case Study</Button> */}
            </div>
          </Col>
          <Col  xs="12" md="6">
            <figure className="img-wrap">
              <img src={`${process.env.REACT_APP_S3_URL}/v4/case-studies/care.png`} alt="Care" />
            </figure>
          </Col>
        </Row>
      </Container>
    </section>
    {/* Work Widget Section End */}
    {/* <div className="load-more-wrap text-center">
      <Button color="primary" size="lg">Load More</Button>
      <hr className="hr-line" />
    </div> */}
  </div>
);

export default Work;
