/**
 *
 * Mobylogix
 * Author: Mobylogix
 * Email: info@mobylogix.com
 * Website: mobylogix.com
 * Version: 1.0
 * Date: Thu Nov 04 2021
 * Category: Pages
 * Title: TestimonialsSection
 *
 */

import React from 'react';
import Slider from "react-slick";
import { Container, Row, Col } from 'reactstrap';

// styles
import './testimonials-section.css';

const TestimonialsSection = (props) => {
  
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <section className="testimonials-section">
      <Container>
        {/* Main Heading */}
        <Row className="row-justify-center">
          <Col xs='12' sm='9' md='9' lg='9'>
            <div className="main-heading">
              <h3 className="main-title wow fadeInUp">Mobylogix develop enterprise-grade software solutions for all type of businesses.</h3>
            </div>
          </Col>
        </Row>
        {/* Main Heading */}
        <Row className="row-justify-center">
          <Col xs='12' md='11' lg='9'>
            <Slider {...settings} className="testimonials-slider">
              <div className="testimonials-slider-item">
                <div className="testimonials-caption">
                  <div className="testimonials-content">
                    “We provide best technology solutions to accelerate your business in the digital world. Being the top-rated software development and marketing company, Mobylogix provides scalable and strong IT solutions across various industries and platforms. We’ve worked with hundreds of top brands, startups, SMBs, and enterprises empowering them with our highly advanced technology solutions. We deliver the right business solutions with 10x faster delivery than our competitors, designed only to accelerate your business and boost ROI.” 
                  </div>
                  <h3 className="title">Karen Agresti Founder</h3>
                  <p className="mr-b-0">Nomidate</p>
                </div>
              </div>
              <div className="testimonials-slider-item">
                <div className="testimonials-caption">
                  <div className="testimonials-content">
                    “The team stood out because of their swift response time and patience. Mobylogix provided one contact person, which helped streamline project management and coordination. Not only did Mobylogix follow the budget and timeline, the team even offered additional services at no extra charge.” 
                  </div>
                  <h3 className="title">Matt Brown</h3>
                  <p className="mr-b-0">Tsenso</p>
                </div>
              </div>
            </Slider>
          </Col>
        </Row>
        <div className="clutch-wrap">
          <a href="#" rel="nooppener noreferrer">
            <span className="img-wrap">
              <img src="/images/clutch-icon.svg"  alt="Clutch" />
            </span>
            <span className="text">4.8 Rating on Clutch</span>
          </a>
          <div className="top-text">Top app developers</div>
        </div>
      </Container>
    </section>
  )
};

TestimonialsSection.propTypes = {};

export default TestimonialsSection;
