/**
 *
 * Mobylogix
 * Author: Mobylogix
 * Email: info@mobylogix.com
 * Website: mobylogix.com
 * Version: 1.0
 * Date: Tue Nov 09 2021
 * Category: Pages
 * Title: MobileAppsDevelopment
 *
 */

import React, { useState, useEffect } from 'react';
import Slider from "react-slick";
import { useCountUp } from 'react-countup';
import { Container, Row, Col, TabContent, TabPane, Nav, NavItem, NavLink,  } from 'reactstrap';
import classnames from 'classnames';

// util
import { arkitandarcore, gesturerecognitionkit, iosmotioncapture, corlocationforios, webconferencing, mlkitsdk } from "../../utils/technolgy";
import { MechineMeta } from 'utils/metas';

// Components
import InnerBanner from 'components/InnerBanner';
import DevelopmentServices from 'components/DevelopmentServices';
import CreativeSection from 'components/CreativeSection';
import ProcessSection from 'components/ProcessSection';
import TechnologyStackSection from 'components/TechnologyStackSection';
import InsightsSection from 'components/InsightsSection';
import HighlightsSection from 'components/HighlightsSection';
import TestimonialsSection from 'components/TestimonialsSection';
import AwardsSection from 'components/AwardsSection';
import ProjectForm from 'components/ProjectForm';
import FeaturedWork from 'components/FeaturedWork';

const MachineDevelopment = (props) => {

  const [activeTab, setActiveTab] = useState('1');

  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }
  // const totalHeight = document.getElementsByClassName('mobile-app-development')[0].offsetHeight;

  // useEffect(() => {
  //   let height = document.getElementsByClassName('mobile-app-development')[0].offsetHeight;

  //   console.log('height', height);

  //   const calcHeight = (height / 100) * 75;

  //   console.log('height1', height);

  //   if (calcHeight > window.scrollY) {

  //   }
  // });

  const settings = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  return (
    <div className="inner-page mobile-app-development">
      {/* Meta */}
      <MechineMeta />
      {/* Meta End */}
      {/* Inner Banner */}
      <InnerBanner title={"Design and build the future with AI services"} description={"We build advanced solutions with the latest technologies that can change the world."} />
      {/* Inner Banner End */}
      {/* Development Services */}
      <DevelopmentServices title={"Defeat the new horizons the intelligent way"} description={"Unlock the power of Artificial Intelligence (AI/Machine Learning) with technology kits that help you breathe life into your creative ideas. Build the intelligent solutions that create significant impact and influence modern life."} />
      {/* Development Services End */}
      {/* Creative Section */}
      <CreativeSection 
        title={"Secure your future with AI Services"}
        description={"We craft the most advanced solutions that will revolutionize the world."}
      />
      {/* Creative Section End */}
      {/* Featured Work Slider */}
      <FeaturedWork />
      {/* Featured Work Slider End */}
      {/* Caption Section */}
      <section className="deve-caption-wrap deve-caption-primary">
        <Container>
          <Row className="row-justify-center">
            <Col xs="12" md="9" lg="7">
              <div className="main-heading">
                <h2 className="main-title">Why choose us?</h2>
                <p className="description">Build Artificial Intelligence (AI) Solutions that transform your industry and possess the prospective to change the world. Leverage artificial intelligence (AI) services that build smart solutions with learning capabilities, automation and machine-based decision-making processes independent of human beings. Build and leverage strong solutions that fit your quest for improving your business operations and efficiency.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Caption Section End */}
      {/* Caption Section */}
      {/* <section className="deve-caption-wrap">
        <Container>
          <Row className="row-justify-center">
            <Col xs="12" md="9" lg="7">
              <div className="main-heading">
                <h2 className="main-title">Why choose us?</h2>
                <p className="description">Build Artificial Intelligence (AI) Solutions that transform your industry and possess the prospective to change the world. Leverage artificial intelligence (AI) services that build smart solutions with learning capabilities, automation and machine-based decision-making processes independent of human beings. Build and leverage strong solutions that fit your quest for improving your business operations and efficiency.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section> */}
      {/* Caption Section End */}
      {/* Caption Section */}
      {/* <section className="deve-caption-wrap deve-caption-primary">
        <Container>
          <Row className="row-justify-center">
            <Col xs="12" md="9" lg="7">
              <div className="main-heading">
                <h2 className="main-title">Apps developed by Mobylogix rake in success</h2>
                <p className="description">It doesn’t matter if you are an enterprise, a unicorn startup or a small-scale business, our development process stays the same, which has awesome written all over it. We begin by asking the number one question “How can I turn my idea into a successful app”. “An app that becomes an integral part of everyone’s life”. The answer: our app development process. We want our apps to be intuitive, appealing, robust and friendly.</p>
                <p className="description">We aren’t in a rush to escalate your project as we believe in detailed evaluation of a product, through various stages including Planning, sketching, wireframing, prototyping, developing, quality assurance, and launching an MVP. Our goal is to create mobile apps that are bound to rake in popularity, downloads and marvelous word of mouth.</p>
                <p className="description">You can find Mobylogix’s leading mobile app development team in USA, Lithuania, Lahore, and Islamabad.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section> */}
      {/* Caption Section End */}
      {/* Process Section */}
      {/* <ProcessSection /> */}
      {/* Process Section End */}
      {/* Caption Section */}
      {/* <section className="deve-caption-wrap deve-caption-primary">
        <Container>
          <Row className="row-justify-center">
            <Col xs="12" md="9" lg="7">
              <div className="main-heading">
                <h2 className="main-title">World-famous mobile application development company</h2>
                <p className="description">Web apps indeed hold a significant chunk of the market, currently (about 58%), making it important for your business to invest in one. If you have a wide customer base from your e-commerce website, imagine the power of pairing that with a mobile app!</p>
                <p className="description">Mobylogix is a popular mobile apps development company that helps you achieve your goals. We examine your particular need and work to develop your app solution to perfection! And it all starts with a rock-solid plan that not only sets the parameters of your project features, but also minutely defines and strategizes the length and breadth of programming required. This concrete plan is matched against a strict timeline to ensure delivery on time.</p>
                <p className="description">Every customer is important to us, as we believe in taking everyone who approaches us to the next level. So, no matter what kind of app you are desirous of building; we can build anything for you from scratch.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section> */}
      {/* Caption Section End */}
      {/* Technology Section */}
      <div className="technology-stack-section">
      <Container>
        <div className="main-heading main-heading-left">
          <h2 className="main-title">Machine Learning – technology stack</h2>
          <p className="description">We create AI Development with scalable and tested technology stack to deliver apps par excellence.</p>
        </div>
        <Row className="row-holder">
          <Col xs="12" lg="5">
            <h3 className="title">Technology</h3>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '1' })}
                  onClick={() => { toggle('1'); }}
                >
                  <img src="/images/Artificial Intelligence/arkit-and-arcore.png" alt="" />
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '2' })}
                  onClick={() => { toggle('2'); }}
                >
                  <img src="/images/Artificial Intelligence/cor-location-for-ios.png" alt="" />
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '3' })}
                  onClick={() => { toggle('3'); }}
                >
                  <img src="/images/Artificial Intelligence/gesture-recognition-kit.png" alt="" />
                </NavLink>
              </NavItem>
            </Nav>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '4' })}
                  onClick={() => { toggle('4'); }}
                >
                  <img src="/images/Artificial Intelligence/ios-motion-capture.png" alt="" />
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '5' })}
                  onClick={() => { toggle('5'); }}
                >
                  <img src="/images/Artificial Intelligence/ml-kit-sdk.png" alt="" />
                </NavLink>
              </NavItem>
            </Nav>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '6' })}
                  onClick={() => { toggle('6'); }}
                >
                  <img src="/images/Artificial Intelligence/web-conferencing.png" alt="" />
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
          <Col xs="12" lg="7">
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <div className="tabs-content-caption">
                  <div className="title-wrap">
                    <img src="/images/Artificial Intelligence/arkit-and-arcore.png" alt="" className="icon" />
                    <h3 className="title">{arkitandarcore.title}</h3>
                  </div>
                  <p className="description">{arkitandarcore.description}</p>
                </div>
              </TabPane>
              <TabPane tabId="2">
                <div className="tabs-content-caption">
                  <div className="title-wrap">
                    <img src="/images/Artificial Intelligence/cor-location-for-ios.png" alt="" className="icon" />
                    <h3 className="title">{corlocationforios.title}</h3>
                  </div>
                  <p className="description">{corlocationforios.description}</p>
                
                </div>
              </TabPane>
              <TabPane tabId="3">
                <div className="tabs-content-caption">
                  <div className="title-wrap">
                    <img src="/images/Artificial Intelligence/gesture-recognition-kit.png" alt="" className="icon" />
                    <h3 className="title">{gesturerecognitionkit.title}</h3>
                  </div>
                  <p className="description">{gesturerecognitionkit.description}</p>
                </div>
              </TabPane>
              <TabPane tabId="4">
                <div className="tabs-content-caption">
                  <div className="title-wrap">
                    <img src="/images/Artificial Intelligence/ios-motion-capture.png" alt="" className="icon" />
                    <h3 className="title">{iosmotioncapture.title}</h3>
                  </div>
                  <p className="description">{iosmotioncapture.description}</p>
                </div>
              </TabPane>
              <TabPane tabId="5">
                <div className="tabs-content-caption">
                  <div className="title-wrap">
                    <img src="/images/Artificial Intelligence/ml-kit-sdk.png" alt="" className="icon" />
                    <h3 className="title">{mlkitsdk.title}</h3>
                  </div>
                  <p className="description">{mlkitsdk.description}</p>
                </div>
              </TabPane>
              <TabPane tabId="6">
                <div className="tabs-content-caption">
                  <div className="title-wrap">
                    <img src="/images/Artificial Intelligence/web-conferencing.png" alt="" className="icon" />
                    <h3 className="title">{webconferencing.title}</h3>
                  </div>
                  <p className="description">{webconferencing.description}</p>
                </div>
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </Container>
    </div>
      {/* Technology Section End */}
      {/* Insights Section */}
      {/* <InsightsSection title="Our artificial intelligence development process" description="Optimized a leverage process to build artificial intelligence (AI) with machine learning solutions." /> */}
      {/* Insights Section End */}
      {/* Highlight Section */}
      <HighlightsSection />
      {/* Highlight Section End */}
      {/* Testimonials Section */}
      <TestimonialsSection />
      {/* Testimonials Section End */}
      {/* Awards Section */}
      <AwardsSection />
      {/* Awards Section End */}
      {/* Project Form */}
      <ProjectForm />
      {/* Project Form End */}
    </div>
  )
};


export default MachineDevelopment;

